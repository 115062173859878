import React, { useEffect, useState } from 'react';
import { withStyles, Box, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClinicApi from '../../../api/ClinicApi';
import { filters } from './styles';

function VatFilter({ classes, label, handleFilterChange }) {
    const [locations, setLocations] = useState([{ label: 'All locations', value: 'allLocations' }]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedLocation({ label: 'All locations', value: 'allLocations' });

        const fetch = async () => {
            setLoading(true);

            const clinics = await ClinicApi.getClinics();
            setLocations([
                ...clinics.map(clinic => ({ label: clinic.accountName, value: clinic.id })),
                { label: 'All locations', value: 'allLocations' }
            ]);

            handleFilterChange({
                clinic: ['All locations']
            });

            setLoading(false);
        };

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (_, item) => {
        if (item) {
            handleFilterChange({
                clinic: [item]
            });
            setSelectedLocation(item);
        } else {
            handleFilterChange({
                clinic: ['All locations']
            });
            setSelectedLocation({ label: 'All locations', value: 'allLocations' });
        }
    };

    return (
        <Box marginTop={4}>
            <Autocomplete
                className={classes.autocomplete}
                options={locations}
                value={selectedLocation}
                onChange={handleChange}
                loading={loading}
                label={label}
                getOptionLabel={option => option.label}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{`${option.label}`}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(VatFilter);
