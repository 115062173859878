import getMissingFilters from './getMissingFilters';
import reportsWithoutFilters from '../../constants/reportsWithoutFilters';
import reportsWithoutPeriod from '../../constants/reportsWithoutPeriod';

const isRunReportDisabled = ({ report, filters, group }) => {
    if (reportsWithoutPeriod.includes(report)) return false;
    if (!filters.to && !filters.from) return true;
    if (Object.keys(filters).length === 0) return true;
    if (!group && !reportsWithoutFilters.includes(report)) return true;
    return getMissingFilters(report, filters).length > 0;
};

export default isRunReportDisabled;
