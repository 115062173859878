import React from 'react';
import Financials from '../reports/report_tables/financials';
import GiftVouchers from '../reports/report_tables/gift_vouchers';
import Appointments from '../reports/report_tables/appointments/index';
import Commission from '../reports/report_tables/commission';
import Stock from '../reports/report_tables/stock';
import Clients from '../reports/report_tables/clients';

function TableSelector({ category, report, group, ...props }) {
    const id = `${category}_${report}${group ? `_${group}` : ''}`;

    switch (id) {
        case 'financials_invoice-log_status':
            return <Financials.InvoiceLog.Status {...props} />;
        case 'financials_sales-by_categories':
            return <Financials.SalesBy.Category {...props} />;
        case 'financials_sales-by_clients':
            return <Financials.SalesBy.Client {...props} />;
        case 'financials_sales-by_items':
            return <Financials.SalesBy.Item {...props} />;
        case 'financials_sales-by_products':
            return <Financials.SalesBy.Product {...props} />;
        case 'financials_sales-by_services':
            return <Financials.SalesBy.Service {...props} />;
        case 'financials_sales-by_courses':
            return <Financials.SalesBy.Course {...props} />;
        case 'financials_sales-by_staffs':
            return <Financials.SalesBy.Staff {...props} />;
        case 'financials_sales-by_types':
            return <Financials.SalesBy.Type {...props} />;
        case 'financials_category-and-type-sales':
            return <Financials.CategoryAndTypeSales.Category {...props} />;
        case 'financials_special-offer-sales':
            return <Financials.SpecialOfferSales.Items {...props} />;
        case 'financials_detailed-invoice-log':
            return <Financials.DetailedInvoiceLog.Items {...props} />;
        case 'financials_payment-log':
            return <Financials.PaymentLog.Status {...props} />;
        case 'financials_payment-totals':
            return <Financials.PaymentTotals.Items {...props} />;
        case 'financials_future-appointments_day':
            return <Financials.FutureAppointments.Day {...props} />;
        case 'clients_top-spender':
            return <Clients.TopSpender.Result {...props} />;
        case 'financials_future-appointments_staff':
            return <Financials.FutureAppointments.Staff {...props} />;
        case 'financials_future-appointments_category':
            return <Financials.FutureAppointments.Category {...props} />;
        case 'financials_outstanding-courses':
            return <Financials.OutstandingCourses {...props} report={report} />;
        case 'financials_outstanding-acc-balances':
            return <Financials.OutstandingAccBalances {...props} report={report} />;
        case 'financials_service-sales-performance':
            return <Financials.ServiceSalesPerformance.Result {...props} />;
        case 'commission_staff-commission-sales':
            return <Commission.StaffCommissionSales.Result {...props} />;
        case 'financials_staff-sales-and-performance':
            return <Financials.StaffSalesAndPerformanceReport.Result {...props} />;
        case 'gift_gift-voucher-totals':
            return <GiftVouchers.GiftVouchersTotals.Result {...props} />;
        case 'gift_outstanding-gift-vouchers':
            return <GiftVouchers.OutstandingGiftVouchers.Result {...props} />;
        case 'appointments_consultation-conversion-log':
            return <Appointments.ConversionLog.Items {...props} />;
        case 'appointments_conversion-by-staff-basic':
            return <Appointments.ConversionByStaffBasic.Items {...props} />;
        case 'appointments_conversion-by-staff-advanced':
            return <Appointments.ConversionByStaffAdvanced.Items {...props} />;
        case 'appointments_conversion-by-location':
            return <Appointments.ConversionByLocation.Items {...props} />;
        case 'appointments_upsell-log':
            return <Appointments.UpsellLog.Items {...props} />;
        case 'appointments_appointment-log-and-slippage':
            return <Appointments.AppointmentLogAndSlippage {...props} />;
        case 'appointments_appointment-log':
            return <Appointments.AppointmentLog {...props} />;
        case 'appointments_upsell-by-staff':
            return <Appointments.UpsellByStaff.Items {...props} />;
        case 'appointments_upsell-by-location':
            return <Appointments.UpsellByLocation.Items {...props} />;
        case 'appointments_rebookings':
            return <Appointments.Rebooking.Items {...props} />;
        case 'financials_vat_vat':
            return <Financials.Vat {...props} />;
        case 'stock_toxin-usage-summary':
            return <Stock.ToxinUsageSummary {...props} />;
        case 'stock_toxin-usage-by-area':
            return <Stock.ToxinUsageByArea {...props} />;
        case 'stock_toxin-usage-by-staff':
            return <Stock.ToxinUsageByStaff {...props} />;
        case 'stock_toxin-usage-log':
            return <Stock.ToxinUsageLog {...props} />;
        default:
            break;
    }
    return <div />;
}

export default TableSelector;
