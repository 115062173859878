import * as actions from '../actions/types';

const initialState = {
    searchBarFilter: '',
    openedAccordion: '',
    reportFiltersState: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_REPORTS_SEARCH_BAR_FILTER:
            return { ...state, searchBarFilter: action.payload };
        case actions.SET_ACCORDIONS_STATE:
            return { ...state, openedAccordion: action.payload };
        case actions.SET_FILTERS_STATE:
            return { ...state, reportFiltersState: action.payload };
        case actions.CLEAR_FILTERS_STATE:
            return { ...state, reportFiltersState: initialState.reportFiltersState };
        default:
            return state;
    }
};

export default reducer;
