import React, { useLayoutEffect, useState } from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import defaultColumns from './defaultColumns';
import generateToxinColumn from '../../../../../utils/helpers/generateToxinColumn';

function Result({ values, query, filterBtn, loading, setValues }) {
    const filters = query;
    const [columns, setColumns] = useState([...defaultColumns, generateToxinColumn('Total')]);

    useLayoutEffect(() => {
        if (query.areas) {
            const newColumns = [
                ...defaultColumns,
                ...query.areas.map(generateToxinColumn),
                generateToxinColumn('Total')
            ];
            setColumns(newColumns);
        }
    }, [query]);

    return (
        <DefaultMaterialTable
            title={'Toxin usage by area'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            canShowFilters
            showFiltersOnHeader
        />
    );
}

export default Result;
