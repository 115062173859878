import React from 'react';
import { Grid } from '@material-ui/core';
import FilterSelector from './components/FilterSelector';
import CheckboxFilter from './components/CheckboxFilter';

function SalesByFilters({ group, handleSetFilters, filters }) {
    return (
        <Grid container>
            <Grid item md={4}>
                <FilterSelector
                    group={group}
                    handleFilterChange={handleSetFilters}
                    label={group?.charAt(0).toUpperCase() + group?.slice(1)}
                    filters={filters}
                />
            </Grid>
            <Grid item md={4}>
                <FilterSelector
                    group={'categories'}
                    mainGroup={group}
                    handleFilterChange={handleSetFilters}
                    label={'Categories'}
                    filters={filters}
                />
            </Grid>
            <Grid item md={4}>
                <FilterSelector
                    group={'location'}
                    handleFilterChange={handleSetFilters}
                    label={'Locations'}
                    filters={filters}
                />
            </Grid>
            {group !== 'clients' && group !== 'staffs' && (
                <Grid item md={4}>
                    <FilterSelector
                        group={'staffs'}
                        handleFilterChange={handleSetFilters}
                        label={'Staff'}
                        filters={filters}
                    />
                </Grid>
            )}
            {group === 'services' && (
                <Grid container>
                    <Grid item>
                        <CheckboxFilter
                            handleFilterChange={handleSetFilters}
                            label="Include redeemed services and redemption values"
                            filters={filters}
                            property="includeRedeemed"
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default SalesByFilters;
