import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { toLocaleString } from '../../../../../collums-components/helpers/index';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';

function Product({ values, query, filterBtn, loading, setValues }) {
    const filters = query;

    const table = useMemo(() => {
        return {
            columns: [
                { title: 'Product', field: 'product' },
                { title: 'Category', field: 'category' },
                { title: '#', field: 'quantity', type: 'quantity' },
                {
                    title: 'Discount',
                    field: 'discount',
                    type: 'currency',
                    //eslint-disable-next-line
                    render: data => (
                        <span style={data.discount < 0 ? { color: 'red' } : {}}>{toLocaleString(data.discount)}</span>
                    )
                },
                {
                    title: 'Net Price',
                    field: 'netPrice',
                    type: 'currency',
                    //eslint-disable-next-line
                    render: data => (
                        <span style={data.netPrice < 0 ? { color: 'red' } : {}}>{toLocaleString(data.netPrice)}</span>
                    )
                },
                {
                    title: 'Tax',
                    field: 'tax',
                    type: 'currency',
                    //eslint-disable-next-line
                    render: data => <span style={data.tax < 0 ? { color: 'red' } : {}}>{toLocaleString(data.tax)}</span>
                },
                {
                    title: 'Total',
                    field: 'totalSales',
                    type: 'currency',
                    //eslint-disable-next-line
                    render: data => (
                        <span style={data.totalSales < 0 ? { color: 'red' } : {}}>
                            {toLocaleString(data.totalSales)}
                        </span>
                    )
                },
                {
                    title: 'Ave Net Sales',
                    field: 'aveNetSales',
                    type: 'currency',
                    //eslint-disable-next-line
                    render: data => (
                        <span style={data.aveNetSales < 0 ? { color: 'red' } : {}}>
                            {toLocaleString(data.aveNetSales)}
                        </span>
                    )
                },

                {
                    title: '% Net Total',
                    field: 'percentageNetTotal',
                    type: 'percentage',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'percentageNetTotal')
                }
            ]
        };
    }, []);
    return (
        <DefaultMaterialTable
            loading={loading}
            title="Sales By Product"
            columns={table.columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            filters={filters}
            setValues={setValues}
        />
    );
}

Product.propTypes = { values: PropTypes.array, query: PropTypes.object };

export default Product;
