import React from 'react';
import DefaultMaterialTable from '../../../../../common/DefaultMaterialTable';
import getColumns from './columns';

function Items({ values, query, filterBtn, loading, setValues }) {
    const filters = query;
    const columns = getColumns();

    return (
        <>
            <DefaultMaterialTable
                title={'Rebookings by Location'}
                filters={filters}
                columns={columns}
                data={values.byLocation}
                options={{ sorting: true }}
                filterBtn={filterBtn}
                loading={loading}
                setValues={setValues}
                canShowFilters={false}
                showFiltersOnHeader={false}
            />
            <DefaultMaterialTable
                title={'Rebookings by Category'}
                filters={filters}
                columns={columns}
                data={values.byCategory}
                options={{ sorting: true }}
                filterBtn={filterBtn}
                loading={loading}
                setValues={setValues}
                canShowFilters={false}
                showFiltersOnHeader={false}
            />
        </>
    );
}

export default Items;
