// const { toLocaleString } = require('../../../../../../collums-components/helpers/index');

const getColumns = () => {
    return [
        {
            title: '',
            field: 'name'
        },
        {
            title: 'New clients #',
            field: 'newClientTotal'
        },
        {
            title: 'Rebooked #',
            field: 'newClientRebooked'
        },
        {
            title: 'Rebooked %',
            field: 'newClientRebookedRate',
            render: data =>
                (data.newClientRebookedRate * 100).toLocaleString('en-GB', { maximumFractionDigits: 1 }) + '%'
        },
        {
            title: 'Existing clients #',
            field: 'existingClientTotal'
        },
        {
            title: 'Rebooked #',
            field: 'existingClientRebooked'
        },
        {
            title: 'Rebooked %',
            field: 'existingClientRebookedRate',
            render: data =>
                (data.existingClientRebookedRate * 100).toLocaleString('en-GB', { maximumFractionDigits: 1 }) + '%'
        },
        {
            title: 'Total #',
            field: 'total'
        },
        {
            title: 'Rebooked #',
            field: 'rebooked'
        },
        {
            title: 'Rebooked %',
            field: 'rebookedRate',
            render: data => (data.rebookedRate * 100).toLocaleString('en-GB', { maximumFractionDigits: 1 }) + '%'
        }
    ];
};

export default getColumns;
