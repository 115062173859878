import Api from './config';

class CoherentApi {
    static async createPaymentLink(data) {
        return Api.request({
            method: 'POST',
            url: '/coherence/create-payment-link',
            data
        });
    }
}

export default CoherentApi;
