import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

import common from './common';
import dashboard from './dashboard';
import reports from './reports';
import scheduledReports from './scheduledReports';

export default combineReducers({
    toastr,
    common,
    dashboard,
    reports,
    scheduledReports
});
