import { Link } from '@material-ui/core';
import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers';
import apps from '../../../../../config';

const columns = setInvoice => {
    return [
        { title: 'Date/Time', field: 'invoiceDate', type: 'date' },
        {
            title: 'Invoice',
            field: 'invoiceCode',
            type: 'unique',
            //eslint-disable-next-line
            render: data => (
                <Link
                    onClick={evt => {
                        evt.stopPropagation();
                        setInvoice({ id: data.invoiceId });
                    }}
                    style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
                >
                    {data.invoiceCode}
                </Link>
            )
        },
        {
            title: 'Client',
            field: 'client',
            type: 'unique',
            //eslint-disable-next-line
            render: data => (
                <Link
                    onClick={() => {
                        window.open(`${apps.calendarUrl}/customer/${data.clientId}/general`);
                    }}
                    style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
                >
                    {data.client}
                </Link>
            )
        },
        {
            title: 'Course',
            field: 'courseName',
            type: 'longText'
        },
        {
            title: 'Net Price',
            field: 'netPrice',
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.netPrice < 0 ? { color: 'red' } : {}}>{toLocaleString(data.netPrice)}</span>
            )
        },
        { title: 'Services #', field: 'servicesQuantity', type: 'quantity' },
        {
            title: 'Service Value',
            field: 'serviceValue',
            type: 'currency', //eslint-disable-next-line
            render: data => (
                <span style={data.serviceValue < 0 ? { color: 'red' } : {}}>{toLocaleString(data.serviceValue)}</span>
            )
        },
        { title: 'Remaining', field: 'servicesRemaining', type: 'quantity' },
        {
            title: 'Remaining Value',
            field: 'remainingValue',
            type: 'currency', //eslint-disable-next-line
            render: data => (
                <span style={data.remainingValue < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.remainingValue)}
                </span>
            )
        },
        { title: 'Remaining Time (hrs)', field: 'hoursRemaining', type: 'hoursAndMinutes' },
        { title: 'Exp Date', field: 'expDate', type: 'date' },
        { title: 'Location', field: 'clinicName' }
    ];
};

export default columns;
