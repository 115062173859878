import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Dialog from '../filters/Dialog';
import TableWrapper from '../common/TableWrapper';
import DefaultMaterialTable from '../common/DefaultMaterialTable';
import TableSelector from '../common/TableSelector';
import ReportApi from '../../api/ReportApi';
import InvoiceModal from '../../collums-components/components/common/InvoiceModal';
import reportsWithoutFilters from './../../constants/reportsWithoutFilters';
import reports from './../../constants/reports';
import { flattenDeep } from 'lodash';
import { isAvailableByPlan } from '../../collums-constants/utils';
import { useAccessTier } from '../../collums-components/hooks/accessTier';
import { useClinics } from '../../collums-components/hooks/clinics';
import transformIDs from '../../utils/helpers/transformIDs';
import cloneDeep from 'lodash/cloneDeep';
import { useReportFilters } from '../../hooks/ReportFilters';
import { useReportFilterItems } from '../../hooks/reportFilterItems';
import transformQueryObjects from '../../utils/helpers/transformQueryObjects';
import reportQueryObjectKeys from '../../constants/reportQueryObjectKeys';

export const ReportContext = createContext({});

function ReportsContainer() {
    const { category, report } = useParams();
    const { toxins, practitioners, serviceCategories } = useReportFilterItems();
    const { openFeatureModal } = useAccessTier();
    const { saveFilters } = useReportFilters();
    const [open, setOpen] = useState(report !== 'outstanding-acc-balances');
    const [loading, setLoading] = useState(false);
    const { clinics } = useClinics();
    const [query, setQuery] = useState(null);
    const [group, setGroup] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [values, setValues] = useState([]);

    useEffect(() => {
        try {
            const groupedReports = flattenDeep(Object.values(reports).map(el => Object.values(el)));
            const targetReport = groupedReports.find(el => el.id === report);
            if (
                targetReport &&
                (targetReport.isIncomplete || (targetReport.accessTier && !isAvailableByPlan(targetReport.accessTier)))
            )
                openFeatureModal('/reports');
        } catch (err) {
            console.error(err);
        }
        //trigger first run of report without filters
        if (report === 'outstanding-acc-balances') {
            generate_report({}, group);
        }
        //eslint-disable-next-line
    }, [group, report]);

    const loadReport = useCallback(
        (query, group) => {
            if (
                (category && report && query && (group || reportsWithoutFilters.includes(report))) ||
                report === 'outstanding-acc-balances'
            ) {
                const reportGroup = group || 'list';
                setValues([]);
                setLoading(true);

                // uses a copy to not remove labels from the query object
                const copyQuery = cloneDeep(query);
                Object.keys(copyQuery).forEach(key => {
                    if (typeof copyQuery[key] === 'object' && !reportQueryObjectKeys.includes(key)) {
                        copyQuery[key] = copyQuery[key].map(item => item.value);
                    }
                });
                transformQueryObjects(copyQuery);
                transformIDs(copyQuery, { clinics, toxins, practitioners, serviceCategories });

                try {
                    ReportApi.getReport({ category, report, group: reportGroup, query: { ...copyQuery } }).then(
                        report => {
                            setValues(
                                report?.data?.map(res => {
                                    return { ...res, ...(res.tax ? { tax: parseFloat(res.tax) } : {}) };
                                }) ||
                                    report ||
                                    []
                            );

                            setLoading(false);
                        }
                    );
                } catch (err) {
                    setLoading(false);
                }
            }
        },
        [category, report, clinics, toxins, practitioners, serviceCategories]
    );

    if (!category || !report) return null;

    const generate_report = (q, group) => {
        if (values) setValues([]);
        if (q?.includeZeroSales === false) delete q.includeZeroSales;

        if (typeof q === 'object') {
            Object.keys(q).forEach(key => {
                if ([undefined, null].includes(q[key])) delete q[key];
            });
        }

        saveFilters(q);
        setQuery(q);
        setGroup(group);
        loadReport(q, group);
        setTimeout(() => setOpen(false), 200);
    };

    const thereAreResults = query && (group || reportsWithoutFilters.includes(report));

    return (
        <div>
            <Dialog
                open={open}
                handleClose={() => setOpen(false)}
                report={report}
                generateReport={generate_report}
                category={category}
                setQuery={setQuery}
            />
            {invoice && (
                <InvoiceModal
                    invoice={invoice}
                    closeInvoiceModal={() => setInvoice(null)}
                    loadInvoice={inv => {
                        setInvoice(null);
                        setTimeout(() => setInvoice({ id: inv }), 200);
                    }}
                />
            )}
            <TableWrapper>
                {thereAreResults && (
                    <TableSelector
                        category={category}
                        report={report}
                        query={query}
                        group={group}
                        values={values}
                        setValues={setValues}
                        setInvoice={inv => setInvoice(inv)}
                        loading={loading}
                        filterBtn={{
                            onClick: () => setOpen(true),
                            runReport: () => generate_report(query, group)
                        }}
                        setLoading={setLoading}
                        generateReport={generate_report}
                    />
                )}

                {!thereAreResults && (
                    <DefaultMaterialTable
                        options={{ search: true }}
                        filterBtn={{
                            onClick: () => setOpen(true)
                        }}
                    />
                )}
            </TableWrapper>
        </div>
    );
}

export default ReportsContainer;
