import React, { useEffect, useCallback, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import getMissingFilters from '../../../utils/helpers/getMissingFilters';
import { filters as filterStyles } from './styles';
import { useReportFilterItems } from '../../../hooks/reportFilterItems';

function StaffListFilter({ classes, label, handleFilterChange, filters, report }) {
    const { practitioners } = useReportFilterItems();

    const [selectedStaffs, setSelectedStaffs] = useState([]);
    const [isFieldRequired, setIsFieldRequired] = useState(false);
    const [selectedClinic, setSelectedClinic] = useState(null);

    const [filteredPractitioners, setFilteredPractitioners] = useState(practitioners);

    useEffect(() => {
        setIsFieldRequired(getMissingFilters(report, {}).includes('staffs'));
    }, [report]);

    const handleChange = useCallback((_, items) => {
        setSelectedStaffs(items);
        handleFilterChange({
            staffs: (items || []).map(el => el.displayName)
        });
    }, [handleFilterChange]);

    useEffect(() => {
        // We need to check the selected clinic here and filter the list if there is one
        if (!filters.clinicId) {
            setFilteredPractitioners(practitioners);
            return;
        }

        // If a new clinic has been selected, we'll clear the list
        if (filters.clinicId !== selectedClinic) {
            setSelectedClinic(filters.clinicId);
            handleChange(null, []);
        }

        setFilteredPractitioners(practitioners.filter(x => x.locations.map(y => y.clinic).includes(filters.clinicId)));
    }, [practitioners, filters, handleChange, selectedClinic]);

    useEffect(() => {
        if (!practitioners.length) return;

        const value = (filters['staffs'] || [])
            .map(el => practitioners.find(practitioner => practitioner.displayName === el))
            .filter(el => el);
        setSelectedStaffs(value);
    }, [filters, practitioners]);


    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={classes.autocomplete}
                options={filteredPractitioners}
                value={selectedStaffs}
                onChange={handleChange}
                label={label}
                getOptionLabel={option => option.displayName}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                        error={!selectedStaffs?.length && isFieldRequired}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{option.displayName}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filterStyles)(StaffListFilter);
