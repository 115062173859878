import React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import { filters as filterStyles } from './styles';

const DaysSinceInput = ({ classes, value, onChange, error }) => {
    return (
        <TextField
            value={value}
            label=""
            variant="outlined"
            onChange={e => {
                if (isNaN(e.target.value) || e.target.value.includes('-') || e.target.value.includes(' ')) {
                    e.target.value = value;
                    return;
                }
                const newValue = (() => {
                    if (!e.target.value) {
                        return '';
                    }
                    return parseInt(e.target.value);
                })();
                onChange(newValue);
            }}
            InputProps={{
                classes: {
                    input: classes.autocompleteInputProps
                }
            }}
            InputLabelProps={{
                className: classes.autocompleteLabelProps
            }}
            error={error}
        />
    );
};

export default withStyles(filterStyles)(DaysSinceInput);
