import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from './style/theme';
import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import './style/index.css';

import configureStore from './redux/store/config';

import App from './components/app/App';
import Routes from './routes';
import Header from './components/common/Header';
import Toastr from './components/common/Toastr';
import { AccessTierProvider } from './collums-components/hooks/accessTier';
import moment from 'moment-timezone';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ClinicsProvider } from './collums-components/hooks/clinics';
import { ReportFiltersProvider } from './hooks/ReportFilters';
import { ReportFilterItemsProvider } from './hooks/reportFilterItems';
import { initSentry } from './collums-components/helpers/sentry';

initSentry();
moment.tz.setDefault(process.env.REACT_APP_TIMEZONE || 'Europe/London');

ReactDOM.render(
    <React.StrictMode>
        <Provider store={configureStore()}>
            <CssBaseline>
                <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                    <MuiThemeProvider theme={theme}>
                        <BrowserRouter>
                            <AccessTierProvider>
                                <ReportFilterItemsProvider>
                                    <ClinicsProvider>
                                        <ReportFiltersProvider>
                                            <App>
                                                <Header />
                                                <Routes />
                                                <Toastr />
                                            </App>
                                        </ReportFiltersProvider>
                                    </ClinicsProvider>
                                </ReportFilterItemsProvider>
                            </AccessTierProvider>
                        </BrowserRouter>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </CssBaseline>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
