import React, { useEffect, useState, useCallback } from 'react';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import Modal from '../../collums-components/components/common/Modal';
import ReportsSelect from './components/ReportsSelect';
import SalesByFilters from './SalesByFilters';
import { modal } from './styles';
import DatePeriodsFilter from './components/DatePeriodsFilter';
import InvoiceLogsFilters from './InvoiceLogsFilters';
import OutstandingCoursesFilters from './OutstandingCoursesFilters';
import reportsWithoutFilters from './../../constants/reportsWithoutFilters';
import FutureAppointmentsFilter from './FutureAppointmentsFilter';
import VatFilters from './VatFilters';
import reportsWithoutPeriod from './../../constants/reportsWithoutPeriod';
import IncludeTaxFilter from './components/IncludeTaxFilter';
import IncludeExpiredFilter from './components/IncludeExpiredFilter';
import { toastr } from 'react-redux-toastr';
import SingleLocationFilter from './components/SingleLocationFilter';
import DateFilterInputs from './components/DateFilterInputs';
import getDefaultFilters from '../../utils/helpers/getDefaultFilters';
import AreaFilters from './components/AreaFilters';
import { useLocation } from 'react-router-dom';
import getQueryFiltersByReport from '../../utils/helpers/getQueryFiltersByReport';
import queryParamsReducer from '../../utils/helpers/queryParamsReducer';
import SingleToxinFilter from './components/SingleToxinFilter';
import MultiLocationFilter from './components/MultiLocationFilter';
import reportsWithMultiLocationsFilter from '../../constants/reportsWithMultiLocationsFilter';
import reportsWithSingleLocationFilter from '../../constants/reportsWithSingleLocationFilter';
import StaffListFilter from './components/StaffListFilter';
import ToxinListFilter from './components/ToxinListFilter';
import reportsWithStaffListFilter from '../../constants/reportsWithStaffListFilter';
import ServiceCategoriesFilter from './components/ServiceCategoriesFilter';
import ApptSlippageFilters from './components/ApptSlippageFilters';
import { useReportFilterItems } from '../../hooks/reportFilterItems';
import { useReportFilters } from '../../hooks/ReportFilters';
import getFiltersInUse from '../../utils/helpers/getFiltersInUse';
import isRunReportDisabled from '../../utils/helpers/isRunReportDisabled';
import reportWithIncludeTaxes from '../../constants/reportWithIncludeTaxes';
import StatusFilter from './components/StatusFilter';
import PaymentMethodsFilters from './components/PaymentMethodsFilters';
import startOfDay from 'date-fns/startOfDay';
import AccountPaymentsFilter from './components/AccountPaymentsFilter';
import ShowVoidedFilter from './components/ShowVoidedFilter';
import ResultsFilter from './components/ResultsFilter';
import { piecesOfTimeInPast } from './helpers/periodFilter';
import AppointmentLogFilters from './AppointmentLogFilters';

//

function Dialog({ open, handleClose, classes, report, generateReport, category, setQuery }) {
    const { savedFilters } = useReportFilters();
    const { organisation } = useReportFilterItems();
    const [group, setGroup] = useState(report === 'invoice-log' ? 'status' : report === 'vat' ? 'vat' : null);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [filters, setFilters] = useState(getDefaultFilters(report, organisation));
    const [toValue, setToValue] = useState();
    const [fromValue, setFromValue] = useState();
    const location = useLocation();

    useEffect(() => {
        if (!isFirstRender) return;
        const queryFilters = getQueryFiltersByReport(report);
        if (queryFilters.length) {
            const queryParams = new URLSearchParams(location.search);
            const queryReducer = queryFilters.reduce(
                (acc, el) => queryParamsReducer(acc, el, queryParams),
                filters ?? {}
            );
            handleSetFilters(queryReducer);
        }
        if (savedFilters) {
            const reportFilters = getFiltersInUse(report);
            const similarFilters = Object.keys(savedFilters).filter(key => {
                return [...reportFilters, 'from', 'to', 'period'].includes(key);
            });
            const newQueryParams = similarFilters.reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: savedFilters[key]
                }),
                {}
            );
            setQuery(newQueryParams);
            setFilters({
                ...filters,
                ...newQueryParams
            });
        }
        setIsFirstRender(false);
        // eslint-disable-next-line
    }, [report, location.search]);

    useEffect(() => {
        if (filters.to && filters.from) {
            setToValue(filters.to);
            setFromValue(filters.from);
        }
    }, [filters]);

    const handleSetFilters = useCallback(filter => setFilters({ ...filters, ...filter }), [filters]);

    const checkDate = useCallback(() => {
        if (filters.to || filters.from) {
            if (filters.dateError) {
                toastr.error('From date must be before to date.');
                return false;
            }
        }

        if (filters.futureDatesOnly) {
            const dateFrom = new Date(filters.from);
            const isFutureDate = dateFrom.getTime() - startOfDay(new Date()).getTime() >= 0;
            if (!isFutureDate) {
                toastr.warning('The date cannot be in past', { timeOut: 10000 });
                return false;
            }
        }

        return true;
    }, [filters]);

    if (isFirstRender) return <></>;

    const getMinDateRange = () => {
        if (filters?.futureDatesOnly) {
            return startOfDay(new Date());
        }

        return null;
    };

    return (
        <Modal
            isOpen={open}
            onClose={handleClose}
            size="md"
            title=""
            cancelLabel="Cancel"
            confirmLabel="Run report"
            onConfirm={() => {
                if (checkDate()) {
                    generateReport(filters, group);
                }
            }}
            disableConfirmButton={isRunReportDisabled({ filters, group, report })}
            onCancel={handleClose}
        >
            <Typography variant="h2" style={{ fontSize: 18, textAlign: 'center', fontWeight: 500 }}>
                Filters
            </Typography>
            <Grid className={classes?.filtersArea} ml={2}>
                {!reportsWithoutPeriod.includes(report) && (
                    <Box>
                        <Typography variant="h4" style={{ fontSize: 14, fontWeight: 500 }}>
                            Select a time period
                        </Typography>
                        <div container style={{ display: 'flex', alignItems: 'center' }}>
                            <DatePeriodsFilter
                                handleFilterChange={handleSetFilters}
                                periodValue={filters.period || 'today'}
                                futureDatesOnly={!!filters?.futureDatesOnly}
                            />
                            <DateFilterInputs
                                handleFilterChange={handleSetFilters}
                                filters={filters}
                                toValue={toValue}
                                fromValue={fromValue}
                                minDate={getMinDateRange()}
                            />
                        </div>
                    </Box>
                )}
                <Box>
                    {!reportsWithoutFilters.includes(report) && (
                        <>
                            <h4>Filter By</h4>
                            <ReportsSelect
                                report={report}
                                handleGroupChange={setGroup}
                                handleSetFilters={handleSetFilters}
                                filters={filters}
                                group={group}
                                category={category}
                            />
                        </>
                    )}

                    {report === 'appointment-log' && (
                        <>
                            <AppointmentLogFilters handleFilterChange={handleSetFilters} filters={filters} />
                        </>
                    )}

                    {report === 'detailed-invoice-log' && (
                        <>
                            <AccountPaymentsFilter handleFilterChange={handleSetFilters} filters={filters} />
                        </>
                    )}

                    {report === 'payment-log' && (
                        <>
                            <StatusFilter label="Status" handleFilterChange={handleSetFilters} filters={filters} />

                            <PaymentMethodsFilters
                                label="Payment Methods"
                                handleFilterChange={handleSetFilters}
                                filters={filters}
                            />
                        </>
                    )}

                    {report === 'appointment-log-and-slippage' && (
                        <ApptSlippageFilters handleFilterChange={handleSetFilters} filters={filters} report={report} />
                    )}

                    {reportsWithSingleLocationFilter.includes(report) && (
                        <SingleLocationFilter
                            label={'Location'}
                            handleFilterChange={handleSetFilters}
                            filters={filters}
                            report={report}
                        />
                    )}

                    {reportsWithMultiLocationsFilter.includes(report) && (
                        <MultiLocationFilter
                            label={'Locations'}
                            handleFilterChange={handleSetFilters}
                            filters={filters}
                            report={report}
                        />
                    )}

                    {report === 'toxin-usage-by-area' && (
                        <SingleToxinFilter
                            label={'Toxin'}
                            handleFilterChange={handleSetFilters}
                            filters={filters}
                            report={report}
                        />
                    )}

                    {report === 'toxin-usage-log' && (
                        <ToxinListFilter
                            label={'Toxins'}
                            handleFilterChange={handleSetFilters}
                            filters={filters}
                            report={report}
                        />
                    )}

                    {['toxin-usage-by-area', 'toxin-usage-by-staff'].includes(report) && (
                        <AreaFilters
                            label={'Areas'}
                            handleFilterChange={handleSetFilters}
                            filters={filters}
                            report={report}
                        />
                    )}

                    {reportsWithStaffListFilter.includes(report) && (
                        <StaffListFilter
                            label={'Staff'}
                            handleFilterChange={handleSetFilters}
                            filters={filters}
                            report={report}
                        />
                    )}

                    {report === 'appointment-log-and-slippage' && (
                        <ServiceCategoriesFilter
                            label={'Category - Services performed'}
                            handleFilterChange={handleSetFilters}
                            filters={filters}
                            report={report}
                        />
                    )}

                    {group && report === 'sales-by' && (
                        <SalesByFilters group={group} handleSetFilters={handleSetFilters} filters={filters} />
                    )}

                    {group && report === 'future-appointments' && (
                        <FutureAppointmentsFilter group={group} handleSetFilters={handleSetFilters} filters={filters} />
                    )}

                    {reportWithIncludeTaxes.includes(report) && (
                        <IncludeTaxFilter handleSetFilters={handleSetFilters} filters={filters} />
                    )}

                    {group && report === 'invoice-log' && (
                        <>
                            <InvoiceLogsFilters
                                group={group}
                                handleSetFilters={handleSetFilters}
                                filters={filters}
                                report={report}
                            />
                            <ShowVoidedFilter handleFilterChange={handleSetFilters} filters={filters} />
                        </>
                    )}

                    {group && report === 'vat' && (
                        <VatFilters group={group} handleSetFilters={handleSetFilters} filters={filters} />
                    )}

                    {report === 'outstanding-courses' && (
                        <OutstandingCoursesFilters handleSetFilters={handleSetFilters} filters={filters} />
                    )}

                    {['outstanding-gift-vouchers', 'gift-voucher-totals'].includes(report) && (
                        <IncludeExpiredFilter handleFilterChange={handleSetFilters} filters={filters} />
                    )}

                    {(report === 'payment-log' || report === 'payment-totals') && (
                        <>
                            <AccountPaymentsFilter handleFilterChange={handleSetFilters} filters={filters} />
                        </>
                    )}

                    {report === 'top-spender' && (
                        <>
                            <Box>
                                <Typography variant="h4" style={{ fontSize: 14, fontWeight: 500 }}>
                                    Select a time period
                                </Typography>
                                <div container style={{ display: 'flex', alignItems: 'center' }}>
                                    <DatePeriodsFilter
                                        handleFilterChange={handleSetFilters}
                                        periodValue={filters.period || 'allTime'}
                                        futureDatesOnly={!!filters?.futureDatesOnly}
                                        customFilters={piecesOfTimeInPast}
                                    />
                                    <DateFilterInputs
                                        handleFilterChange={handleSetFilters}
                                        filters={filters}
                                        toValue={toValue}
                                        fromValue={fromValue}
                                        minDate={getMinDateRange()}
                                        disabled={true}
                                    />
                                </div>
                            </Box>
                            <ResultsFilter handleFilterChange={handleSetFilters} filters={filters} />
                        </>
                    )}
                </Box>
            </Grid>
        </Modal>
    );
}

export default withStyles(modal)(Dialog);
