import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers';

const columns = [
    { title: 'Category', field: 'category' },
    { title: 'Service', field: 'service' },
    { title: '#', field: 'quantity', type: 'quantity' },
    {
        title: 'Discount',
        field: 'discount',
        type: 'currency',
        //eslint-disable-next-line
        render: data => <span style={data.discount < 0 ? { color: 'red' } : {}}>{toLocaleString(data.discount)}</span>
    },
    {
        title: 'Net Sales',
        field: 'netSales',
        type: 'currency',
        //eslint-disable-next-line
        render: data => <span style={data.netSales < 0 ? { color: 'red' } : {}}>{toLocaleString(data.netSales)}</span>
    },
    {
        title: 'Ave Net Sales',
        field: 'aveNetSales',
        type: 'currency',
        total: 'average',
        //eslint-disable-next-line
        render: data => (
            <span style={data.aveNetSales < 0 ? { color: 'red' } : {}}>{toLocaleString(data.aveNetSales)}</span>
        )
    },
    {
        title: 'Duration (hrs)',
        field: 'duration',
        type: 'hoursAndMinutes',
        //eslint-disable-next-line
        render: data => <span>{data.duration}</span>
    },
    {
        title: 'Net Sales Per Hr',
        field: 'netSalesPerHour',
        type: 'currency',
        total: 'average',
        //eslint-disable-next-line
        render: data => (
            <span style={data.netSalesPerHour < 0 ? { color: 'red' } : {}}>{toLocaleString(data.netSalesPerHour)}</span>
        )
    }
];

export default columns;
