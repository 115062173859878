const transformIDs = (query, { clinics, toxins, practitioners, serviceCategories }) => {
    if (query.clinic && clinics.length) {
        const clinicObject = clinics.find(clinic => clinic.accountName === query.clinic);
        query.clinic = clinicObject?.id;
    }

    if (query.toxin && toxins.length) {
        const toxinObject = toxins.find(toxin => toxin.name === query.toxin);
        query.toxin = toxinObject?.id;
    }
    if (query.toxins && toxins.length) {
        query.toxins = query.toxins
            .map(toxinName => {
                return toxins.find(toxin => {
                    return toxin.name === toxinName;
                })?.id;
            })
            .filter(el => el)
            .join(',');
    }

    if (query.clinics && clinics.length) {
        const clinicList = query.clinics
            .map(clinic => {
                return clinics.find(clinicItem => {
                    return clinic === clinicItem.accountName;
                })?.id;
            })
            .filter(el => el)
            .join(',');
        query.clinics = clinicList;
    }
    if (query.staffs && practitioners.length) {
        query.staffs = query.staffs
            .map(practitionerName => {
                return practitioners.find(practitioner => {
                    return practitioner.displayName === practitionerName;
                })?.id;
            })
            .filter(el => el)
            .join(',');
    }

    if (query.serviceAndCategories && serviceCategories.length) {
        query.serviceAndCategories = serviceCategories
            .filter(item => query.serviceAndCategories.includes(item.name))
            .map(el => el.id);
    }

    const objectKeys = Object.keys(query);

    ['toxin', 'clinic', 'staffs', 'toxins', 'serviceAndCategories'].forEach(key => {
        if (!query[key] && objectKeys.includes(key)) delete query[key];
    });
};

export default transformIDs;
