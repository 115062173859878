import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import styled from 'styled-components';

const ExitConfirmation = ({ setShowWarning, leaveAction }) => {
    const cancelAction = () => {
        setShowWarning(false);
    };

    const confirmAction = () => {
        leaveAction();
        setShowWarning(false);
    };

    return (
        <>
            <Dialog open={true} onClose={() => cancelAction()} aria-labelledby="exit-confirm-modal">
                <Title id="exit-confirm-modal">Your changes will not be saved</Title>
                <Content>
                    <Typography>Are you sure you want to continue?</Typography>
                </Content>
                <ActionsContainer>
                    <Button onClick={() => cancelAction()}>Cancel</Button>
                    <Button id="confirm-leave-button" onClick={() => confirmAction()}>
                        Continue
                    </Button>
                </ActionsContainer>
            </Dialog>
        </>
    );
};

export default ExitConfirmation;

const Title = styled(DialogTitle)`
    h2 {
        font-size: 16px;
        font-weight: 500;
    }
`;

const Content = styled(DialogContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-size: 14px;
    }
`;

const ActionsContainer = styled(DialogActions)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 3rem;
    & button {
        width: 80px;
        height: 38px;
        border-radius: 0;
        margin-bottom: 16px;
        text-transform: none;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 16px;
        border: 1px solid #000;
    }

    & button:nth-child(2) {
        background-color: #b41778;
        color: white;
        border: 0px solid #000;
    }
`;
