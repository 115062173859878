import Category from './Category';
import Client from './Client';
import Item from './Item';
import Product from './Product';
import Service from './Service';
import Staff from './Staff';
import Type from './Type';
import Course from './Course';

export default { Category, Client, Item, Product, Service, Staff, Type, Course };
