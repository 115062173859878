import SalesBy from './sales_by';
import InvoiceLog from './invoice_log';
import PaymentLog from './payment_log';
import SalesByTime from './sales_by_time';
// import SalesLog from './sales_log';
import CategoryAndTypeSales from './category_and_type_sales';
import SpecialOfferSales from './special_offer_sales';
import DetailedInvoiceLog from './detailed_invoice_log';
import PaymentTotals from './payment_totals';
import FutureAppointments from './future_appointments';
import OutstandingCourses from './outstanding_courses';
import OutstandingAccBalances from './outstanding_acc_balances';
import Vat from './vat';
import ServiceSalesPerformance from './service_sales_performance';
import StaffSalesAndPerformanceReport from './staff-sales-performance';

export default {
    SalesBy,
    InvoiceLog,
    PaymentLog,
    SalesByTime,
    // SalesLog
    CategoryAndTypeSales,
    SpecialOfferSales,
    DetailedInvoiceLog,
    PaymentTotals,
    FutureAppointments,
    OutstandingCourses,
    OutstandingAccBalances,
    Vat,
    ServiceSalesPerformance,
    StaffSalesAndPerformanceReport
};
