import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import getMissingFilters from '../../../utils/helpers/getMissingFilters';
import { filters as filterStyles } from './styles';
import { useReportFilterItems } from '../../../hooks/reportFilterItems';

function ServiceCategoriesFilter({ classes, label, handleFilterChange, filters, report }) {
    const { serviceCategories } = useReportFilterItems();

    const [selectedServiceCategories, setSelectedServiceCategories] = useState([]);
    const [isFieldRequired, setIsFieldRequired] = useState(false);

    useEffect(() => {
        setIsFieldRequired(getMissingFilters(report, {}).includes('serviceAndCategories'));
    }, [report]);

    useEffect(() => {
        if (serviceCategories.length) {
            const value = (filters['serviceAndCategories'] || [])
                .map(el => serviceCategories.find(service => service.name === el))
                .filter(el => el);
            setSelectedServiceCategories(value);
        }
    }, [filters, serviceCategories]);

    const handleChange = (_, items) => {
        setSelectedServiceCategories(items);
        handleFilterChange({
            serviceAndCategories: (items || []).map(el => el.name)
        });
    };

    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={classes.autocomplete}
                options={serviceCategories}
                value={selectedServiceCategories}
                onChange={handleChange}
                label={label}
                getOptionLabel={option => option.name}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                        error={!selectedServiceCategories?.length && isFieldRequired}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{option.name}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filterStyles)(ServiceCategoriesFilter);
