const getFilterName = key => {
    return (
        {
            daysSinceCateg: 'Days since last cat sale',
            futureBooked: 'Future service booked',
            serviceAndCategories: 'Category - Services performed',
            staffs: 'Staff'
        }[key] ?? key
    );
};

export default getFilterName;
