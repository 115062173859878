import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import MuiTableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import { getEmptyRows } from '../../../../common/CollumsTable/helpers/table';
import SearchInput from '../../../../common/CollumsTable/components/SearchInput';
import Loading from '../../../../common/CollumsTable/components/Loading';
import TableInfo from '../../../../common/CollumsTable/components/TableInfo';
import { toLocaleString, useDebounce } from '../../../../../collums-components/helpers';
import { DropdownButton } from '../../../../common/DropdownButton';
import { scrollAnimation } from '../../../../../utils/helpers/common';
import WarningMessage from '../../../../common/CollumsTable/components/WarningMessage.jsx';
import { useHistory } from 'react-router-dom';
import convertToCSV from './exportCSV';
import ToxinTable from './ToxinTable';
import ReportFilterList from '../../../../common/ReportFilterList';

function ToxinTableResults({
    columns,
    rows,
    customHead,
    isLoading,
    tableInfo,
    customLoadingComponent,
    setValues,
    canShowFilters = true,
    filterBtn
}) {
    const [searchValue, setSearchValue] = useState('');
    const [searchRows, setSearchRows] = useState([]);
    const [isUsingSearch, setIsUsingSearch] = useState(false);
    const [updatedData, setUpdatedData] = useState(rows);
    const debounce = useDebounce(searchValue, 300);

    useEffect(() => {
        if (!searchValue) {
            setIsUsingSearch(false);
            setUpdatedData(rows);
        } else handleSearch();
        //eslint-disable-next-line
    }, [debounce]);

    const [showWarning, setShowWarning] = useState(false);
    const redirectTo = useRef();
    const canRedirect = useRef(false);
    const history = useHistory();

    const previousFilters = useRef(tableInfo?.filters || {});

    const emptyRows = getEmptyRows(isUsingSearch ? searchRows || [] : rows || [], 0, 10);

    useEffect(() => {
        scrollAnimation();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setUpdatedData(rows);

        if (isUsingSearch) {
            handleSearch();
        } else setSearchRows([]);
        //eslint-disable-next-line
    }, [rows]);

    useEffect(() => {
        previousFilters.current = tableInfo.filters || {};
    }, [tableInfo]);

    const handleSearch = (_ = rows) => {
        if (!_) return;
        if (!searchValue) {
            setIsUsingSearch(false);
            setUpdatedData(_);
            return;
        }

        const _searchedValues = _.filter(el => !el.isToxinRow).filter(row => {
            const check = columns
                .filter(el => !el.hide)
                .filter(col => {
                    const columnValue = (() => {
                        if (typeof row[col.field] === 'number' && col.type === 'currency') {
                            return toLocaleString(row[col.field]);
                        }
                        return row[col.field] || '';
                    })();
                    return columnValue
                        .toString()
                        .toLowerCase()
                        .includes((searchValue || '').toLowerCase());
                });

            if (check.length > 0) return true;

            return false;
        });

        setIsUsingSearch(true);
        setSearchRows(_searchedValues);
        setUpdatedData(_searchedValues);
    };

    const onChangeToxinTableOrder = useCallback(
        newOrder => {
            const filteredRows = rows.filter(el => {
                return !newOrder.some(orderedItem => orderedItem.id === el.id);
            });
            const newRowsData = [...filteredRows, ...newOrder];
            setValues(newRowsData);
            handleSearch(newRowsData);
        },
        // eslint-disable-next-line
        [rows, isUsingSearch, searchRows]
    );

    const dataRows = isUsingSearch ? searchRows : rows;

    const count = dataRows ? dataRows.length : 0;
    const loadingComponent = customLoadingComponent || <Loading />;
    const ref = useRef();

    const releaseAction = () => {
        setShowWarning(true);
    };

    const leaveAction = () => {
        setShowWarning(false);
        canRedirect.current = true;
        if (redirectTo.current) {
            const name = redirectTo.current?.innerText?.toLowerCase();
            if (['scheduled reports', 'reports'].includes(name)) {
                if ('scheduled reports' === name) {
                    history.push('/scheduled-reports');
                } else {
                    history.push('/reports');
                }
            } else {
                try {
                    redirectTo.current.click();
                } catch (err) {
                    return;
                }
            }
        }
    };

    const handleClickOutside = event => {
        if (event.target?.id === 'confirm-leave-button') return;

        if (event.target?.download) return;

        const isFromSearchButton = (event.path || []).some(el => el?.id === 'searchIcon');

        if (isFromSearchButton) return;

        const tagName = event.target?.tagName?.toLowerCase();
        if (tagName !== 'a' && tagName !== 'img') return;

        if (canRedirect.current) return;

        if (ref.current && !ref.current.contains(event.target) && count) {
            redirectTo.current = event.target;
            event.stopPropagation();
            event.preventDefault();
            if (event.target.attributes['target']?.value && event.target.attributes['target']?.value === '_blank') {
                leaveAction();
            } else {
                releaseAction();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const getFormattedData = () => {
        if (!updatedData) return;

        const mapValues = row => {
            const formattedValues = Object.keys(row).map(name => {
                if (typeof row[name] !== 'number') return row[name];
                const column = columns.find(
                    col => col.field === name || (col.subColumns && col.subColumns.find(sub => sub.field === name))
                );
                if (!column) return '';
                if (column.type === 'group') {
                    const subColumn = column.subColumns.find(sub => sub.field === name);
                    if (!subColumn) return row[name];
                    return row[subColumn.field];
                }
                return row[name];
            });
            const newRow = { ...row };
            Object.keys(newRow).forEach((name, index) => {
                newRow[name] = formattedValues[index];
            });
            return newRow;
        };

        const organizedData = rows
            .filter(el => el.isToxinRow)
            .reduce((acc, item) => {
                const elementsAppearing = updatedData
                    .filter(row => {
                        return row.toxin === item.id;
                    })
                    .map(mapValues);
                if (elementsAppearing.length) {
                    return [
                        ...acc,
                        {
                            ...item,
                            items: elementsAppearing
                        }
                    ];
                }
                return acc;
            }, []);

        return organizedData;
    };

    const toxinProducts = useMemo(() => {
        if (isUsingSearch) {
            return rows.filter(row => {
                return row.isToxinRow && searchRows.some(el => el.toxin === row.id);
            });
        }
        return rows.filter(row => row.isToxinRow);
    }, [rows, searchRows, isUsingSearch]);

    return (
        <div ref={ref}>
            <AdditionalHeadRow>
                <ReportFilterList openFilterModal={filterBtn.onClick} runReport={filterBtn.runReport}>
                    <DropdownButton
                        label={'Options'}
                        options={[
                            {
                                label: 'Export as CSV',
                                handleClick: () => {
                                    convertToCSV(getFormattedData(updatedData), tableInfo, canShowFilters, columns);
                                }
                            }
                        ]}
                    />
                </ReportFilterList>
                <SearchRow>
                    <SearchInput
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        onSearch={() => handleSearch()}
                        onCancel={() => {
                            setIsUsingSearch(false);
                            setSearchValue('');
                        }}
                    />
                    {customHead}
                </SearchRow>
            </AdditionalHeadRow>
            {tableInfo.filters && (
                <TableInfoWrapper>
                    <TableInfo {...tableInfo} />
                </TableInfoWrapper>
            )}

            {isLoading && loadingComponent}
            {Boolean(toxinProducts?.length) &&
                toxinProducts.map(toxinProduct => {
                    return (
                        <ToxinTableContainer key={toxinProduct.id}>
                            <ToxinTable
                                columns={columns}
                                rowList={isUsingSearch ? searchRows : rows}
                                reportList={rows}
                                toxinId={toxinProduct.id}
                                toxinName={toxinProduct.name}
                                isLoading={isLoading}
                                tableInfo={tableInfo}
                                onChangeToxinTableOrder={onChangeToxinTableOrder}
                            />
                        </ToxinTableContainer>
                    );
                })}
            {columns && !dataRows?.length && (
                <NoDataToDisplay style={{ height: 33 * emptyRows }}>No data to display</NoDataToDisplay>
            )}
            {showWarning && <WarningMessage leaveAction={leaveAction} setShowWarning={setShowWarning} />}
        </div>
    );
}

const AdditionalHeadRow = styled(MuiTableRow)`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    height: 125px;
    max-height: 125px;
`;

const SearchRow = styled.div`
    display: flex;
    width: 21.875rem;
`;

const TableInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -8px;
`;

const NoDataToDisplay = styled(MuiTableRow)`
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ToxinTableContainer = styled.div`
    margin-bottom: 30px;
`;

export default ToxinTableResults;
