const reportQueryObjectKeys = [
    'areas',
    'clinics',
    'staffs',
    'toxins',
    'daysSinceCateg',
    'serviceAndCategories',
    'paymentMethod'
];
export default reportQueryObjectKeys;
