import api from './api';

class ClinicApi {
    static async getClinics() {
        return api.request({
            method: 'GET',
            url: '/clinics'
        });
    }
}

export default ClinicApi;
