import React from 'react';
import { Icon, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getPath = file => {
    return `/icons/${file}`;
};

const useStyles = makeStyles({
    imageIcon: {
        height: '100%',
        width: '100%'
    },
    iconRoot: {
        textAlign: 'center',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        verticalAlign: 'center'
    },
    small: {
        height: '18px',
        width: '18px'
    },
    medium: {
        height: '30px',
        width: '30px'
    },
    large: {
        height: '35px',
        width: '35px'
    }
});

const IconBuilder = ({ path, classes, variant, opacity }) => {
    const useClasses = useStyles();

    if (!classes && variant) {
        switch (variant) {
            case 'small':
                classes = useClasses.small;
                break;
            case 'medium':
                classes = useClasses.medium;
                break;
            case 'large':
                classes = useClasses.large;
                break;
            default:
                break;
        }
    }
    if (!opacity) opacity = '1.0';
    return (
        <Icon className={classes ? classNames(classes, useClasses.iconRoot) : useClasses.iconRoot}>
            <img alt={path} className={useClasses.imageIcon} style={{ opacity }} src={getPath(path)} />
        </Icon>
    );
};

IconBuilder.propTypes = {
    path: PropTypes.string.isRequired,
    classes: PropTypes.object,
    variant: PropTypes.string,
    opacity: PropTypes.string
};

/*eslint-disable */
export const Search = ({ classes, variant, opacity }) => (
    <IconBuilder path="search.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Calendar = ({ classes, variant, opacity }) => (
    <IconBuilder path="calendar-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ChevronBackWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-back-white.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ChevronForwardWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-forward-white.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Help = ({ classes, variant, opacity }) => (
    <IconBuilder path="help.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Stock = ({ classes, variant, opacity }) => (
    <IconBuilder path="inventory.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const LockOpen = ({ classes, variant, opacity }) => (
    <IconBuilder path="lock-open.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const LogOut = ({ classes, variant, opacity }) => (
    <IconBuilder path="log-out.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Marketing = ({ classes, variant, opacity }) => (
    <IconBuilder path="marketing.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const People = ({ classes, variant, opacity }) => (
    <IconBuilder path="people.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const People10 = ({ classes, variant, opacity }) => (
    <IconBuilder path="people10.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Pos = ({ classes, variant, opacity }) => (
    <IconBuilder path="pos.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Reports = ({ classes, variant, opacity }) => (
    <IconBuilder path="reports.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Settings = ({ classes, variant, opacity }) => (
    <IconBuilder path="settings.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const CalendarWhite = ({ classes, variant, opacity }) => (
    <IconBuilder path="calendar-white.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const Practitioner = ({ classes, variant, opacity }) => (
    <IconBuilder path="practitioner.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const CloseOutline = ({ classes, variant, opacity }) => (
    <IconBuilder path="close-outline.svg" classes={classes} variant={variant} opacity={opacity} />
);

export const ChevronUp = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-up.svg" classes={classes} variant={variant} opacity={opacity} />
);
export const ChevronDown = ({ classes, variant, opacity }) => (
    <IconBuilder path="chevron-down.svg" classes={classes} variant={variant} opacity={opacity} />
);
