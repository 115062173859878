export const styles = () => ({
    reportMainTitle: {
        margin: '10px'
    },
    reportItemWrapper: {
        border: '1px solid #9d9d9d',
        boxShadow: '3px 3px 3px 0px rgb(207 207 207);',
        margin: '25px',
        position: 'relative',
        height: '378px',

        '& h3': {
            textAlign: 'center',
            margin: '10px 0'
        },
        '& h3:nth-child(2)': {
            fontSize: '10px'
        }
    },
    mousePointer: {
        cursor: 'pointer'
    },
    emptySorter: {
        width: '24px',
        height: '24px',
        marginTop: '-9px',
        display: 'inline-block'
    },
    boldRow: {
        '& th, & td': {
            fontWeight: 'bold'
        }
    }
});
