import React from 'react';
import config from '../../../../../config';
import { linkableCell } from '../../../../../style/reportStyles';
import { toLocaleString } from '../../../../../collums-components/helpers';
import moment from 'moment-timezone';

/**
 * hide - not visible in table
 * hideCSV - not visible in csv
 */
const columns = [
    {
        title: 'Date/Time',
        field: 'date',
        type: 'date',
        hideCSV: true,
        // eslint-disable-next-line
        render: data => <>{moment(data.date, 'YYYY/MM/DD HH:mm').format('DD/MM/YY HH:mm')}</>
    },
    {
        title: 'Date/Time',
        field: 'dateFormatted',
        type: 'date',
        hide: true
    },
    {
        title: 'Action',
        field: 'action',
        customTotalCalculation: content => {
            return content.length;
        }
    },
    // { title: 'Status', field: 'status' },
    { title: 'Actioned by', field: 'actionedByName' },
    {
        title: 'Client',
        field: 'customerName',
        type: 'unique',
        hideCSV: true,
        // eslint-disable-next-line
        render: data => {
            return (
                <span
                    style={linkableCell}
                    onClick={() => {
                        window.open(`${config.calendarUrl}/customer/${data.customerId}/general`, '_blank');
                    }}
                >
                    {data.customerName}
                </span>
            );
        }
    },
    {
        title: 'Online',
        field: 'bookedOnline',
        // eslint-disable-next-line
        render: data => {
            return data.bookedOnline ? 'Online' : '-';
        }
    },
    { title: 'First name', field: 'customerFirstName', hide: true },
    { title: 'Surname', field: 'customerSurname', hide: true },
    { title: 'Mobile', field: 'customerMobile' },
    {
        title: 'Email',
        field: 'customerEmail',
        // eslint-disable-next-line
        render: data => {
            return (
                <span
                    style={linkableCell}
                    onClick={() => {
                        window.open(`mailto:${data.customerEmail}`, '_self');
                    }}
                >
                    {data.customerEmail}
                </span>
            );
        }
    },
    {
        title: 'Appt Date',
        field: 'appointmentDate',
        type: 'date',
        render: data => <>{moment(data.appointmentDate).format('DD/MM/YY')}</>
    },
    { title: 'Appt Start', field: 'appointmentStartTime' },
    { title: 'Appt End', field: 'appointmentEndTime' },
    { title: 'Category', field: 'categoryName' },
    { title: 'Service', field: 'serviceName' },
    {
        title: 'Value',
        field: 'value',
        type: 'currency',
        // eslint-disable-next-line
        customTotalCalculation: (content, column) => {
            const addedAppointments = [];
            return toLocaleString(
                content.reduce((counter, row) => {
                    if (!row?.appointmentId || !row?.finalPrice) return counter;
                    if (!addedAppointments.includes(row.appointmentId)) {
                        addedAppointments.push(row.appointmentId);

                        const newValue = row.finalPrice || 0;
                        return counter + newValue;
                    }

                    return counter;
                }, 0)
            );
        },
        // eslint-disable-next-line
        render: data => {
            return (
                <span style={{ width: '100%', display: 'block', textAlign: 'right' }}>
                    {toLocaleString(data.price || 0)}
                </span>
            );
        }
    },
    { title: 'Performed by', field: 'performedByName' },
    { title: 'Location', field: 'clinicName' }
];

export default columns;
