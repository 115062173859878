import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import { linkableCell } from '../../../../../style/reportStyles';
import config from '../../../../../config';
import { INVOICE_PAYMENT_TYPES } from '../../../../../collums-constants';
// import { INVOICE_PAYMENT_STATUS } from '../../../../../collums-constants';

function Status({ values, query, filterBtn, setInvoice, loading, setValues }) {
    const filters = query;
    const columns = [
        { title: 'Date/Time', field: 'createdAt' },
        {
            title: 'Invoice',
            field: 'invoiceCode',
            type: 'unique',
            // eslint-disable-next-line react/display-name
            render: data =>
                data.invoiceId && (
                    <span
                        style={linkableCell}
                        onClick={evt => {
                            evt.stopPropagation();
                            setInvoice({ id: data.invoiceId });
                        }}
                    >
                        {data.invoiceCode}
                    </span>
                )
        },
        {
            title: 'Client',
            field: 'customerName',
            type: 'unique',
            // eslint-disable-next-line
            render: data => {
                return (
                    <span
                        style={linkableCell}
                        onClick={() => {
                            window.open(`${config.calendarUrl}/customer/${data.customerId}/general`, '_blank');
                        }}
                    >
                        {data.customerName}
                    </span>
                );
            }
        },
        {
            title: 'Taken by',
            field: 'takenBy',
            render: data => data.takenBy
        },
        {
            title: 'Payment Method',
            field: 'method',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'method'),
            render: data => {
                return (data.method === INVOICE_PAYMENT_TYPES.CREDIT_CARD_TERMINAL ||
                    data.method === INVOICE_PAYMENT_TYPES.CREDIT_CARD) &&
                    data?.cardBrand
                    ? `${data.method} - ${data.cardBrand}`
                    : data.method;
            }
        },
        {
            title: 'Amount',
            field: 'amount',
            type: 'currency',
            render(data) {
                return <span style={data.amount < 0 ? { color: 'red' } : {}}>{toLocaleString(data.amount)}</span>;
            }
        }
    ];

    return (
        <DefaultMaterialTable
            title={'Payment Log'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            showTotalRow={true}
        />
    );
}

export default Status;
