import * as actions from '../actions/types';

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SCHEDULED_REPORTS_FETCHED:
            return {
                ...state,
                schedule: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
