import _ from 'lodash';
import { getCurrencySymbol } from '../../collums-components/helpers';

import categories from '../../constants/reportCategories';
import reports from '../../constants/reports';

export const isValidCategory = id => !!_.findKey(categories, { id });
export const isValidReport = (categoryId, reportId) => {
    if (!_.findKey(categories, { id: categoryId })) return false;
    const categoryGroups = reports[_.findKey(categories, { id: categoryId })];
    if (!_.findKey(categoryGroups, { id: reportId })) return false;
    return true;
};

export const getCategoryObj = id => categories[_.findKey(categories, { id })];
export const getReportObj = (categoryId, reportId) => {
    const categoryGroups = reports[_.findKey(categories, { id: categoryId })];
    return categoryGroups[_.findKey(categoryGroups, { id: reportId })];
};

export const getReportUrl = (categoryId, reportId, filters) => {
    if (filters) {
        const queryFilters = Object.keys(filters)
            .filter(key => key !== 'group')
            .map(key => `${key}=${filters[key]}&`)
            .join('')
            .slice(0, -1);
        return `/reports/category/${categoryId}/report/${reportId}/group/${filters.group || 'items'}/result${
            queryFilters !== '' ? `?${queryFilters}` : ''
        }`;
    } else {
        return `/reports/category/${categoryId}/report/${reportId}`;
    }
};

export const isFavourite = ({ favourites, categoryKey, reportKey }) =>
    favourites.find(
        el =>
            !!categoryKey &&
            !!reportKey &&
            !!reports[categoryKey] &&
            !!reports[categoryKey][reportKey] &&
            el.reportId === reports[categoryKey][reportKey].id
    );

export const isScheduled = ({ scheduled, categoryKey, reportKey }) =>
    scheduled.find(
        el =>
            !!categoryKey &&
            !!reportKey &&
            !!reports[categoryKey] &&
            !!reports[categoryKey][reportKey] &&
            el.reportId === reports[categoryKey][reportKey].id
    );

/**
 *
 * @param {Array} table
 */
export const materialTableCurrencySorter = (a, b, direction, sortBy) => {
    const value = a[sortBy].toString().replace(getCurrencySymbol(), '').replace(' ', '').replace(',', '');
    const otherValue = b[sortBy].toString().replace(getCurrencySymbol(), '').replace(' ', '').replace(',', '');
    if (direction === 'asc') {
        return parseFloat(value) - parseFloat(otherValue);
    } else {
        return parseFloat(otherValue) - parseFloat(value);
    }
};

export const isNegative = valueAsLocaleString => {
    return (valueAsLocaleString || '')[0] === '-';
};
