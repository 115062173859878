import ToxinUsageSummary from './toxin-usage-summary';
import ToxinUsageByArea from './toxin-usage-by-area';
import ToxinUsageByStaff from './toxin-usage-by-staff';
import ToxinUsageLog from './toxin-usage-log';

export default {
    ToxinUsageSummary,
    ToxinUsageByArea,
    ToxinUsageByStaff,
    ToxinUsageLog
};
