const transformQueryObjects = query => {
    if (query.daysSinceCateg) {
        return (query.daysSinceCateg = JSON.stringify(query.daysSinceCateg));
    } else {
        if (Object.keys(query).includes('daysSinceCateg')) {
            delete query.daysSinceCateg;
        }
    }
    if (query.paymentMethod) {
        query.paymentMethod = (query.paymentMethod || []).join('|');
    }
};

export default transformQueryObjects;
