import React, { useMemo } from 'react';
import _ from 'lodash';
import MaterialTable from 'material-table';
import TableInfo from '../../../../common/CollumsTable/components/TableInfo';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import { toLocaleString } from '../../../../../collums-components/helpers';

const days = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday'
};

function Day({ values, query }) {
    const filters = useMemo(() => _.keys(query).map(key => `${key}: ${query[key]}`), [query]);
    const table = useMemo(() => {
        return {
            columns: [
                {
                    title: 'Day',
                    field: 'day',
                    customSort: (a, b) =>
                        Object.keys(days).find(key => days[key] === a.day) -
                        Object.keys(days).find(key => days[key] === b.day)
                },
                { title: '#', field: 'quantity' },
                {
                    title: 'refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'aveNetSales',
                    field: 'aveNetSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'aveNetSales')
                },
                {
                    title: 'Discounts',
                    field: 'discount',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'discount')
                },
                {
                    title: 'Refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'Net Sales',
                    field: 'netSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netSales')
                },
                {
                    title: 'Tax',
                    field: 'tax',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'tax')
                },
                {
                    title: 'Total Sales',
                    field: 'totalSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalSales')
                }
            ],
            data: values.map(value => {
                const { day, quantity, totalSales, listPrice, discount, refunds, tax, aveNetSales, netSales } = value;

                return {
                    day,
                    quantity,
                    listPrice: toLocaleString(listPrice),
                    discount: toLocaleString(discount || 0),
                    refunds: toLocaleString(refunds || 0),
                    netSales: toLocaleString(netSales || 0),
                    aveNetSales: toLocaleString(aveNetSales || 0),
                    tax: toLocaleString(tax || 0),
                    totalSales: toLocaleString(totalSales || 0)
                };
            })
        };
    }, [values]);
    return (
        <>
            <MaterialTable
                title={<TableInfo type="Day" filters={filters} />}
                columns={table.columns}
                data={table.data}
                options={{ sorting: true }}
            />
        </>
    );
}

export default Day;
