import reports from '../../constants/reports';
import flattenDeep from 'lodash/flattenDeep';

const getMissingFilters = (report, params) => {
    const reportList = flattenDeep(Object.values(reports).map(reportSection => Object.values(reportSection)));

    const reportProps = reportList.find(reportItem => reportItem.id === report);

    if (!reportProps || !reportProps.requiredFilters?.length) return [];

    if (!params) return reportProps.requiredFilters;

    const isNumericInputInvalid = value => {
        return isNaN(value) || value === '';
    };

    return reportProps.requiredFilters.filter(requiredItem => {
        if (requiredItem === 'daysSinceCateg') {
            const daysSinceCateg = params[requiredItem];
            if (!daysSinceCateg?.type) return false;
            if (daysSinceCateg.type === 'Between') {
                return isNumericInputInvalid(daysSinceCateg.from) || isNumericInputInvalid(daysSinceCateg.to);
            }
            return isNumericInputInvalid(daysSinceCateg.value);
        }

        if (['clinics'].includes(requiredItem)) {
            return !params[requiredItem]?.length;
        }
        return !params[requiredItem];
    });
};

export default getMissingFilters;
