import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import CloseBtn from './CloseBtn';
import { withStyles, Modal, Button } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { getDateNoTimezone } from '../../collums-components/helpers/timezone';
import { toastr } from 'react-redux-toastr';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-GB/index';

import { modal } from './styles';

import invoiceApi from '../../api/invoiceApi';

function EditGiftcardExpiryModal({ giftcard, setGiftcard, classes, refreshReport }) {
    const [expiryDate, setNewExpiryDate] = useState(null);

    const save = async () => {
        if (!expiryDate) return;

        const data = {
            expDate: expiryDate
        };

        const res = await invoiceApi.updateGiftcardExpiry(giftcard.id, data);
        if (res.status !== 200) {
            toastr.error(`There was an issue updating gift voucher expiry`);
            return;
        }

        refreshReport();
        toastr.success(`Gift voucher expiry updated`);
        closeModal();
    };

    const closeModal = () => {
        setGiftcard(null);
        setNewExpiryDate(null);
    };

    useEffect(() => {
        if (!giftcard) return;

        setNewExpiryDate(Moment.parseZone(giftcard.expDate, 'DD/MM/YYYY'));
    }, [giftcard]);

    return (
        giftcard &&
        <Modal open={giftcard} className={classes.modal}>
            <div className={classes.root}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={closeModal} />
                </div>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <h3>{`Gift Voucher ${giftcard.voucherNo}`}</h3>
                    </div>
                    <div>
                      <p>{`Purchased by ${giftcard.client === '' ? '-' : giftcard.client} on ${giftcard.createdAt}`}</p>
                      <br/>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                            <DatePicker
                                variant={'outlined'}
                                autoOk
                                className={`${classes.font} ${classes.hiddenCalendarIcon}`}
                                keyboard
                                name="expiryDate"
                                label="Expiry date"
                                format={'dd/MM/yyyy'}
                                required
                                keyboardIcon={null}
                                mask={value =>
                                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                                }
                                value={ expiryDate }
                                onInputChange={value => {
                                    if (value.target.value === '')
                                        setNewExpiryDate(null);
                                }}
                                onChange={value => {
                                    const date = Moment.utc(getDateNoTimezone(value));
                                    setNewExpiryDate(date);
                                }}
                                disableOpenOnEnter
                                animateYearScrolling={false}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={classes.actions}>
                        <Button onClick={closeModal} variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={save}>Save</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

EditGiftcardExpiryModal.propTypes = {
    classes: PropTypes.object.isRequired,
    giftcard: PropTypes.object.isRequired,
    setGiftcard: PropTypes.object.isRequired,
    refreshReport: PropTypes.func.isRequired
};

export default withStyles(modal)(EditGiftcardExpiryModal);
