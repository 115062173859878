import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { filters as filtersStyles } from './styles';
import { startOfDay, endOfDay } from 'date-fns';
import DateRangeFilter from './DateRangeFilter';
import DateInput from './DateInput';

function DateFilterInputs({ classes, handleFilterChange, fromValue, toValue, minDate, disabled, simple = false }) {
    const [from, setFrom] = useState(startOfDay(new Date()));
    const [to, setTo] = useState(endOfDay(new Date()));
    const [anchor, setAnchor] = useState(null);

    useEffect(() => {
        if (toValue) {
            setTo(endOfDay(new Date(toValue)));
        }
        if (fromValue) {
            setFrom(startOfDay(new Date(fromValue)));
        }
    }, [toValue, fromValue, simple]);

    useEffect(() => {
        if (!toValue && !fromValue) {
            let toValue = to;
            let fromValue = from;
            if (simple) {
                fromValue = new Date(from.getTime() - from.getTimezoneOffset() * 60000);
                fromValue.setUTCHours(0, 0, 0, 0);
            }
            handleFilterChange({
                to: toValue.toISOString(),
                from: fromValue.toISOString(),
                period: 'today'
            });
        }
        // eslint-disable-next-line
    }, []);

    const onClickDateField = e => {
        e.stopPropagation();
        e.preventDefault();
        if (disabled) return;
        setAnchor(e.target);
    };

    return (
        <>
            <div className={classes.alignedContainer}>
                <DateInput
                    isFirst
                    id="start-date-input"
                    label="From"
                    targetDate={from}
                    onClickDateField={onClickDateField}
                    disabled={disabled}
                />
                <DateInput
                    id="end-date-input"
                    label="To"
                    targetDate={to}
                    onClickDateField={onClickDateField}
                    disabled={disabled}
                />
            </div>
            <DateRangeFilter
                handleFilterChange={handleFilterChange}
                toValue={toValue}
                fromValue={fromValue}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                minDate={minDate}
                disabled={disabled}
            />
        </>
    );
}

export default withStyles(filtersStyles)(DateFilterInputs);
