import React from 'react';
import CategoriesFilter from './CategoriesFilter';
import ServicesFilter from './ServicesFilter';
import ClientsFilter from './ClientsFilter';
import ItemsFilter from './ItemsFilter';
import StaffsFilter from './StaffsFilter';
import TypesFilter from './TypesFilter';
import ProductsFilter from './ProductsFilter';
import TagsFilter from './TagsFilter';
import ChannelsFilter from './ChannelsFilter';
import LocationFilter from './LocationFilter';
import StatusFilter from './StatusFilter';
import IncludeZeroSalesFilter from './IncludeZeroSalesFilter';
import CoursesFilter from './CoursesFilter';
import VatFilter from './VatFilter';
import ActionFilter from './ActionFilter';

//
function FilterSelector({ group, ...props }) {
    switch (group) {
        case 'categories':
            return <CategoriesFilter {...props} />;
        case 'services':
            return <ServicesFilter {...props} />;
        case 'clients':
            return <ClientsFilter {...props} />;
        case 'products':
            return <ProductsFilter {...props} />;
        case 'types':
            return <TypesFilter {...props} />;
        case 'staffs':
            return <StaffsFilter {...props} />;
        case 'items':
            return <ItemsFilter {...props} />;
        case 'tags':
            return <TagsFilter {...props} />;
        case 'channels':
            return <ChannelsFilter {...props} />;
        case 'location':
            return <LocationFilter {...props} />;
        case 'status':
            return <StatusFilter {...props} />;
        case 'zero-sales':
            return <IncludeZeroSalesFilter {...props} />;
        case 'courses':
            return <CoursesFilter {...props} />;
        case 'vat':
            return <VatFilter {...props} />;
        case 'action':
            return <ActionFilter {...props} />;
        default:
            return <div />;
    }
}

export default FilterSelector;
