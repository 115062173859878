import React from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import columns from './columns';
import { Typography } from '@material-ui/core';

function Result({ values, query, filterBtn, loading, setValues }) {
    return (
        <DefaultMaterialTable
            title={'Top Spender'}
            filters={query}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            canShowFilters
            showFiltersOnHeader
            targetComponentProps={{
                customHeadRow: (
                    <Typography style={{ marginTop: 5 }}>
                        A list of your top spenders based on their payments. Account and gift voucher payments are NOT
                        included. This report is updated every day
                    </Typography>
                )
            }}
        />
    );
}

export default Result;
