const getDaysSinceCategSaleLabel = daysSinceCategSale => {
    if (!daysSinceCategSale?.type) {
        return '';
    }
    if (daysSinceCategSale.type === 'Between') {
        return `${daysSinceCategSale.type} ${daysSinceCategSale.from} and ${daysSinceCategSale.to} days`;
    }
    return `${daysSinceCategSale.type} ${daysSinceCategSale.value} days`;
};

export default getDaysSinceCategSaleLabel;
