import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { INVOICE_PAYMENT_STATUS } from '../../../collums-constants';
import { filters } from './styles';

const items = [
    { label: 'Paid', value: INVOICE_PAYMENT_STATUS.PAID },
    { label: 'Part Paid', value: INVOICE_PAYMENT_STATUS.PART_PAID },
    { label: 'Refund', value: INVOICE_PAYMENT_STATUS.REFUND },
    { label: 'Unpaid', value: INVOICE_PAYMENT_STATUS.UNPAID },
    { label: 'Voided', value: INVOICE_PAYMENT_STATUS.VOID }
];

function StatusFilter({ classes, label, handleFilterChange, filters, report }) {
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (items.length > 0) {
            const value = filters['paymentStatus'];
            if (value) {
                const splitted_values = value.split(',');
                setSelectedItems(splitted_values.map(sv => items.find(s => s.value === sv)));
            }
        }
        //eslint-disable-next-line
    }, [filters]);

    const handleChange = (_, items) => {
        const _items = items.map(i => i.value).join(',');
        handleFilterChange({
            paymentStatus: _items
        });
        setSelectedItems(items);
    };

    const getOptions = () => {
        switch (report) {
            case 'invoice-log':
                return items.filter(el => el.label !== 'Voided');
            default:
                return items;
        }
    };

    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={classes.autocomplete}
                options={getOptions()}
                value={selectedItems}
                onChange={handleChange}
                label={label}
                getOptionLabel={option => option.label}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label.charAt(0).toUpperCase() + label.slice(1)}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{`${option.label}`}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(StatusFilter);
