import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableFooter from '@material-ui/core/TableFooter';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { currencySorter, defaultSorter } from '../../../../common/CollumsTable/helpers/sorting';
import { scrollAnimation } from '../../../../../utils/helpers/common';

function ToxinTable({ columns, rowList, reportList, toxinId, tableInfo, toxinName, onChangeToxinTableOrder }) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const filteredRows = rowList.filter(row => row.toxin === toxinId);
        setRows(filteredRows);
    }, [toxinId, rowList]);

    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState('desc');

    const previousFilters = useRef(tableInfo?.filters || {});

    const isRightAligned = useCallback(item => {
        const rightAligned = ['currency', 'percentage', 'quantity', 'hoursAndMinutes', 'number'];
        return rightAligned.includes(item);
    }, []);

    useEffect(() => {
        scrollAnimation();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        previousFilters.current = tableInfo.filters || {};
    }, [tableInfo]);

    const handleSort = (id, type) => {
        let orderedRows = reportList.filter(el => el.toxin === toxinId);
        switch (type) {
            case 'currency':
            case 'quantity':
                orderedRows = orderedRows.sort((a, b) => {
                    const objA = { ...a };
                    const objB = { ...b };
                    return currencySorter(objA, objB, direction, id);
                });
                break;
            default:
                orderedRows = orderedRows.sort((a, b) => {
                    const objA = { ...a };
                    const objB = { ...b };
                    return defaultSorter(objA, objB, direction, id);
                });
                break;
        }

        const newRowsOrder = orderedRows
            .map(el => {
                return reportList.find(row => row.id === el.id);
            })
            .filter(el => el);

        setDirection(direction === 'asc' ? 'desc' : 'asc');
        setOrderBy(id);

        onChangeToxinTableOrder(newRowsOrder);
    };

    const dataRows = rows;

    const generateRows = useMemo(() => {
        const displayedRows = dataRows || [];
        const _rows = displayedRows.map((row, i) => {
            const cells = columns
                .filter(el => !el.hide)
                .map((col, j) => {
                    let value = row[col.field];

                    if (col.Component) {
                        value = col.Component(row);
                    }

                    if (col.render) {
                        value = col.render(row);
                    }

                    if (col.type === 'group') {
                        return col.renders.map((render, index) => {
                            value = render(row);
                            const alignment = (() => {
                                if (col.subColumns.length === 1) {
                                    if (isRightAligned(col.subColumns[index].type)) {
                                        return 'right';
                                    } else {
                                        return 'left';
                                    }
                                } else if (index === 1) {
                                    return 'right';
                                } else if (isRightAligned(col.subColumns[index].type)) {
                                    return 'right';
                                } else {
                                    return 'left';
                                }
                            })();
                            return (
                                <TableCellBody
                                    key={`col_${j}_${col.field}_${index}`}
                                    align={alignment}
                                    style={(() => {
                                        if (index === 0) {
                                            return {
                                                borderLeft: '1px solid rgba(224, 224, 224, 1)'
                                            };
                                        }
                                        if (index === col.subColumns.length - 1) {
                                            return {
                                                borderRight: '1px solid rgba(224, 224, 224, 1)'
                                            };
                                        }
                                        return {};
                                    })()}
                                >
                                    {value}
                                </TableCellBody>
                            );
                        });
                    } else {
                        return (
                            <TableCellBody
                                key={`col_${j}_${col.field}`}
                                align={col.type === 'currency' || col.type === 'percentage' ? 'right' : 'left'}
                            >
                                {value}
                            </TableCellBody>
                        );
                    }
                });

            return <TableRow key={`row_${i}`}>{cells}</TableRow>;
        });
        return _rows;
        //eslint-disable-next-line
    }, [rows, orderBy]);

    const ref = useRef();

    const haveGroup = useMemo(() => columns && columns.some(el => el.type === 'group'), [columns]);

    return (
        <div ref={ref}>
            <TableContainer>
                <Table aria-label="collums table" id="collums-table" size="large">
                    <MuiTableHead>
                        <TableRow>
                            {columns &&
                                columns.map(col => {
                                    const isName = col.field === 'name';
                                    return col.type === 'group' ? (
                                        <MuiTableCell
                                            colSpan={col.subColumns.length}
                                            style={{
                                                borderBottom: 0,
                                                paddingBottom: 0,
                                                paddingTop: 0,
                                                border: '1px solid rgb(224, 224, 224)'
                                            }}
                                            key={col.field}
                                            align="center"
                                        >
                                            <Typography style={{ fontSize: 14, fontWeight: '500' }}>
                                                {col.title}
                                            </Typography>
                                        </MuiTableCell>
                                    ) : (
                                        <MuiTableCell
                                            style={haveGroup ? { borderBottom: 0, paddingBottom: 0 } : {}}
                                            key={col.field}
                                            align={
                                                col.type === 'currency' || col.type === 'percentage' ? 'right' : 'left'
                                            }
                                        >
                                            {!haveGroup && (
                                                <TableSortLabel
                                                    active={orderBy === col.field}
                                                    direction={orderBy === col.field ? direction : 'asc'}
                                                    onClick={() => handleSort(col.field, col.type)}
                                                    hideSortIcon={orderBy !== col.field}
                                                >
                                                    <Typography style={{ fontSize: 14, fontWeight: '500' }}>
                                                        {isName ? toxinName : col.title}
                                                    </Typography>
                                                </TableSortLabel>
                                            )}
                                        </MuiTableCell>
                                    );
                                })}
                        </TableRow>
                        {haveGroup && (
                            <TableRow>
                                {columns.map(col => {
                                    if (col.type !== 'group') {
                                        const isName = col.field === 'name';
                                        return (
                                            <MuiTableCell
                                                style={{ paddingTop: 0, borderTop: '1px solid rgb(224, 224, 224)' }}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === col.field}
                                                    direction={orderBy === col.field ? direction : 'asc'}
                                                    onClick={() => handleSort(col.field, col.type)}
                                                    hideSortIcon={orderBy !== col.field}
                                                >
                                                    <Typography style={{ fontSize: 14, fontWeight: '500' }}>
                                                        {isName ? toxinName : col.title}
                                                    </Typography>
                                                </TableSortLabel>
                                            </MuiTableCell>
                                        );
                                    }
                                    return col.subColumns.map((subCol, index) => {
                                        const alignment = (() => {
                                            if (col.subColumns.length === 1) {
                                                return isRightAligned(subCol.type) ? 'right' : 'left';
                                            } else if (isRightAligned(subCol.type)) {
                                                return 'right';
                                            } else {
                                                return 'left';
                                            }
                                        })();
                                        return (
                                            <MuiTableCell
                                                style={(() => {
                                                    if (index === 0) {
                                                        return {
                                                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                            paddingTop: 0
                                                        };
                                                    }
                                                    if (index === col.subColumns.length - 1) {
                                                        return {
                                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                            paddingTop: 0
                                                        };
                                                    }
                                                    return { paddingTop: 0 };
                                                })()}
                                                key={subCol.field}
                                                align={alignment}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === subCol.field}
                                                    direction={orderBy === subCol.field ? direction : 'asc'}
                                                    onClick={() => handleSort(subCol.field, subCol.type)}
                                                    hideSortIcon={orderBy !== subCol.field}
                                                >
                                                    <Typography style={{ fontSize: 14, fontWeight: '500' }}>
                                                        {subCol.title}
                                                    </Typography>
                                                </TableSortLabel>
                                            </MuiTableCell>
                                        );
                                    });
                                })}
                            </TableRow>
                        )}
                    </MuiTableHead>
                    <TableBody>{generateRows}</TableBody>
                    <MuiTableFooter></MuiTableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}

const Table = styled(MuiTable)`
    position: relative;
    min-height: 100px;
    height: 100px;
`;

const TableBody = styled(MuiTableBody)`
    width: 100%;
    min-width: 650px;
`;
const TableRow = styled(MuiTableRow)`
    width: 100%;
    height: 53px;
    max-height: 53px;
`;
const TableCellBody = styled(MuiTableCell)`
    font-size: 14px;
    font-weight: 400;
`;

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

export default ToxinTable;
