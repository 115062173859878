//COMMON

//DASHBOARD

//REPORTS
export const SET_REPORTS_SEARCH_BAR_FILTER = 'SET_REPORTS_SEARCH_BAR_FILTER';
export const SET_ACCORDIONS_STATE = 'SET_ACCORDIONS_STATE';
export const SET_FILTERS_STATE = 'SET_FILTERS_STATE';
export const CLEAR_FILTERS_STATE = 'CLEAR_FILTERS_STATE';

//SCHEDULED_REPORTS
export const SCHEDULED_REPORTS_FETCHED = 'SCHEDULED_REPORTS_FETCHED';
