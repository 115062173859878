import React from 'react';
import { Box } from '@material-ui/core';
import Breadcrumb from './Breadcrumb';

function TableWrapper({ children, showBreadcrumb = false }) {
    return (
        <Box m={3} mb={10} mt={0}>
            {showBreadcrumb && <Breadcrumb />}
            <Box m={2} />
            {/* {Buttons()} */}
            <Box m={2} />
            <Box m={2} />
            <div>{children}</div>
        </Box>
    );
}

export default TableWrapper;
