import React from 'react';

import { Select, MenuItem, makeStyles } from '@material-ui/core';

const optionsOfRowsToShow = ['all', 10, 20];

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > p': {
            margin: 0.5
        }
    },
    selectRoot: {
        backgroundColor: 'white'
    },
    selectInputProps: {
        fontSize: 14,
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'space-between',
        alignItems: 'center'
    },
    menuProps: {
        '&&& $li': {
            fontSize: 14
        }
    }
});

function SelectAmountOfRows({ rowsToShow, setRowsToShow }) {
    const classes = makeStyles(styles)();

    return (
        <div className={classes.container}>
            <p>Amount of rows to show</p>
            <Select
                label="Amount of rows to show"
                variant="outlined"
                value={rowsToShow}
                className={classes.selectRoot}
                onChange={e => setRowsToShow(e.target.value)}
                inputProps={{
                    className: classes.selectInputProps
                }}
                MenuProps={{
                    className: classes.menuProps
                }}
            >
                {optionsOfRowsToShow.map(option => {
                    return (
                        <MenuItem key={`${option}-option`} value={option}>
                            {option}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
}

export default SelectAmountOfRows;
