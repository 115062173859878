import React, { useMemo, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { InvoiceModalStyles } from './styles';
import PropTypes from 'prop-types';
import CoherentApi from '../../../api/CoherentApi';
import { toastr } from 'react-redux-toastr';

const PaymentLinkDialog = ({ classes, setPaymentLinkModel, amount, invoiceId }) => {
    const [options, setOptions] = React.useState({ email: false, sms: false });
    const [outstandingAmount, setOutstandingAmount] = useState(amount);
    const [isProcessing, setIsProcessing] = useState(false);

    const changeHandler = (e, value) => {
        e.persist();
        setOptions(prev => ({ ...prev, [e.target.name]: value }));
    };

    const hasChosenOption = useMemo(() => {
        return Object.values(options).some(option => option);
    }, [options]);

    const sendForm = async () => {
        setIsProcessing(true);
        const result = await CoherentApi.createPaymentLink({
            invoiceId,
            amount: outstandingAmount,
            email: options.email,
            sms: options.sms
        });
        if (result && result?.transactionUuid) {
            toastr.success('Payment link has sent to customer.');
            setPaymentLinkModel(false);
        }
        setIsProcessing(false);
    };

    return (
        <Dialog
            open={true}
            className={classes.dialog}
            onBackdropClick={() => setPaymentLinkModel(false)}
            onEscapeKeyDown={() => setPaymentLinkModel(false)}
        >
            <Box minWidth={500} minHeight={300}>
                <DialogTitle>
                    <Typography align="center">
                        <b className={`${classes.fontSize18px} ${classes.title}`}>Send Payment Link</b>
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid item className={classes.paymentLinkAmountWrapper}>
                            <TextField
                                variant="outlined"
                                required
                                id="outstandingAmount"
                                label="Outstanding amount"
                                name="outstandingAmount"
                                value={outstandingAmount}
                                onChange={e => setOutstandingAmount(e.target.value)}
                                className={classes.font}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item container className={classes.paymentLinkOptionWrapper}>
                            <Grid item>
                                <div className={classes.formContainer}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="email"
                                                name="email"
                                                color="primary"
                                                onChange={(e, value) => changeHandler(e, value)}
                                            />
                                        }
                                        className={`${classes.label}`}
                                        label="Email"
                                    />
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={classes.formContainer}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="sms"
                                                name="sms"
                                                color="primary"
                                                onChange={(e, value) => changeHandler(e, value)}
                                            />
                                        }
                                        className={`${classes.label}`}
                                        label="SMS"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <p className={classes.paymentLinkMessage}>
                        Ensure that notifications are switched on to know when this has been paid.
                    </p>
                    <div className={classes.paymentLinkButtons} style={{ paddingBottom: 10 }}>
                        <Button
                            onClick={() => setPaymentLinkModel(false)}
                            variant="outlined"
                            style={{ borderRadius: 0 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => sendForm()}
                            color="primary"
                            className={classes.button}
                            disabled={!hasChosenOption || isProcessing}
                        >
                            Send payment link
                        </Button>
                    </div>
                </DialogContent>
            </Box>
            <IconButton className={classes.closeButton} onClick={() => setPaymentLinkModel(false)}>
                <CloseIcon />
            </IconButton>
        </Dialog>
    );
};

PaymentLinkDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    amount: PropTypes.string.isRequired,
    setPaymentLinkModel: PropTypes.func.isRequired,
    invoiceId: PropTypes.string.isRequired
};

export default withStyles(InvoiceModalStyles)(PaymentLinkDialog);
