import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useClinics } from '../../../collums-components/hooks/clinics';
import { filters } from './styles';
import getMissingFilters from '../../../utils/helpers/getMissingFilters';

function SingleLocationFilter({ classes, label, handleFilterChange, filters, report }) {
    const { clinics } = useClinics();
    const [selectedItem, setSelectedItem] = useState(null);
    const [isFieldRequired, setIsFieldRequired] = useState(false);

    const handleChange = (_, item) => {
        handleFilterChange({
            clinic: item?.accountName,
            clinicId: item?.id
        });
        setSelectedItem(item);
    };

    useEffect(() => {
        if (clinics.length === 1) {
            handleChange(null, clinics[0]);
        }

        // eslint-disable-next-line
    }, [clinics]);

    useEffect(() => {
        setIsFieldRequired(getMissingFilters(report, {}).includes('clinic'));
    }, [report]);

    useEffect(() => {
        if (clinics.length > 0) {
            const value = filters['clinic'];
            if (value) {
                setSelectedItem(clinics.find(item => item.accountName === value));
            } else {
                setSelectedItem(null);
            }
        }
        // eslint-disable-next-line
    }, [filters]);

    return (
        <Box marginTop={4}>
            <Autocomplete
                className={`${classes.autocomplete} ${classes.filterMaxWidth}`}
                options={clinics}
                value={selectedItem}
                onChange={handleChange}
                loading={!clinics.length}
                label={label}
                required={isFieldRequired}
                getOptionLabel={option => option.accountName}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        error={!selectedItem && isFieldRequired}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{`${option.accountName}`}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(SingleLocationFilter);
