import { Link } from '@material-ui/core';
import React from 'react';
import { toLocaleString } from '../../../../../../collums-components/helpers';
import { materialTableCurrencySorter } from '../../../../../../utils/helpers/reports';
import apps from '../../../../../../config';

const getColumns = () => {
    return [
        {
            title: 'Client',
            field: 'client',
            type: 'rows',
            //eslint-disable-next-line
            render: data => (
                <Link
                    onClick={() => {
                        window.open(`${apps.calendarUrl}/customer/${data._id}/general`);
                    }}
                    style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
                >
                    {data.client}
                </Link>
            )
        },
        {
            title: 'Current Balance',
            field: 'balance',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'balance'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => <span style={data.balance < 0 ? { color: 'red' } : {}}>{toLocaleString(data.balance)}</span>
        }
    ];
};

export default getColumns;
