import React, { useState, useEffect } from 'react';
import { Box, withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { filters } from './styles';

function FutureAppointmentsCheckboxFilter({ handleFilterChange, filters }) {
    const [includeRedemptions, setIncludeRedemptions] = useState(false);

    useEffect(() => {
        const filter = {
            ...filters,
            includeRedemptions
        };

        handleFilterChange(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRedemptions = () => {
        const filter = {
            ...filters,
            includeRedemptions: !includeRedemptions
        };

        handleFilterChange(filter);

        setIncludeRedemptions(redemption => !redemption);
    };

    return (
        <Box marginTop={4}>
            <FormControlLabel control={<Checkbox onChange={handleRedemptions} />} label="Include Course Redemptions" />
        </Box>
    );
}

export default withStyles(filters)(FutureAppointmentsCheckboxFilter);
