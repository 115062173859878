import React from 'react';
import { Box } from '@material-ui/core';
import Breadcrumb from '../common/Breadcrumb';
import List from './List';
import ReportsContainer from './ReportsContainer';

function Reports(props) {
    const pathName = props?.location?.pathname;
    const isShowingContainer = pathName !== '/reports';

    return (
        <>
            <Box m={3} mt={1.5} mb={0}>
                <Breadcrumb />
                <Box mt={0}></Box>
                <List />
            </Box>
            {isShowingContainer && <ReportsContainer />}
        </>
    );
}

export default Reports;
