import React, { useLayoutEffect, useState } from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import defaultColumns from './defaultColumns';
import { BOTOX_TREATMENT_AREAS } from '../../../../../collums-constants';
import generateAreaColumn from './generateAreaColumn';

function Result({ values, query, filterBtn, loading, setValues }) {
    const filters = query;
    const [columns, setColumns] = useState([...defaultColumns, generateAreaColumn('Total')]);

    useLayoutEffect(() => {
        const keyItems = Object.keys(values[0] || {});

        const appearingAreas = Object.values(BOTOX_TREATMENT_AREAS).filter(area => {
            return keyItems.includes(area.toLowerCase());
        });

        const newColumns = [...defaultColumns, ...appearingAreas.map(generateAreaColumn), generateAreaColumn('Total')];

        setColumns(newColumns);
    }, [values]);

    return (
        <DefaultMaterialTable
            title={'Toxin usage log'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            canShowFilters
            showFiltersOnHeader
        />
    );
}

export default Result;
