import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Select, MenuItem, withStyles, Grid } from '@material-ui/core';
import { filters } from './styles';
import { getPeriodInDate } from '../helpers/period';
import { periodFilter, futurePeriodFilter } from '../helpers/periodFilter';
import { getOlderInvoiceDate } from '../../../api/invoiceApi';

function DatePeriodsFilter({
    classes,
    handleFilterChange,
    periodValue,
    futureDatesOnly = false,
    customFilters,
    simple = false
}) {
    const [period, setPeriod] = useState(periodValue);

    useEffect(() => {
        setPeriod(periodValue);
    }, [periodValue]);

    useEffect(() => {
        if ('custom' === period) return;
        const { from, to, willGetOlderInvoice } = getPeriodInDate(period);

        if (willGetOlderInvoice) {
            getOlderInvoiceDate().then(res => {
                handleFilterChange({
                    to: moment(to).format('YYYY-MM-DD'),
                    from: moment(res.date).startOf('day').format('YYYY-MM-DD'),
                    period
                });
            });
        } else if (to && from) {
            if (simple) {
                handleFilterChange({
                    to: to,
                    from: from,
                    period
                });
            } else {
                handleFilterChange({
                    to: moment(to).endOf('day').format('YYYY-MM-DD'),
                    from: moment(from).startOf('day').format('YYYY-MM-DD'),
                    period
                });
            }
        }
        //eslint-disable-next-line
    }, [period]);

    const getPeriodFilters = () => {
        return customFilters ? customFilters : futureDatesOnly ? futurePeriodFilter : periodFilter;
    };

    return (
        <Grid item md={2} sm={3}>
            <Grid container>
                <Select
                    label="Period"
                    variant="outlined"
                    className={classes.selectRoot}
                    value={period}
                    onChange={e => setPeriod(e.target.value)}
                    inputProps={{
                        className: classes.selectInputProps
                    }}
                    MenuProps={{
                        className: classes.menuProps
                    }}
                >
                    {getPeriodFilters().map(period => {
                        return (
                            <MenuItem key={`${period.value}-option`} value={period.value}>
                                {period.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
        </Grid>
    );
}

export default withStyles(filters)(DatePeriodsFilter);
