export const categoryStyles = () => ({
    boldCell: {
        fontWeight: 'bold'
    },
    categoryLink: {
        color: '#0000EE',
        textDecoration: 'underline'
    },
    toolbarTitle: {
        display: 'block'
    },
    table: {
        '& *': {
            fontFamily: '"Roboto", "Arial", sans-serif'
        }
    },
    tableCell: {
        width: 'calc((100% - 0px) / 12)',
        boxSizing: 'border-box',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        textAlign: 'right'
    },
    leftAlign: {
        textAlign: 'left !important'
    },
    rightAlign: {
        textAlign: 'right !important'
    },
    defaultHeader: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'right'
    },
    filterBtn: {
        backgroundColor: '#565a65',
        color: '#FFF',
        borderRadius: 0,
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 400,
        padding: '6px 16px'
    }
});
