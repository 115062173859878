import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { filters } from './styles';
import { useReportFilterItems } from '../../../hooks/reportFilterItems';

function ProductsFilter({ classes, label, handleFilterChange, filters }) {
    const { productList } = useReportFilterItems();
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (productList.length > 0) {
            const value = filters['products'];
            if (value) {
                setSelectedItems(value.map(sv => productList.find(s => s.value === sv.value)));
            }
        }
    }, [filters, productList]);

    const handleChange = (_, items) => {
        handleFilterChange({
            products: items
        });
        setSelectedItems(items);
    };

    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={classes.autocomplete}
                options={productList}
                value={selectedItems}
                onChange={handleChange}
                label={label}
                getOptionLabel={option => option.label}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{`${option.label}`}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(ProductsFilter);
