import React from 'react';
import { Grid } from '@material-ui/core';
import FutureAppointmentsCheckboxFilter from './components/FutureAppointmentsCheckboxFilter';

function FutureAppointmentsFilter({ handleSetFilters, filters }) {
    return (
        <Grid container>
            <Grid item md={4}>
                <FutureAppointmentsCheckboxFilter handleFilterChange={handleSetFilters} filters={filters} />
            </Grid>
        </Grid>
    );
}

export default FutureAppointmentsFilter;
