import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography, Box } from '@material-ui/core';
import getSelectedPeriod from '../../../filters/helpers/getSelectedPeriod';
import styled from 'styled-components';
import reportsWithoutPeriod from '../../../../constants/reportsWithoutPeriod';
import { useParams } from 'react-router-dom';

function TableInfo({ type, filters, title }) {
    const { report } = useParams();

    const renderFilters = () => {
        let { to, from, period } = filters;

        /* let __filters = Object.keys(_filters).map((filter, key) => {
            let value = filters[filter];

            if (filter === 'dateError') {
                return '';
            }

            if (typeof value === 'boolean') {
                value = value.toString();
            }

            if (typeof value === 'object') {
                if (filter !== 'areas') {
                    if (filter === 'daysSinceCateg') {
                        value = getDaysSinceCategSaleLabel(value);
                        if (!value) return <></>;
                    } else {
                        value = value.join(', ');
                    }
                } else {
                    value = value.map(item => item.label).join(',');
                }
            }

            return (
                <span key={key} style={{ marginLeft: 2 }}>
                    {getFilterName(filter)}: {value}
                    {'; '}
                </span>
            );
        }); */

        to = moment(to).format('DD/MM/YYYY');
        from = moment(from).format('DD/MM/YYYY');

        return (
            <FlexBox>
                <Text variant={'body2'}>Generated: {moment().format('DD/MM/YYYY')}</Text>
                {!reportsWithoutPeriod.includes(report) && (
                    <Text variant={'body2'}>
                        Reporting Period: {from} - {to} {period && `${getSelectedPeriod(period)}`}
                    </Text>
                )}
            </FlexBox>
        );
    };

    return (
        <Box ml={3} mt={3}>
            <Title variant="h3">
                {title || 'Sales By'} {type} Report
            </Title>
            {renderFilters()}
        </Box>
    );
}

TableInfo.propTypes = { type: PropTypes.string, filters: PropTypes.array };

export default TableInfo;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const Text = styled(Typography)`
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
`;

const FlexBox = styled(Box)`
    display: flex;
`;
