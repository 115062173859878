import Moment from 'moment';

export function getPeriodInDate(period) {
    const fromAndTo = () => {
        switch (period) {
            case 'allTime': {
                return {
                    from: Moment().format('YYYY-MM-DD'),
                    to: Moment().format('YYYY-MM-DD'),
                    willGetOlderInvoice: true
                };
            }
            case 'today': {
                return {
                    from: Moment().format('YYYY-MM-DD'),
                    to: Moment().format('YYYY-MM-DD')
                };
            }

            case 'yesterday': {
                return {
                    from: Moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    to: Moment().subtract(1, 'days').format('YYYY-MM-DD')
                };
            }

            case '7days':
            case '30days':
            case '90days': {
                const days = period.split('days')[0];
                return {
                    from: Moment().subtract(days, 'days').format('YYYY-MM-DD'),
                    to: Moment().subtract(1, 'days').format('YYYY-MM-DD')
                };
            }

            case 'lastWeek':
            case 'lastMonth':
            case 'lastYear': {
                const duration = period.split('last')[1].toLowerCase();

                const getFromAndToByPeriod = date => {
                    if (date === 'week') {
                        if (Moment().day() === 0) {
                            //it's sunday, use -2 weeks, as our week ends in sunday
                            const from = Moment().subtract(2, 'weeks').startOf('week').add(1, 'day');
                            const to = Moment().subtract(2, 'weeks').endOf('week').add(1, 'day');

                            return { from, to };
                        }
                        const from = Moment().subtract(1, 'weeks').startOf('week').add(1, 'day');
                        const to = Moment().subtract(1, 'weeks').endOf('week').add(1, 'day');

                        return { from, to };
                    } else if (date === 'month') {
                        const from = Moment().subtract(1, 'months').startOf('month');
                        const to = Moment().subtract(1, 'months').endOf('month');

                        return { from, to };
                    } else {
                        const from = Moment().subtract(1, 'years').startOf('year');
                        const to = Moment().subtract(1, 'years').endOf('year');

                        return { from, to };
                    }
                };

                const { from, to } = getFromAndToByPeriod(duration);

                return {
                    from: from.format('YYYY-MM-DD'),
                    to: to.format('YYYY-MM-DD')
                };
            }

            case 'weekToDate': {
                const duration = period.split('To')[0].toLowerCase();

                const getFromAndToByPeriod = date => {
                    // if it's sunday, get the previous week start, as the week is Mon -> Sun
                    if (Moment().day() === 0) {
                        const from = Moment().subtract(1, 'weeks').startOf(date).add(1, 'day');
                        const to = Moment();

                        return { from, to };
                    }
                    const from = Moment().startOf(date).add(1, 'day');
                    const to = Moment();

                    return { from, to };
                };

                const { from, to } = getFromAndToByPeriod(duration);

                return {
                    from: from.format('YYYY-MM-DD'),
                    to: to.format('YYYY-MM-DD')
                };
            }
            case 'monthToDate':
            case 'quarterToDate':
            case 'yearToDate': {
                const duration = period.split('To')[0].toLowerCase();

                const getFromAndToByPeriod = date => {
                    const from = Moment().startOf(date);
                    const to = Moment();

                    return { from, to };
                };

                const { from, to } = getFromAndToByPeriod(duration);

                return {
                    from: from.format('YYYY-MM-DD'),
                    to: to.format('YYYY-MM-DD')
                };
            }

            case 'restOfWeek': {
                return {
                    from: Moment().startOf('day').format('YYYY-MM-DD'),
                    to: Moment().endOf('isoWeek').endOf('day').format('YYYY-MM-DD')
                };
            }
            case 'nextWeek': {
                const startNextWeek = Moment().add(1, 'week');
                const endNextWeek = startNextWeek.clone();

                return {
                    from: startNextWeek.startOf('isoWeek').startOf('day').format('YYYY-MM-DD'),
                    to: endNextWeek.endOf('isoWeek').endOf('day').format('YYYY-MM-DD')
                };
            }
            case 'restOfMonth': {
                return {
                    from: Moment().startOf('day').format('YYYY-MM-DD'),
                    to: Moment().endOf('month').endOf('day').format('YYYY-MM-DD')
                };
            }
            case 'nextMonth': {
                const startNextMonth = Moment().add(1, 'month');
                const endNextMonth = startNextMonth.clone();

                return {
                    from: startNextMonth.startOf('month').startOf('day').format('YYYY-MM-DD'),
                    to: endNextMonth.endOf('month').endOf('day').format('YYYY-MM-DD')
                };
            }
            case 'lastThreeMonths': {
                return {
                    from: Moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
                    to: Moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                };
            }
            case 'lastSixMonths': {
                return {
                    from: Moment().subtract(6, 'month').startOf('month').format('YYYY-MM-DD'),
                    to: Moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                };
            }
            case 'lastThreeYears': {
                return {
                    from: Moment().subtract(3, 'year').startOf('year').format('YYYY-MM-DD'),
                    to: Moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
                };
            }
            default: {
                return { from: '', to: '' };
            }
        }
    };

    return fromAndTo();
}
