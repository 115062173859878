import React from 'react';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import {
    withStyles,
    Accordion as MaterialUIAcordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow
} from '@material-ui/core';
import { Star, Schedule } from '@material-ui/icons';

import { getReportUrl, isFavourite, isScheduled } from '../../utils/helpers/reports';
import { useFavourites, useScheduled, useFiltered } from '../../utils/hooks/reports';
import { list } from './styles';
import { getOpenedAccordion } from '../../redux/selectors/reports';
import { useDispatch, useSelector } from 'react-redux';
import { setAccordionsState } from '../../redux/actions/reports';
import { isAvailableByPlan } from '../../collums-constants/utils';
import { useAccessTier } from '../../collums-components/hooks/accessTier';

function Report({ categoryKey, reportKey, favourited, scheduled, filters, description }) {
    const { openFeatureModal } = useAccessTier();
    const { categories, reports } = useFiltered();
    const report =
        !!categories &&
        !!categoryKey &&
        !!reports &&
        !!reportKey &&
        _.has(reports, categoryKey, reportKey) &&
        reports[categoryKey][reportKey];

    if (!report) return null;

    const isUnavailable = report.accessTier && !isAvailableByPlan(report.accessTier);
    const isDisabled = report.disabled;

    const onClickReport = e => {
        if (isUnavailable) {
            e.stopPropagation();
            e.preventDefault();
            openFeatureModal();
        }
    };

    const style = (() => {
        if (isUnavailable) {
            return {
                color: 'rgba(0, 0, 0, 0.38)',
                cursor: 'pointer'
            };
        } else if (isDisabled) {
            return {
                color: 'rgba(0, 0, 0, 0.38)',
                cursor: 'pointer',
                pointerEvents: 'none'
            };
        }
        return {};
    })();

    const renderExternalAnchorDescription = () => {
        const splitedDescription = report.description.split(report.destination);
        return (
            <p>
                {splitedDescription[0]}
                <a href={report.id} target="_blank" rel="noopener noreferrer" style={style} onClick={onClickReport}>
                    {report.destination}
                </a>
                {splitedDescription[1]}
            </p>
        );
    };

    return (
        <TableRow key={report.id}>
            <TableCell>
                {report.isIncomplete ? (
                    <p style={style} onClick={onClickReport}>
                        {report.label}
                    </p>
                ) : report.external ? (
                    <a style={style} onClick={onClickReport} href={report.id} target="_blank" rel="noopener noreferrer">
                        {report.label}
                    </a>
                ) : (
                    <Link
                        style={style}
                        onClick={onClickReport}
                        to={getReportUrl(categories[categoryKey].id, report.id, filters)}
                    >
                        {report.label}
                    </Link>
                )}
            </TableCell>
            <TableCell style={{ textAlign: 'justify' }}>
                {description ? description : report.external ? renderExternalAnchorDescription() : report.description}
            </TableCell>
            <TableCell>{favourited && <Star />}</TableCell>
            <TableCell>{scheduled && <Schedule />}</TableCell>
        </TableRow>
    );
}

function Category({ label, categoryKey, favourites, scheduled, expanded, handleChange, classes, disabled }) {
    const { reports } = useFiltered();
    if (!_.has(reports, categoryKey)) return null;

    return (
        <MaterialUIAcordion
            expanded={expanded === categoryKey}
            onChange={handleChange(categoryKey)}
            className={classes.accordion}
            disabled={disabled}
        >
            <AccordionSummary className={classes.accordionSummary}>
                <Typography>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead className={classes.tableHeadStyles}>
                        <TableRow>
                            <TableCell>
                                <Typography>Report</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Description</Typography>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBodyStyles}>
                        {_.keys(reports[categoryKey])
                            .sort((a, b) => a.localeCompare(b))
                            .map(reportKey => (
                                <Report
                                    favourited={isFavourite({ favourites, categoryKey, reportKey })}
                                    scheduled={isScheduled({ scheduled, categoryKey, reportKey })}
                                    key={`${reportKey}-${categoryKey}`}
                                    reportKey={reportKey}
                                    categoryKey={categoryKey}
                                />
                            ))}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </MaterialUIAcordion>
    );
}

function Favourites({ favourites, scheduled, expanded, handleChange, classes }) {
    const { categories, reports } = useFiltered();

    /**
     * @typedef {Object} Keys
     * @property {String} categoryKey - The category object key.
     * @property {String} reportKey - The report object key.
     */

    /**
     * @return { Keys } Category and report keys.
     */
    const keys =
        !!_.keys(favourites).length &&
        _.keys(categories)
            .map(categoryKey => ({ categoryKey, reports: _.keys(reports[categoryKey]) }))
            .map(({ categoryKey, reports }) => reports.map(reportKey => ({ categoryKey, reportKey })))
            .flat()
            .filter(keys =>
                favourites.map(favourite => favourite.reportId).includes(reports[keys.categoryKey][keys.reportKey].id)
            )
            .map(item => {
                const favouriteItem = favourites.filter(
                    favourite => favourite.reportId === reports[item.categoryKey][item.reportKey].id
                )[0];
                return {
                    ...item,
                    filters: favouriteItem.filters,
                    description: favouriteItem.description
                };
            });
    if (!keys) return null;

    return (
        <MaterialUIAcordion expanded={expanded === 'favorites' || expanded === ''} onChange={handleChange('favorites')}>
            <AccordionSummary>
                <Typography>Favourites</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead className={classes.tableHeadStyles}>
                        <TableRow>
                            <TableCell>
                                <Typography>Report</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Description</Typography>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBodyStyles}>
                        {keys.map(({ categoryKey, reportKey, filters, description }) => (
                            <Report
                                favourited={isFavourite({ favourites, categoryKey, reportKey })}
                                scheduled={isScheduled({ scheduled, categoryKey, reportKey })}
                                key={`favourite-${reportKey}-${categoryKey}`}
                                categoryKey={categoryKey}
                                reportKey={reportKey}
                                filters={filters}
                                description={description}
                            />
                        ))}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </MaterialUIAcordion>
    );
}

function List({ classes }) {
    const { category, report } = useParams();
    const favourites = useFavourites();
    const scheduled = useScheduled();
    const { categories, reports } = useFiltered();

    const dispatch = useDispatch();

    const expanded = useSelector(getOpenedAccordion);

    const handleChange = panel => (event, isExpanded) => {
        dispatch(setAccordionsState(isExpanded ? panel : false));
    };

    if (category && report) return null;

    return (
        <>
            <Favourites
                favourites={favourites}
                scheduled={scheduled}
                expanded={expanded}
                handleChange={handleChange}
                classes={classes}
            />
            {_.keys(reports).map(categoryKey => {
                return (
                    <Category
                        favourites={favourites}
                        scheduled={scheduled}
                        key={categories[categoryKey].id}
                        label={categories[categoryKey].label}
                        categoryKey={categoryKey}
                        expanded={expanded}
                        handleChange={handleChange}
                        classes={classes}
                        disabled={categories[categoryKey]?.disabled || false}
                    />
                );
            })}
        </>
    );
}

export default withStyles(list)(List);
