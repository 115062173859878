import React, { useEffect, useState } from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import columns from './columns';

function Result({ values, query, filterBtn, loading, setValues }) {
    const filters = query;
    const [columnsValues, setColumnsValues] = useState([]);
    const [useGrandTotalRows, setUseGrandTotalRows] = useState(false);

    useEffect(() => {
        if (query?.clinics?.length && query?.clinics?.length > 1) {
            setColumnsValues(columns);
            setUseGrandTotalRows(true);
        } else {
            setUseGrandTotalRows(false);
            setColumnsValues(columns.filter(el => el.field !== 'locationName'));
        }
    }, [query]);

    return (
        <DefaultMaterialTable
            title={'Staff Commission Sales'}
            filters={filters}
            columns={columnsValues}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            canShowFilters
            showFiltersOnHeader={true}
            useGrandTotalRows={useGrandTotalRows}
        />
    );
}

export default Result;
