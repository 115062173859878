import React from 'react';
import { FormControl, Select, MenuItem, withStyles, Box } from '@material-ui/core';
import { filters } from './styles';
import getSelectedReport from '../helpers/getSelectedReport';

function ReportsSelect({ classes, handleGroupChange, group, report, category, handleSetFilters }) {
    const options = getSelectedReport(category, report);
    const label = options.LABEL;

    delete options.LABEL;

    return (
        <Box>
            <FormControl variant="outlined" className={classes.formControl}>
                <Select
                    className={classes.selectRoot}
                    labelId="select-report-label"
                    value={group}
                    onChange={e => {
                        if (handleSetFilters && report !== e.target.value) {
                            handleSetFilters({
                                categories: []
                            });
                            handleGroupChange(e.target.value);
                        }
                    }}
                >
                    {Object.keys(options).map((key, index) => (
                        <MenuItem key={`${index}-menu-item`} value={options[key].id}>
                            {label} {options[key].label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default withStyles(filters)(ReportsSelect);
