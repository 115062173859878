const { COLLUMS_ACCESS_TIER } = require('../collums-constants');

module.exports = {
    FINANCIALS: {
        CATEGORY_AND_TYPE_SALES: {
            id: 'category-and-type-sales',
            label: 'Category & Type Sales',
            description: 'Sales sorted by service, course and product categories.',
            filters: {}
        },
        SPECIAL_OFFER_SALES: {
            id: 'special-offer-sales',
            label: 'Special Offer Sales',
            description: 'A list of special offer sales',
            filters: {}
        },
        SALES_BY: {
            id: 'sales-by',
            label: 'Sales By Item',
            description: 'Sales sorted by services, courses or products.',
            filters: {
                items: 'items',
                tags: 'tags',
                soldBy: 'soldBy',
                courses: 'courses',
                categories: 'categories',
                products: 'products',
                services: 'services',
                clinics: 'clinics'
            }
        },
        INVOICE_LOG: {
            id: 'invoice-log',
            label: 'Invoice Log',
            description: 'A list of all invoices',
            filters: {
                paymentStatus: 'paymentStatus'
            }
        },
        DETAILED_INVOICE_LOG: {
            id: 'detailed-invoice-log',
            label: 'Detailed Invoice Log',
            description: 'A list of all invoice items',
            filters: {}
        },
        PAYMENT_LOG: {
            id: 'payment-log',
            label: 'Payment Log',
            description: 'Log of all payments received',
            filters: {
                paymentStatus: 'paymentStatus',
                paymentMethod: 'paymentMethod',
                clinic: 'clinic'
            }
        },
        PAYMENT_TOTALS_RECONCILIATION: {
            id: 'payment-totals',
            label: 'Payment Totals (Cash Up)',
            description: 'A list of all payments from Account, Cash, Card, Gift card and Online payments',
            filters: {}
        },
        VALUE_FUTURE_APPOINTMENTS: {
            id: 'future-appointments',
            label: 'Value Of Future Appointments',
            description: 'The value of future appointments by day, staff or category.',
            filters: {
                redemption: 'redemption'
            },
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        OUTSTANDING_COURSES: {
            id: 'outstanding-courses',
            label: 'Outstanding Courses',
            description: 'Details of Outstanding Courses ex Tax.',
            filters: {
                clinics: 'clinics',
                includeExpired: 'includeExpired'
            },
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        OUTSTANDING_ACCOUNT_BALANCES: {
            id: 'outstanding-acc-balances',
            label: 'Account Balances',
            description:
                'Current list of clients with both positive and negative account balances - those with money on account yet to be spent and those owing you money.',
            filters: {},
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        SERVICE_SALES_PERFORMANCE: {
            id: 'service-sales-performance',
            label: 'Service Sales Performance',
            description:
                'Service sales performance inc average sale price and sales per hour. NB Duration is calculated using Default Service Duration - not the total time of services performed.',
            filters: {},
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        VALUE_STOCK: {
            id: `${process.env.REACT_APP_STOCK_URL}`,
            label: 'Value Of Stock',
            description: 'This information is found and can be downloaded via the stock module.',
            external: true,
            destination: 'stock',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        VAT: {
            id: 'vat',
            label: 'VAT',
            description: 'Details of total sales by different taxes.',
            filters: {
                clinic: 'clinic'
            },
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        STAFF_SALES_PERFORMANCE: {
            id: 'staff-sales-and-performance',
            label: 'Staff Sales & Performance',
            description:
                'Sales and treatments performed grouped by staff member. This report does not count unpaid invoices.',
            filters: {
                clinics: 'clinics',
                includeTaxes: 'includeTaxes'
            },
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        }

        /* SALES_BY_TIME: {
            id: 'sales-by-time',
            label: 'Sales By Time',
            description: 'Sales grouped by hour, day, month, quarter or year',
            filters: {}
        },
        SALES_LOG: { id: 'payments-log', label: 'Sales Log', description: 'A list of all items sold', filters: {} },
        PAYMENTS_LOG: {
            id: 'financials-total',
            label: 'Payments Log',
            description: 'A list of all payments',
            filters: {}
        } */
    },
    GIFT_VOUCHERS: {
        GIFT_VOUCHERS_TOTALS: {
            id: 'gift-voucher-totals',
            label: 'Gift Voucher Totals',
            description: 'Total value of gift vouchers sold, used and outstanding.',
            filters: {
                includeExpired: 'includeExpired'
            },
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        OUTSTANDING_GIFT_VOUCHERS: {
            id: 'outstanding-gift-vouchers',
            label: 'Outstanding Gift Vouchers',
            description: 'Detail of outstanding gift voucher (those with value > 0).',
            filters: {
                includeExpired: 'includeExpired'
            },
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        }
    },
    APPOINTMENTS: {
        CONVERSION_LOG: {
            id: 'consultation-conversion-log',
            label: 'Consultation conversion log',
            description: 'List of all consultations and conversion data',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            filters: {
                clinic: 'clinic',
                staffs: 'staffs'
            },
            disabled: true
        },
        CONVERSION_BY_STAFF_BASIC: {
            id: 'conversion-by-staff-basic',
            label: 'Conversion by Staff - Basic',
            description: 'Consultation conversion numbers and values by staff member.',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            filters: {
                clinics: 'clinics',
                staffs: 'staffs'
            },
            disabled: true
        },
        CONVERSION_BY_STAFF_ADVANCED: {
            id: 'conversion-by-staff-advanced',
            label: 'Conversion by Staff - Advanced',
            description: 'Consultation conversion numbers and values by staff member.',
            filters: {
                clinics: 'clinics',
                staffs: 'staffs',
                includeTaxes: 'includeTaxes'
            },
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            disabled: true
        },
        CONVERSION_BY_LOCATION: {
            id: 'conversion-by-location',
            label: 'Conversion by Location',
            description:
                'Consultation conversion numbers and values by staff member. You must add a Consultation tags to services for conversion reports to work. See the help article.',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            filters: {
                clinics: 'clinics'
            },
            disabled: true
        },
        UPSELL_LOG: {
            id: 'upsell-log',
            label: 'Upsell Log',
            description: 'List of all consultations and upsell data',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            filters: {
                clinic: 'clinic',
                staffs: 'staffs'
            },
            disabled: true
        },
        APPOINTMENT_LOG: {
            id: 'appointment-log',
            label: 'Appointment log',
            description: 'List of appointments that can be used to see no of cancelled, booked etc.',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            filters: {}
        },
        APPOINTMENT_LOG_AND_SLIPPAGE: {
            id: 'appointment-log-and-slippage',
            label: 'Appointment log & Slippage',
            description: 'List of all services performed, no of days since that (and last) visits.',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            filters: {
                clinic: 'clinic',
                daysSinceCateg: 'daysSinceCateg',
                staffs: 'staffs',
                futureBooked: 'futureBooked',
                serviceAndCategories: 'serviceAndCategories'
            },
            requiredFilters: ['clinic', 'daysSinceCateg']
        },
        UPSELL_BY_STAFF: {
            id: 'upsell-by-staff',
            label: 'Upsell by Staff',
            description: 'Upsell % and values by staff member.',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            disabled: true
        },
        UPSELL_BY_LOCATION: {
            id: 'upsell-by-location',
            label: 'Upsell by Location',
            description: 'Upsell % and values by staff member.',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            disabled: true
        },
        REBOOKINGS: {
            id: 'rebookings',
            label: 'Rebooking by location & category',
            description: 'Data on rebooking by location and category',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        }
    },
    CLIENTS: {
        TOP_SPENDER: {
            id: 'top-spender',
            label: 'Top Spender',
            description: 'Clients list with total spend',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS,
            filters: {
                results: 'results'
            }
        },
        DUPLICATE_CLIENTS: {
            id: `${process.env.REACT_APP_ADMIN_URL}/resources/merge-clients`,
            label: 'Duplicate Clients',
            description: 'This information is found in the Admin/Clients page.',
            external: true,
            destination: 'Admin/Clients',
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        }
    },
    MARKETING: {},
    OTHER: {},
    STAFF: {},
    COMMISSION: {
        STAFF_COMMISSION_SALES: {
            id: 'staff-commission-sales',
            label: 'Staff Commission Sales',
            description:
                'Sales and treatments performed that are eligible for commission, grouped by staff member. This report does NOT count sales that are not eligible for commission and does not count unpaid invoices.',
            filters: {
                clinics: 'clinics',
                includeTaxes: 'includeTaxes'
            },
            requiredFilters: ['clinics'],
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        }
    },
    STOCK: {
        TOXIN_USAGE_SUMMARY: {
            id: 'toxin-usage-summary',
            label: 'Toxin usage summary',
            description: 'Data on toxin usage by staff & area. Includes toxin cost.',
            filters: {
                clinic: 'clinic'
            },
            requiredFilters: ['clinic'],
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        TOXIN_USAGE_BY_AREA: {
            id: 'toxin-usage-by-area',
            label: 'Toxin usage by area',
            description: 'Data on toxin usage by staff & area',
            filters: {
                clinic: 'clinic',
                areas: 'areas',
                toxin: 'toxin'
            },
            requiredFilters: ['clinic', 'areas'],
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        TOXIN_USAGE_BY_STAFF: {
            id: 'toxin-usage-by-staff',
            label: 'Toxin usage by staff',
            description: 'Data on toxin usage by staff & area',
            filters: {
                clinic: 'clinic',
                areas: 'areas',
                staffs: 'staffs'
            },
            requiredFilters: ['clinic', 'areas'],
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        },
        TOXIN_USAGE_LOG: {
            id: 'toxin-usage-log',
            label: 'Toxin usage log',
            description: 'Data on toxin usage by staff & area',
            filters: {
                clinic: 'clinic',
                toxins: 'toxins',
                staffs: 'staffs'
            },
            requiredFilters: ['clinic'],
            accessTier: COLLUMS_ACCESS_TIER.EXPERT_ACCESS
        }
    },
    STAFF_SALES: {}
};
