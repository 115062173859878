import ConversionLog from './conversion_log';
import ConversionByStaffBasic from './conversion_staff_basic';
import ConversionByStaffAdvanced from './conversion_staff_advanced';
import Rebooking from './rebooking';
import ConversionByLocation from './conversion_location';
import UpsellLog from './upsell_log';
import AppointmentLogAndSlippage from './appointment-log-and-slippage';
import AppointmentLog from './appointment-log';
import UpsellByStaff from './upsell_by_staff';
import UpsellByLocation from './upsell_by_location';

export default {
    ConversionLog,
    ConversionByStaffBasic,
    ConversionByStaffAdvanced,
    ConversionByLocation,
    UpsellLog,
    AppointmentLogAndSlippage,
    AppointmentLog,
    UpsellByStaff,
    UpsellByLocation,
    Rebooking
};
