const reportsWithTotalRow = [
    'Invoice Log',
    'Staff Commission Sales',
    'Staff Sales & Performance',
    'Detailed Invoice Log',
    'Payment Totals (Cash Up)',
    'Outstanding Courses',
    'Account Balances',
    'Gift Voucher Totals',
    'Outstanding Gift Vouchers',
    'Service Sales Performance',
    'Sales by Service',
    'Sales By Product',
    'Value of future appointments',
    'Payment Log',
    'Appointment log'
];
export default reportsWithTotalRow;
