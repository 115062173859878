import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import config from '../../config';
import { getQueryParams } from '../helpers/common';
import { validateJobPermissions } from '../../collums-components/helpers/index';
import OrganisationApi from '../../collums-components/api/organizationApi';
import { isValidMongoIdString } from '../../collums-constants/utils';
import { CURRENT_CLINIC, TOKEN } from '../../collums-constants/storageKeys';
import api from '../../api/api';
import AuthApi from '../../api/AuthApi';
import { useClinics } from '../../collums-components/hooks/clinics';
import { useReportFilterItems } from '../../hooks/reportFilterItems';
import { useCookies } from 'react-cookie';
import { setSentryUser } from '../../collums-components/helpers/sentry';
import Intercom from '../../collums-components/helpers/Intercom';

export const useAuth = () => {
    const { fetchClinics } = useClinics();
    const { loadFilterItems, setOrganisation } = useReportFilterItems();
    const [isInitialized, setIsInitialized] = useState(false);
    const [practitionerId, setPractitionerId] = useState('');
    const [isShowingPINModal, setIsShowingPINModal] = useState(false);
    const [sentryDialogOptions, setSentryDialogOptions] = useState({});
    const location = useLocation();
    const [cookies] = useCookies([TOKEN]);

    useEffect(() => {
        const doEffect = async () => {
            const currentClinic = getQueryParams(location).clinic;
            if (currentClinic && isValidMongoIdString(currentClinic)) {
                localStorage.setItem(CURRENT_CLINIC, currentClinic);
            }
            const token = getQueryParams(location).token || cookies.token;
            if (token) {
                try {
                    api.config({ headers: { Authorization: token } });
                    const me = await AuthApi.getMe();
                    setPractitionerId(me?.id);
                    setSentryDialogOptions(setSentryUser(me));

                    localStorage.setItem(TOKEN, token);
                    await validateJobPermissions(token, me, process.env.REACT_APP_NAME);

                    const organisation = await OrganisationApi.getOrg();
                    setOrganisation(organisation);
                    localStorage.setItem('currency', organisation?.currency);

                    loadFilterItems();
                    fetchClinics();

                    await Intercom.updateUser(me);
                } catch (error) {
                    if (error && error.status === 401) {
                        window.location = `${config.authUrl}?redirect=reporting`;
                    } else {
                        throw error;
                    }
                }
            } else if (!token) {
                window.location = `${config.authUrl}?redirect=reporting`;
            }
            setIsInitialized(true);
        };

        doEffect();
    }, []); // eslint-disable-line

    return {
        isInitialized,
        isShowingPINModal,
        setIsShowingPINModal,
        sentryDialogOptions,
        practitionerId
    };
};

export const useQueryParams = () => {
    const location = useLocation();
    const [queryParams, setQueryParams] = useState();
    useEffect(() => {
        setQueryParams(getQueryParams(location));
    }, [location]);
    return queryParams;
};
