import api from './api';
import { generateQueryString, clearHoursAndMinutes } from '../utils/helpers/common';

class ReportApi {
    static async getReport({ category, report, group, query }) {
        if (query.from) {
            query.from = clearHoursAndMinutes(query.from);
        }
        if (query.to) {
            query.to = clearHoursAndMinutes(query.to, true);
        }
        if (report === 'invoice-log') {
            //TODO: Change this to a good solution
            report = 'invoice-logs';
            return api
                .request({
                    method: 'GET',
                    url: `/reports/${category}/${report}?${generateQueryString(query)}`
                })
                .then(res => res)
                .catch(console.error);
        } else {
            return api
                .request({
                    method: 'GET',
                    url: `/reports/${category}/${report}/${group}?${generateQueryString(query)}`
                })
                .then(res => res)
                .catch(console.error);
        }
    }
    static async downloadReport({ category, report, group, query }) {
        return api
            .request({
                method: 'GET',
                url: `/reports/${category}/${report}/${group}/download?${generateQueryString(query)}`
            })
            .then(res => res)
            .catch(console.error);
    }
    static async getFavourites() {
        return api
            .request({ method: 'GET', url: '/reports/favourites' })
            .then(res => res)
            .catch(console.error);
    }
    static async getScheduleds() {
        return api
            .request({ method: 'GET', url: '/reports/scheduled' })
            .then(res => res)
            .catch(console.error);
    }
    static async getServices(count, active) {
        return api
            .request({
                method: 'GET',
                url: `/services?isFromOrg=true${count ? `&count=${count}` : ''}${!active ? '&active=false' : ''}`
            })
            .catch(console.error);
    }

    static async getCourses(count) {
        return api
            .request({ method: 'GET', url: `/course?isFromOrg=true${count ? `&pageSize=${count}` : ''}` })
            .catch(console.error);
    }

    static async getProducts() {
        return api
            .request({ method: 'GET', url: '/stocks/get-all' })
            .then(res => res)
            .catch(console.error);
    }

    static async getProductsCategories() {
        return api
            .request({
                method: 'GET',
                url: '/stocks/categories?isFromOrg=true',
                params: { withSubcategories: false, archived: true }
            })
            .then(res => res);
    }

    static async getServicesCategories() {
        return api
            .request({
                method: 'GET',
                url: '/categories?isFromOrg=true',
                params: { withSubCategories: false, archived: true }
            })
            .then(res => res);
    }

    static async getCoursesCategories() {
        return api
            .request({
                method: 'GET',
                url: '/course/categories?isFromOrg=true',
                params: { withSubcategories: false, archived: true }
            })
            .then(res => res);
    }

    static async getClients() {
        return api.request({ method: 'GET', url: '/customers?isFromOrg=true' }).then(res => res);
    }

    static async getStaffs() {
        return api.request({ method: 'GET', url: '/practitioners/all' });
    }
    static async deleteScheduled(id) {
        return api
            .request({ method: 'DELETE', url: `/reports/scheduled/${id}` })
            .then(res => res)
            .catch(console.error);
    }
    static async saveFavourite(reportId, filters, description) {
        return api
            .request({
                method: 'POST',
                url: '/reports/favourites',
                data: { reportId, filters: JSON.stringify(filters), description }
            })
            .then(res => res)
            .catch(console.error);
    }
    static async deleteFavourite(reportId) {
        return api
            .request({ method: 'DELETE', url: '/reports/favourites/' + reportId })
            .then(res => res)
            .catch(console.error);
    }

    static async getPaymentMethods() {
        return api
            .request({
                method: 'GET',
                url: '/invoices/payment-methods'
            })
            .then(res => res)
            .catch(error => {
                console.error(error);
                return [];
            });
    }
}

export default ReportApi;
