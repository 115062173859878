import React from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import columns from './columns';

function Result({ values, query, filterBtn, loading, setValues }) {
    return (
        <DefaultMaterialTable
            title={'Appointment log'}
            filters={query}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            canShowFilters
            showFiltersOnHeader
        />
    );
}

export default Result;
