import React from 'react';
import TextField from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { filters as filtersStyles } from './styles';

import withStyles from '@material-ui/styles/withStyles';
import moment from 'moment';

const DateInput = ({ classes, isFirst, targetDate, id, label, onClickDateField, disabled }) => {
    const getFormattedDate = () => {
        if (targetDate) {
            return moment(targetDate).format('DD/MM/YYYY');
        }
        return '';
    };

    return (
        <TextField
            id={id}
            label={label}
            value={getFormattedDate(targetDate)}
            onChange={() => {}}
            InputProps={{
                endAdornment: <DateRangeIcon />
            }}
            variant="outlined"
            onClick={onClickDateField}
            className={isFirst ? classes.marginRight : ''}
            focused={false}
            disabled={disabled}
        />
    );
};

export default withStyles(filtersStyles)(DateInput);
