import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ReportFiltersContext = createContext({});

export const ReportFiltersProvider = ({ children }) => {
    const [savedFilters, saveFilters] = useState([]);
    const [items, setItems] = useState({});

    const addItems = (itemKey, values) => {
        setItems({
            ...items,
            [itemKey]: values
        });
    };

    return (
        <ReportFiltersContext.Provider value={{ savedFilters, saveFilters, addItems }}>
            {children}
        </ReportFiltersContext.Provider>
    );
};

ReportFiltersProvider.propTypes = {
    children: PropTypes.element
};

export const useReportFilters = () => useContext(ReportFiltersContext);
