module.exports = {
    FINANCIALS: {
        SALES_BY: {
            LABEL: 'Sales By',
            /* ITEMS: {
                id: 'items',
                label: 'Items'
            },
            TYPES: {
                id: 'types',
                label: 'Types'
            },
            CATEGORIES: {
                id: 'categories',
                label: 'Categories'
            },*/
            COURSE: {
                id: 'courses',
                label: 'Course'
            },
            SERVICES: {
                id: 'services',
                label: 'Service'
            },
            PRODUCTS: {
                id: 'products',
                label: 'Product'
            } /*,
            CLIENTS: {
                id: 'clients',
                label: 'Clients'
            },
            STAFFS: {
                id: 'staffs',
                label: 'Staffs'
            }*/
        },
        CATEGORY_AND_TYPE_SALES: {
            LABEL: 'Category & Type Sales'
            /* TYPES: {
                id: 'types',
                label: 'Types'
            },
            CATEGORIES: {
                id: 'categories',
                label: 'Categories'
            }, */
        },
        SPECIAL_OFFER_SALES: {
            LABEL: 'SPECIAL OFFER SALES'
        },
        INVOICE_LOG: {
            LABEL: 'Invoices Log',
            STATUS: {
                id: 'status',
                label: 'Status'
            }
        },
        DETAILED_INVOICE_LOG: {
            LABEL: 'Detailed Invoice Log'
        },
        SALES_BY_TIME: {
            LABEL: 'Sales By',
            HOUR_OF_DAY: { id: 'hour-of-day', label: 'Hour Of day' },
            DAY: { id: 'day', label: 'Day' },
            MONTH: { id: 'month', label: 'Month' },
            QUARTER: { id: 'quarter', label: 'Quarter' },
            YEAR: { id: 'year', label: 'Year' }
        },
        PAYMENT_TOTALS_RECONCILIATION: {
            LABEL: 'Payment Totals (Cash Up)'
        },
        TOP_SPENDER: {
            LABEL: 'Top Spender'
        },
        FUTURE_APPOINTMENTS: {
            LABEL: 'Future Appointments',
            DAY: {
                id: 'day',
                label: 'Day'
            },
            STAFF: {
                id: 'staff',
                label: 'Staff'
            },
            CATEGORY: {
                id: 'category',
                label: 'Category'
            }
        },
        SERVICE_SALES_PERFORMANCE: {
            LABEL: 'Service Sales Performance'
        },
        OUTSTANDING_COURSES: {
            LABEL: 'outstanding-courses'
        },
        OUTSTANDING_ACC_BALANCES: {
            LABEL: 'outstanding-acc-balances'
        },
        GIFT_VOUCHERS: {
            GIFT_VOUCHER_TOTALS: {
                LABEL: 'Gift Voucher Totals'
            },
            OUTSTANDING_GIFT_VOUCHERS: {
                LABEL: 'Outstanding Gift Vouchers'
            }
        },
        VAT: {
            LABEL: 'VAT'
        }
    },
    COMMISSION: {
        STAFF_COMMISSION_SALES: {
            LABEL: 'Staff Commission Sales'
        }
    },
    STOCK: {
        TOXIN_USAGE_SUMMARY: {
            LABEL: 'Toxin usage summary'
        },
        TOXIN_USAGE_BY_AREA: {
            LABEL: 'Toxin usage by area'
        },
        TOXIN_USAGE_BY_STAFF: {
            label: 'Toxin usage by staff'
        },
        TOXIN_USAGE_LOG: {
            label: 'Toxin usage log'
        }
    },
    APPOINTMENT: {
        APPOINTMENT_LOG: {
            label: 'Appointment log'
        },
        APPOINTMENT_LOG_AND_SLIPPAGE: {
            label: 'Appointment log & Slippage'
        }
    }
};
