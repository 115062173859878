import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { filters } from './styles';
import { useReportFilterItems } from '../../../hooks/reportFilterItems';

function CategoriesFilter({ classes, label, handleFilterChange, filters, mainGroup }) {
    const {
        categoriesForServiceSalesBy,
        categoriesForCoursesSalesBy,
        categoriesForProductSalesBy
    } = useReportFilterItems();
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        switch (mainGroup) {
            case 'services': {
                setItems(categoriesForServiceSalesBy);
                break;
            }
            case 'courses': {
                setItems(categoriesForCoursesSalesBy);
                break;
            }
            case 'products':
            default: {
                setItems(categoriesForProductSalesBy);
            }
        }
        //eslint-disable-next-line
    }, [mainGroup, categoriesForProductSalesBy, categoriesForCoursesSalesBy, categoriesForServiceSalesBy]);

    useEffect(() => {
        if (items.length > 0) {
            const value = filters['categories'];

            if (value) {
                setSelectedItems(value.map(sv => items.find(s => s.value === sv.value)));
            } else {
                setSelectedItems([]);
            }
        }
    }, [filters, items]);

    const handleChange = (_, items) => {
        handleFilterChange({
            categories: items
        });
        setSelectedItems(items);
    };

    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={classes.autocomplete}
                options={items}
                value={selectedItems}
                onChange={handleChange}
                label={label}
                getOptionLabel={option => option?.label}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{`${option.label}`}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(CategoriesFilter);
