import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import TableContainer from '@material-ui/core/TableContainer';
import { ChevronDown, ChevronUp } from '../../../assets/icons';
import Loading from '../../common/CollumsTable/components/Loading';
import {
    SORT_DESC,
    TYPE_SERVICE,
    FIELD_QUANTITY,
    SORT_ASC,
    FIELD_NET_PRICE,
    FIELD_AVERAGE
} from '../../../constants/dashboard';
import { poundsFormatter } from './helpers';

const clearCurrencyFormatter = currency => {
    return currency ? Number(currency.replace(/[^0-9.-]+/g, '')) : 0;
};

const CourseServiceCategories = ({ classes, data, filters, type = TYPE_SERVICE }) => {
    const [stats, setStats] = useState(null);
    const [sortedStats, setSortedStats] = useState(null);
    const [sorting, setSorting] = useState({ field: FIELD_QUANTITY, order: SORT_DESC });

    useEffect(() => {
        if (data) {
            let temp = type === TYPE_SERVICE ? data?.services?.items : data?.courses?.items;
            if (temp) {
                temp = temp.map(item => {
                    const netPriceFloat = clearCurrencyFormatter(item.netPrice);
                    return {
                        ...item,
                        netPriceFloat,
                        average: netPriceFloat / item.quantity || 0
                    };
                });
                setStats(temp);
                sort(sorting.field, sorting.order, temp);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        sort(sorting.field, sorting.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting]);

    const sort = (field, order = 'asc', items = stats) => {
        if (!items) {
            return false;
        }

        function compare(a, b) {
            if (a[field] < b[field]) {
                return -1;
            }
            if (a[field] > b[field]) {
                return 1;
            }
            return 0;
        }

        const temp = cloneDeep(items);

        temp.sort(compare);

        if (order === SORT_DESC) {
            temp.reverse();
        }
        setSortedStats(temp.slice(0, 5));
    };

    const getDatePeriod = () => {
        if (filters) {
            return `${moment(filters.from).format('YYYY-MM-DD')} - ${moment(filters.to).format('YYYY-MM-DD')}`;
        }
    };

    const handlerSortClick = field => {
        if (field === sorting.field) {
            setSorting(prev => ({
                ...prev,
                order: prev.order === SORT_DESC ? SORT_ASC : SORT_DESC
            }));
        } else {
            setSorting({ field, order: SORT_DESC });
        }
    };

    const renderSortIcon = field => {
        if (sorting.field !== field) {
            return <div className={classes.emptySorter} />;
        }
        return (
            <IconButton size="small">
                {sorting.order === SORT_DESC ? <ChevronDown variant="small" /> : <ChevronUp variant="small" />}
            </IconButton>
        );
    };

    const getName = () => (type === TYPE_SERVICE ? 'Top 5 Service Categories' : 'Top 5 Course Categories');

    if (!data || !stats) {
        return (
            <div className={classes.reportItemWrapper}>
                <Loading />
            </div>
        );
    }

    return (
        <div className={classes.reportItemWrapper}>
            <h3>{getName()}</h3>
            <h3>{getDatePeriod()}</h3>

            {sortedStats && (
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell
                                    className={classes.mousePointer}
                                    align="right"
                                    onClick={() => handlerSortClick(FIELD_QUANTITY)}
                                >
                                    #{renderSortIcon(FIELD_QUANTITY)}
                                </TableCell>
                                <TableCell
                                    className={classes.mousePointer}
                                    align="right"
                                    onClick={() => handlerSortClick(FIELD_NET_PRICE)}
                                >
                                    Ex VAT{renderSortIcon(FIELD_NET_PRICE)}
                                </TableCell>
                                <TableCell
                                    className={classes.mousePointer}
                                    align="right"
                                    onClick={() => handlerSortClick(FIELD_AVERAGE)}
                                >
                                    Average{renderSortIcon(FIELD_AVERAGE)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedStats.map(item => (
                                <TableRow key={0}>
                                    <TableCell component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                    <TableCell align="right">{item.quantity}</TableCell>
                                    <TableCell align="right">{poundsFormatter.format(item.netPriceFloat)}</TableCell>
                                    <TableCell align="right">{poundsFormatter.format(item.average)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default withStyles(styles)(CourseServiceCategories);
