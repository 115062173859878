import React from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import getColumns from './columns';

function Result({ values, query, filterBtn, loading, setValues }) {
    const filters = query;
    const columns = getColumns();

    return (
        <DefaultMaterialTable
            title={'Gift Voucher Totals'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
        />
    );
}

export default Result;
