import api from './config';

class ProductApi {
    static getAll(query) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/stocks/get-all?${query}`
        });
    }

    static getByTagType(type, clinic) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/stocks/tags/type/${type}${clinic ? `?clinic=${clinic}` : ''}`
        });
    }

    static getToxinsFromOrg() {
        return api.request({
            method: 'GET',
            url: '/stocks/toxin-from-org'
        });
    }
}

export default ProductApi;
