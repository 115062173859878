import { toLocaleString } from '../../../../../../collums-components/helpers';

const getColumns = () => {
    return [
        {
            title: 'Staff',
            field: 'staff'
        },
        {
            title: 'Service #',
            field: 'serviceCount'
        },
        {
            title: 'Upsell #',
            field: 'upsellCount'
        },
        {
            title: 'Upsell %',
            field: 'upsellRate',
            render(data) {
                return (data.upsellRate * 100).toLocaleString('en-GB', { maximumFractionDigits: 1 }) + '%';
            }
        },
        {
            title: 'Upsell value - service',
            field: 'upsellService',
            render(data) {
                return toLocaleString(data.upsellService);
            }
        },
        {
            title: 'Upsell value - course',
            field: 'upsellCourse',
            render(data) {
                return toLocaleString(data.upsellCourse);
            }
        },
        {
            title: 'Upsell value - product',
            field: 'upsellProduct',
            render(data) {
                return toLocaleString(data.upsellProduct);
            }
        },
        {
            title: 'Upsell value - total',
            field: 'upsellTotal',
            render(data) {
                return toLocaleString(data.upsellTotal);
            }
        },
        {
            title: 'Upsell value - avg',
            field: 'upsellAvg',
            render(data) {
                return toLocaleString(data.upsellAvg);
            }
        }
    ];
};

export default getColumns;
