import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import { styles } from './styles';
import Loading from '../../common/CollumsTable/components/Loading';
import moment from 'moment-timezone';
import { poundsFormatter } from './helpers';

const clearCurrencyFormatter = currency => {
    return currency ? Number(currency.replace(/[^0-9.-]+/g, '')) : 0;
};

const TypeSalesComponent = ({ classes, data, filters }) => {
    const [stats, setStats] = useState(null);
    useEffect(() => {
        if (data) {
            const temp = {
                services: {
                    quantity: data?.services?.totals?.quantity,
                    total: clearCurrencyFormatter(data?.services?.totals?.netPrice),
                    avg: 0
                },
                products: {
                    quantity: data?.products?.totals?.quantity,
                    total: clearCurrencyFormatter(data?.products?.totals?.netPrice),
                    avg: 0
                },
                courses: {
                    quantity: data?.courses?.totals?.quantity,
                    total: clearCurrencyFormatter(data?.courses?.totals?.netPrice),
                    avg: 0
                },
                grandTotal: {
                    quantity: data?.grandTotal?.quantity,
                    total: clearCurrencyFormatter(data?.grandTotal?.netPrice),
                    avg: 0
                }
            };
            temp.services.avg = temp.services.total / temp.services.quantity || 0;
            temp.products.avg = temp.products.total / temp.products.quantity || 0;
            temp.courses.avg = temp.courses.total / temp.courses.quantity || 0;
            temp.grandTotal.avg = temp.grandTotal.total / temp.grandTotal.quantity || 0;

            setStats(temp);
        }
    }, [data]);

    if (!data || !stats) {
        return (
            <div className={classes.reportItemWrapper}>
                <Loading />
            </div>
        );
    }

    const getDatePeriod = () => {
        if (filters) {
            return `${moment(filters.from).format('DD-MM-YYYY')} - ${moment(filters.to).format('DD-MM-YYYY')}`;
        }
    };

    return (
        <div className={classes.reportItemWrapper}>
            <h3>Type Sales</h3>
            <h3>{getDatePeriod()}</h3>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">#</TableCell>
                            <TableCell align="right">Ex VAT</TableCell>
                            <TableCell align="right">Average</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={0}>
                            <TableCell component="th" scope="row">
                                Services
                            </TableCell>
                            <TableCell align="right">{stats.services.quantity}</TableCell>
                            <TableCell align="right">{poundsFormatter.format(stats.services.total)}</TableCell>
                            <TableCell align="right">{poundsFormatter.format(stats.services.avg)}</TableCell>
                        </TableRow>
                        <TableRow key={1}>
                            <TableCell component="th" scope="row">
                                Products
                            </TableCell>
                            <TableCell align="right">{stats.products.quantity}</TableCell>
                            <TableCell align="right">{poundsFormatter.format(stats.products.total)}</TableCell>
                            <TableCell align="right">{poundsFormatter.format(stats.products.avg)}</TableCell>
                        </TableRow>
                        <TableRow key={2}>
                            <TableCell component="th" scope="row">
                                Courses
                            </TableCell>
                            <TableCell align="right">{stats.courses.quantity}</TableCell>
                            <TableCell align="right">{poundsFormatter.format(stats.courses.total)}</TableCell>
                            <TableCell align="right">{poundsFormatter.format(stats.courses.avg)}</TableCell>
                        </TableRow>
                        <TableRow key={3} className={classes.boldRow}>
                            <TableCell component="th" scope="row">
                                Total
                            </TableCell>
                            <TableCell align="right">{stats.grandTotal.quantity}</TableCell>
                            <TableCell align="right">{poundsFormatter.format(stats.grandTotal.total)}</TableCell>
                            <TableCell align="right">{poundsFormatter.format(stats.grandTotal.avg)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default withStyles(styles)(TypeSalesComponent);
