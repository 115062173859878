import flattenDeep from 'lodash/flattenDeep';
import reports from '../../constants/reports';

const getQueryFiltersByReport = report => {
    const reportList = flattenDeep(Object.values(reports).map(reportSection => Object.values(reportSection)));

    const reportProps = reportList.find(reportItem => reportItem.id === report);

    if (!reportProps || !Object.keys(reportProps.filters || {})?.length) return [];

    return ['from', 'to', ...Object.values(reportProps.filters), 'period'];
};

export default getQueryFiltersByReport;
