import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { filters } from './styles';
import getMissingFilters from '../../../utils/helpers/getMissingFilters';
import { useReportFilterItems } from '../../../hooks/reportFilterItems';

function SingleToxinFilter({ classes, label, handleFilterChange, filters, report }) {
    const { toxins } = useReportFilterItems();
    const [selectedItem, setSelectedItem] = useState(null);
    const [isFieldRequired, setIsFieldRequired] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const handleChange = item => {
        handleFilterChange({
            toxin: item?.name
        });
        setSelectedItem(item ?? null);
    };

    useEffect(() => {
        setIsFieldRequired(getMissingFilters(report, {}).includes('toxin'));
    }, [report]);

    useEffect(() => {
        if (!isFirstRender) return;
        if (toxins.length > 0) {
            const value = filters['toxin'];
            if (value) {
                handleChange(toxins.find(item => item.name === value));
            } else {
                handleChange();
            }
            setIsFirstRender(false);
        }
        // eslint-disable-next-line
    }, [filters, toxins]);

    return (
        <Box marginTop={4}>
            <Autocomplete
                className={`${classes.autocomplete} ${classes.filterMaxWidth}`}
                options={toxins}
                value={selectedItem}
                onChange={(_, item) => handleChange(item)}
                loading={!toxins.length}
                label={label}
                required={isFieldRequired}
                getOptionLabel={option => option.name}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        error={!selectedItem && isFieldRequired}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{`${option.name}`}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(SingleToxinFilter);
