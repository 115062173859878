import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { filters } from './styles';

function TypesFilter({ classes, label, handleFilterChange, filters }) {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            setItems([
                { label: 'Services', value: 'Service' },
                { label: 'Courses', value: 'Course' },
                { label: 'Products', value: 'Product' }
            ]);
            setLoading(false);
        };

        fetch();
    }, []);

    useEffect(() => {
        if (items.length > 0) {
            const value = filters['types'];
            if (value) {
                const splitted_values = value.split(',');
                setSelectedItems(splitted_values.map(sv => items.find(s => s.value === sv)));
            }
        }
    }, [filters, items]);

    const handleChange = (_, items) => {
        const _items = items.map(i => i.value).join(',');
        handleFilterChange({
            types: _items
        });
        setSelectedItems(items);
    };

    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={classes.autocomplete}
                options={items}
                value={selectedItems}
                onChange={handleChange}
                loading={loading}
                label={label}
                getOptionLabel={option => option.label}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{`${option.label}`}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(TypesFilter);
