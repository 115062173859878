import React from 'react';
import styled from 'styled-components';
import theme from '../../../../style/theme';
import { Pagination } from '@material-ui/lab';
import MuiTablePagination from '@material-ui/core/TablePagination';

export default function PaginationTable({
    page,
    count,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    countDivRows
}) {
    const TablePagination = styled(MuiTablePagination)`
        .MuiTablePagination-actions {
            display: none;
        }
        border-bottom: 0px;
        .MuiTablePagination-toolbar p {
            font-size: 0.75rem;
            font-weight: 400;
            text-align: center;
        }
        .MuiInputBase-input {
            padding: 0.3125rem 1.375rem 0.3125rem 0;
        }
    `;

    const PaginationRow = styled.div`
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    `;

    const CustomPagination = styled(Pagination)`
        .MuiPagination-ul li .Mui-selected {
            background-color: ${props => props.theme.palette.gray.bold};
            color: ${props => props.theme.palette.white.main};
        }
    `;

    return (
        <PaginationRow>
            <CustomPagination
                theme={theme}
                page={page}
                count={countDivRows}
                onChange={handleChangePage}
                siblingCount={2}
            />
            <TablePagination
                page={page}
                count={count}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelDisplayedRows={labelDisplayedRows}
            />
        </PaginationRow>
    );
}
