import React, { useMemo } from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers/index';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';

function Type({ values, query, filterBtn, loading }) {
    const filters = query;
    const table = useMemo(() => {
        return {
            columns: [
                { title: 'Type', field: 'type' },
                { title: '#', field: 'quantity' },
                {
                    title: 'List Price',
                    field: 'listPrice',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'listPrice')
                },
                {
                    title: 'Gross Discounts',
                    field: 'discount',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'discount')
                },
                {
                    title: 'Refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'Net Sales',
                    field: 'netSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netSales')
                },
                {
                    title: 'Ave Net Sales',
                    field: 'aveNetSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'aveNetSales')
                },
                {
                    title: 'Tax',
                    field: 'tax',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'tax')
                },
                {
                    title: 'Total Sales',
                    field: 'totalSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalSales')
                }
            ],
            data: values.map(value => {
                const { type, quantity, listPrice, discount, refunds, netSales, aveNetSales, tax, totalSales } = value;
                return {
                    type,
                    quantity,
                    listPrice: toLocaleString(listPrice || 0),
                    discount: toLocaleString(discount || 0),
                    refunds: toLocaleString(refunds || 0),
                    netSales: toLocaleString(netSales || 0),
                    aveNetSales: toLocaleString(aveNetSales || 0),
                    tax: toLocaleString(tax || 0),
                    totalSales: toLocaleString(totalSales || 0)
                };
            })
        };
    }, [values]);

    return (
        <DefaultMaterialTable
            title="Sales By Type"
            columns={table.columns}
            data={table.data}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            filters={filters}
        />
    );
}

export default Type;
