import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as tabs from './constants/tabs';

import Dashboard from './components/dashboard/Dashboard';
import Reports from './components/reports/Reports';
// import ScheduledReports from './components/scheduled_reports/ScheduledReports';
import * as Sentry from '@sentry/react';
import { useAuth } from './utils/hooks/common';
import GenericErrorBoundaryFallback from './collums-components/components/common/GenericErrorBoundaryFallback';

function Routes() {
    const { sentryDialogOptions } = useAuth();

    return (
        <Switch>
            <Sentry.ErrorBoundary
                fallback={<GenericErrorBoundaryFallback />}
                showDialog
                dialogOptions={sentryDialogOptions}
            >
                {/*<Route exact path={'/'} component={Dashboard} />*/}
                <Route exact path={`/${tabs.dashboard.value}`} component={Dashboard} />
                <Route path={`/${tabs.reports.value}/category/:category/report/:report`} component={Reports} />
                <Route exact path={`/${tabs.reports.value}`} component={Reports} />
                <Redirect from="/*" to={`/${tabs.dashboard.value}`} />
                {/*<Route exact path={`/${tabs.scheduledReports.value}`} component={ScheduledReports} />*/}
                {/*<Redirect exact from={`\`/${tabs.scheduledReports.value}\``} to={`/${tabs.reports.value}`} />*/}
            </Sentry.ErrorBoundary>
        </Switch>
    );
}

export default Routes;
