import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import { toLocaleString } from '../../../../../collums-components/helpers';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';

function Item({ values, query, filterBtn, loading }) {
    const filters = query;
    const history = useHistory(); //eslint-disable-line
    const table = useMemo(() => {
        return {
            columns: [
                {
                    title: 'Date/Time',
                    field: 'dateAndTime',
                    customSort: (a, b) => new Date(a.invoice.date).valueOf() - new Date(b.invoice.date).valueOf()
                },
                { title: 'Invoice', field: 'invoice' },
                { title: 'Item', field: 'item' },
                { title: 'Client', field: 'client' },
                { title: 'Sold By', field: 'soldBy' },
                { title: '#', field: 'quantity' },
                {
                    title: 'List Price',
                    field: 'listPrice',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'listPrice')
                },
                {
                    title: 'Gross Discounts',
                    field: 'discount',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'discount')
                },
                {
                    title: 'Refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'Net Sales',
                    field: 'netSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netSales')
                },
                {
                    title: 'Tax',
                    field: 'tax',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'tax')
                },
                {
                    title: 'Total Sales',
                    field: 'totalSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalSales')
                }
            ],
            data: values.map(value => {
                const {
                    item: {
                        name: itemName,
                        invoice: {
                            code,
                            date: dateAndTime,
                            customer: { firstName: customerName }
                        },
                        soldBy
                    },
                    quantity,
                    listPrice,
                    discount,
                    refunds,
                    tax,
                    netSales,
                    totalSales
                } = value;

                return {
                    dateAndTime: new Date(dateAndTime).toLocaleString(),
                    invoice: code,
                    item: itemName,
                    client: customerName,
                    soldBy: `${soldBy ? soldBy.firstName : ''} ${soldBy ? soldBy.surname : ''}`,
                    quantity,
                    listPrice: toLocaleString(listPrice),
                    discount: toLocaleString(discount || 0),
                    refunds: toLocaleString(refunds || 0),
                    netSales: toLocaleString(netSales || 0),
                    tax: toLocaleString(tax || 0),
                    totalSales: toLocaleString(totalSales || 0)
                };
            })
        };
    }, [values]);

    return (
        <>
            <DefaultMaterialTable
                loading={loading}
                title="Sales By Items"
                columns={table.columns}
                data={table.data}
                options={{ sorting: true }}
                filterBtn={filterBtn}
                filters={filters}
            />
        </>
    );
}

Item.propTypes = { values: PropTypes.array, query: PropTypes.object };

export default Item;
