import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';

const columns = [
    { title: 'Staff', field: 'practitioner' },
    { title: 'Location', field: 'locationName' },
    {
        title: 'Clients',
        field: 'clientsGroup',
        type: 'group',
        subColumns: [{ title: '#', field: 'totalClients', type: 'quantity' }],
        renders: [data => <span>{data.totalClients}</span>]
    },
    {
        title: 'Services',
        field: 'servicesGroup',
        type: 'group',
        subColumns: [
            { title: '#', field: 'servicesQuantity', type: 'quantity' },
            {
                title: 'Total',
                field: 'totalUnredemeedServices',
                customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'servicesValue'),
                type: 'currency'
            },
            {
                title: 'Commission',
                field: 'servicesCommission',
                type: 'currency'
            }
        ],
        renders: [
            data => <span>{data.servicesQuantity}</span>,
            data => (
                <span style={data.totalUnredemeedServices < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.totalUnredemeedServices)}
                </span>
            ),
            data => (
                <span style={data.servicesCommission < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.servicesCommission)}
                </span>
            )
        ]
    },
    {
        title: 'Courses Sold',
        field: 'coursesSoldGroup',
        type: 'group',
        subColumns: [
            { title: '#', field: 'coursesQuantity', type: 'quantity' },
            {
                title: 'Total',
                field: 'totalCourses',
                customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalCourses'),
                type: 'currency'
            },
            {
                title: 'Commission',
                field: 'coursesCommission',
                type: 'currency'
            }
        ],
        renders: [
            data => <span>{data.coursesQuantity}</span>,
            data => (
                <span style={data.totalCourses < 0 ? { color: 'red' } : {}}>{toLocaleString(data.totalCourses)}</span>
            ),
            data => (
                <span style={data.coursesCommission < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.coursesCommission)}
                </span>
            )
        ]
    },
    {
        title: 'Products',
        field: 'productsGroup',
        type: 'group',
        subColumns: [
            { title: '#', field: 'productsQuantity', type: 'quantity' },
            {
                title: 'Total',
                field: 'totalProducts',
                customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalProducts'),
                type: 'currency'
            },
            {
                title: 'Commission',
                field: 'productsCommission',
                type: 'currency'
            }
        ],
        renders: [
            data => <span>{data.productsQuantity}</span>,
            data => (
                <span style={data.totalProducts < 0 ? { color: 'red' } : {}}>{toLocaleString(data.totalProducts)}</span>
            ),
            data => (
                <span style={data.productsCommission < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.productsCommission)}
                </span>
            )
        ]
    },
    {
        title: 'Total Sales',
        field: 'totalSalesGroup',
        type: 'group',
        subColumns: [
            { title: '#', field: 'totalSaleQuantity', type: 'quantity' },
            {
                title: 'Total',
                field: 'totalSale',
                customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalSale'),
                type: 'currency'
            },
            {
                title: 'Commission',
                field: 'totalSalesCommission',
                type: 'currency'
            }
        ],
        renders: [
            data => <span>{data.totalSaleQuantity}</span>,
            data => <span style={data.totalSale < 0 ? { color: 'red' } : {}}>{toLocaleString(data.totalSale)}</span>,
            data => (
                <span style={data.totalSalesCommission < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.totalSalesCommission)}
                </span>
            )
        ]
    },
    {
        title: 'Courses Redemptions',
        field: 'coursesPerfGroup',
        type: 'group',
        subColumns: [
            { title: '#', field: 'redeemedQuantity', type: 'quantity' },
            {
                title: 'Total',
                field: 'totalRedeemed',
                customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'redeemedValue'),
                type: 'currency'
            },
            {
                title: 'Commission',
                field: 'coursesRedemptionCommission',
                type: 'currency'
            }
        ],
        renders: [
            data => <span>{data.redeemedQuantity}</span>,
            data => (
                <span style={data.redeemedValue < 0 ? { color: 'red' } : {}}>{toLocaleString(data.totalRedeemed)}</span>
            ),
            data => (
                <span style={data.coursesRedemptionCommission < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.coursesRedemptionCommission)}
                </span>
            )
        ]
    },
    {
        title: 'Sales + Redemption',
        field: 'salesPerfGroup',
        type: 'group',
        subColumns: [
            { title: '#', field: 'totalQuantity', type: 'quantity' },
            {
                title: 'Total',
                field: 'total',
                customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'total'),
                type: 'currency'
            },
            {
                title: 'Commission',
                field: 'commission',
                type: 'currency'
            }
        ],
        renders: [
            data => <span>{data.totalQuantity}</span>,
            data => <span style={data.total < 0 ? { color: 'red' } : {}}>{toLocaleString(data.total)}</span>,
            data => <span style={data.commission < 0 ? { color: 'red' } : {}}>{toLocaleString(data.commission)}</span>
        ]
    }
];

export default columns;
