module.exports = {
    FINANCIALS: { id: 'financials', label: 'Financials' },
    APPOINTMENTS: { id: 'appointments', label: 'Appointments' },
    GIFT_VOUCHERS: { id: 'gift', label: 'Gift Vouchers' },
    CLIENTS: { id: 'clients', label: 'Clients' },
    COMMISSION: { id: 'commission', label: 'Commission' },
    STOCK: { id: 'stock', label: 'Stock' }
    /*
    STAFF: { id: 'staff', label: 'Staff' },
    MARKETING: { id: 'marketing', label: 'Marketing' },
    OTHER: { id: 'other', label: 'Other' },
    STAFF_SALES: { id: 'staff-sales-and-commission-category', label: 'Staff Sales & Commission' }
    */
};
