import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableFooter from '@material-ui/core/TableFooter';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import styled from 'styled-components';
import { Checkbox, Typography, FormControlLabel } from '@material-ui/core';
import { sliceRows, getDisplayedRows } from './helpers/pagination';
import { getEmptyRows } from './helpers/table';
import SearchInput from './components/SearchInput';
import Loading from './components/Loading';
import { currencySorter, boolSorter, dateSorter, defaultSorter } from './helpers/sorting';
import TableInfo from './components/TableInfo';
import { toLocaleString, useDebounce } from '../../../collums-components/helpers';
import { isEqual } from './helpers/isEqual';
import { DropdownButton } from '../DropdownButton';
import { scrollAnimation } from '../../../utils/helpers/common';
import PaginationTable from './components/PaginationTable';
import WarningMessage from './components/WarningMessage.jsx';
import SelectAmountOfRows from './components/SelectAmountOfRows';
import { useHistory } from 'react-router-dom';
import exportCsv from './helpers/exportCSV';
import { map } from 'lodash';
import { standardizeTimeSize } from '../../../collums-constants/utils/index';
import reportsWithTotalRow from '../../../constants/reportsWithTotalRow';
import GrandTotalRows from '../GrandTotalRows';
import ReportFilterList from '../ReportFilterList';

function CollumsTable({
    columns,
    columnsExport,
    rows,
    customHead,
    isLoading,
    tableInfo,
    filterBtn,
    customLoadingComponent,
    setValues,
    canShowFilters = true,
    useGrandTotalRows,
    //eslint-disable-next-line
    contentTop,
    customHeadRow
}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [orderBy, setOrderBy] = useState();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [direction, setDirection] = useState('desc');
    const [searchValue, setSearchValue] = useState('');
    const [searchRows, setSearchRows] = useState([]);
    const [isUsingSearch, setIsUsingSearch] = useState(false);
    const [updatedData, setUpdatedData] = useState([]);
    const [totalRow, setTotalRow] = useState({});
    const debounce = useDebounce(searchValue, 300);
    const [redemptionValuesCheckbox, setRedemptionValuesCheckbox] = useState(false);
    const [rowsToShow, setRowsToShow] = useState(tableInfo?.title === 'Service Sales Performance' ? 10 : 'all');
    const grandTotalsRef = useRef([]);

    useEffect(() => {
        if (!searchValue) {
            setIsUsingSearch(false);
            setUpdatedData(rows);
        } else handleSearch();
        //eslint-disable-next-line
    }, [debounce]);

    const [showWarning, setShowWarning] = useState(false);
    const redirectTo = useRef();
    const canRedirect = useRef(false);
    const history = useHistory();

    const previousFilters = useRef(tableInfo?.filters || {});

    const emptyRows = getEmptyRows(isUsingSearch ? searchRows || [] : rows || [], page, rowsPerPage);

    const isRightAligned = useCallback(item => {
        const rightAligned = ['currency', 'percentage', 'quantity', 'hoursAndMinutes', 'number'];
        return rightAligned.includes(item);
    }, []);

    const haveGroup = useMemo(() => columns && columns.some(el => el.type === 'group'), [columns]);

    useEffect(() => {
        scrollAnimation();
        //eslint-disable-next-line
    }, [rowsPerPage]);

    useEffect(() => {
        if (rows?.length && tableInfo?.title === 'Outstanding Courses' && isFirstRender) {
            handleSort('courseName', undefined);
            setIsFirstRender(false);
        } else {
            setUpdatedData(rows);
        }

        if (isUsingSearch) {
            handleSearch();
        } else setSearchRows([]);
        //eslint-disable-next-line
    }, [rows]);

    useEffect(() => {
        if (tableInfo.filters) {
            if (!isEqual(tableInfo.filters, previousFilters.current)) {
                setPage(0);
            }
        }
        previousFilters.current = tableInfo.filters || {};
    }, [tableInfo]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (id, type) => {
        let orderedRows = [...rows];
        let backupHiddenPart = [];
        if (rowsToShow !== 'all') {
            backupHiddenPart = orderedRows.slice(rowsToShow);
            orderedRows = orderedRows.slice(0, rowsToShow);
        }
        switch (type) {
            case 'currency':
            case 'percentage':
            case 'quantity':
                orderedRows = orderedRows.sort((a, b) => {
                    const objA = { ...a };
                    const objB = { ...b };
                    return currencySorter(objA, objB, direction, id);
                });
                break;
            case 'date':
                orderedRows = orderedRows.sort((a, b) => {
                    const objA = { ...a };
                    const objB = { ...b };
                    return dateSorter(objA, objB, direction, id);
                });
                break;
            case 'bool':
                orderedRows = orderedRows.sort((a, b) => {
                    const objA = { ...a };
                    const objB = { ...b };
                    return boolSorter(objA, objB, direction, id);
                });
                break;
            default:
                if (['createdAt', 'paymentDate'].includes(id)) {
                    orderedRows = orderedRows.sort((a, b) => {
                        const objA = { ...a };
                        const objB = { ...b };
                        return dateSorter(objA, objB, direction, id);
                    });
                } else {
                    orderedRows = orderedRows.sort((a, b) => {
                        const objA = { ...a };
                        const objB = { ...b };
                        return defaultSorter(objA, objB, direction, id);
                    });
                }
                break;
        }

        const newRowsOrder = [
            ...orderedRows.map(el => {
                return rows.find(row => row.id === el.id);
            }),
            ...backupHiddenPart
        ];

        setDirection(direction === 'asc' ? 'desc' : 'asc');
        setOrderBy(id);

        if (setValues) {
            setValues(newRowsOrder);
            if (isUsingSearch) handleSearch(newRowsOrder);
        } else {
            rows = newRowsOrder;
        }

        setUpdatedData(newRowsOrder);
    };

    const handleSearch = (_ = rows) => {
        if (!_) return;
        if (!searchValue) {
            setIsUsingSearch(false);
            setUpdatedData(_);
            return;
        }

        const _searchedValues = _.filter(row => {
            const check = columns
                .filter(el => !el.hide)
                .filter(col => {
                    const collumnValue = (() => {
                        if (typeof row[col.field] === 'number' && col.type === 'currency') {
                            return toLocaleString(row[col.field]);
                        }
                        return row[col.field] || '';
                    })();
                    return collumnValue
                        .toString()
                        .toLowerCase()
                        .includes((searchValue || '').toLowerCase());
                });

            if (check.length > 0) return true;

            return false;
        });

        setIsUsingSearch(true);
        setSearchRows(_searchedValues);
        setUpdatedData(_searchedValues);
        setPage(0);
    };

    const dataRows = isUsingSearch ? searchRows : rows;

    const generateRows = useMemo(() => {
        const displayedRows = sliceRows(
            dataRows || [],
            rowsToShow === 'all' ? rowsPerPage : rowsToShow,
            rowsToShow === 'all' ? (page === 0 ? page : page - 1) : 0
        );
        const _rows = displayedRows.map((row, i) => {
            const cells = columns
                .filter(
                    el =>
                        !el.hide &&
                        (redemptionValuesCheckbox ||
                            !['redemptionValue', 'grossRedemption', 'isCourseRedemption'].includes(el.field))
                )
                .map((col, j) => {
                    let value = row[col.field];

                    if (col.Component) {
                        value = col.Component(row);
                    }

                    if (col.render) {
                        value = col.render(row);
                    }

                    if (col.type === 'group') {
                        return col.renders.map((render, index) => {
                            value = render(row);
                            const alignment = (() => {
                                if (col.subColumns.length === 1) {
                                    if (isRightAligned(col.subColumns[index].type)) {
                                        return 'right';
                                    } else {
                                        return 'left';
                                    }
                                } else if (index === 1) {
                                    return 'right';
                                } else if (isRightAligned(col.subColumns[index].type)) {
                                    return 'right';
                                } else {
                                    return 'left';
                                }
                            })();
                            return (
                                <TableCellBody
                                    key={`col_${j}_${col.field}_${index}`}
                                    align={alignment}
                                    style={(() => {
                                        if (index === 0) {
                                            return {
                                                borderLeft: '1px solid rgba(224, 224, 224, 1)'
                                            };
                                        }
                                        if (index === col.subColumns.length - 1) {
                                            return {
                                                borderRight: '1px solid rgba(224, 224, 224, 1)'
                                            };
                                        }
                                        return {};
                                    })()}
                                >
                                    {value}
                                </TableCellBody>
                            );
                        });
                    } else {
                        return (
                            <TableCellBody
                                key={`col_${j}_${col.field}`}
                                align={(() => {
                                    if (isRightAligned(col.type)) {
                                        return 'right';
                                    }
                                    return 'left';
                                })()}
                                style={{
                                    minWidth: col.type === 'longText' ? '200px' : '',
                                    maxWidth: col.type === 'text' ? '150px' : ''
                                }}
                            >
                                {value}
                            </TableCellBody>
                        );
                    }
                });

            return <TableRow key={`row_${i}`}>{cells}</TableRow>;
        });
        return _rows;
        //eslint-disable-next-line
    }, [
        page,
        rowsPerPage,
        orderBy,
        searchRows,
        searchValue,
        isUsingSearch,
        updatedData,
        redemptionValuesCheckbox,
        rowsToShow
    ]);

    const count = dataRows ? dataRows.length : 0;
    const loadingComponent = customLoadingComponent || <Loading />;
    const ref = useRef();

    const releaseAction = () => {
        setShowWarning(true);
    };

    const leaveAction = () => {
        setShowWarning(false);
        canRedirect.current = true;
        if (redirectTo.current) {
            const name = redirectTo.current?.innerText?.toLowerCase();
            if (['scheduled reports', 'reports'].includes(name)) {
                if ('scheduled reports' === name) {
                    history.push('/scheduled-reports');
                } else {
                    history.push('/reports');
                }
            } else {
                try {
                    redirectTo.current.click();
                } catch (err) {
                    return;
                }
            }
        }
    };

    const handleClickOutside = event => {
        if (event.target?.id === 'confirm-leave-button') return;

        if (event.target?.download) return;

        const isFromSearchButton = (event.path || []).some(el => el?.id === 'searchIcon');

        if (isFromSearchButton) return;

        const tagName = event.target?.tagName?.toLowerCase();
        if (tagName !== 'a' && tagName !== 'img') return;

        if (canRedirect.current) return;

        if (ref.current && !ref.current.contains(event.target) && count) {
            redirectTo.current = event.target;
            event.stopPropagation();
            event.preventDefault();
            if (event.target.attributes['target']?.value && event.target.attributes['target']?.value === '_blank') {
                leaveAction();
            } else {
                releaseAction();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const TotalRow = useMemo(() => {
        if (!reportsWithTotalRow.includes((tableInfo || {}).title)) return null;
        const data = [...(isUsingSearch ? searchRows : rows)];

        const content = rowsToShow === 'all' ? data : data.splice(0, rowsToShow);

        if (!content || !content?.length) return null;
        const totalObject = {};
        const cells = columns.map((col, i) => {
            const totalCellStyle = { font: 14, fontWeight: 500 };

            // function to get cell value
            const getCell = (column, index) => {
                let value;
                let customCalculated = false;
                if (column?.customTotalCalculation && typeof column?.customTotalCalculation === 'function') {
                    value = column.customTotalCalculation(content, column);
                    customCalculated = true;
                } else {
                    if (column.type === 'currency') {
                        if (column.total === 'average') {
                            const sumOfItems = content.reduce((counter, row) => {
                                const newValue = row[column.field] || 0;
                                return counter + newValue;
                            }, 0);
                            if (sumOfItems < 0) totalCellStyle.color = 'red';

                            totalObject[column.field] = value;
                            value = toLocaleString(sumOfItems / content.length);
                        } else {
                            const sumOfItems = content.reduce((counter, row) => {
                                const newValue = row[column.field] || 0;
                                return counter + newValue;
                            }, 0);
                            if (sumOfItems < 0) totalCellStyle.color = 'red';
                            if (sumOfItems !== 0 && column.field === 'outstanding') totalCellStyle.color = 'red';
                            totalObject[column.field] = value;
                            value = toLocaleString(sumOfItems);
                        }
                    }

                    if (column.type === 'quantity') {
                        const sumOfItems = content.reduce((counter, row) => {
                            const newValue = row[column.field];
                            return counter + newValue;
                        }, 0);
                        if (sumOfItems < 0) totalCellStyle.color = 'red';
                        if (sumOfItems !== 0 && column.field === 'outstanding') totalCellStyle.color = 'red';
                        value = sumOfItems;
                    }

                    if (column.type === 'unique') {
                        const nameSet = new Set();
                        content.forEach(el => {
                            if (el[column.field]) {
                                nameSet.add(el[column.field]);
                            }
                        });
                        const array = Array.from(nameSet);
                        value = array.length;
                    }

                    if (col.type === 'percentage') {
                        value = '100.0%';
                    }

                    if (col.field === 'code') {
                        value = content.length;
                    }

                    if (col.type === 'hoursAndMinutes') {
                        const [hours, minutes] = content.reduce(
                            (acc, item) => {
                                const splited = item[col.field]?.split(':');

                                if (!splited) return acc;

                                return [acc[0] + parseInt(splited[0]), acc[1] + parseInt(splited[1])];
                            },
                            [0, 0]
                        );

                        value = `${standardizeTimeSize(hours + parseInt(minutes / 60))}:${standardizeTimeSize(
                            minutes % 60
                        )}`;
                    }

                    if (index === 0 && column.type !== 'currency' && column.field !== 'code') {
                        if (col.type === 'grandTotal') {
                            value = 'Grand Total';
                        } else if (col.type === 'rows') {
                            value = 'Total ' + content.length;
                        } else {
                            value = 'Total';
                        }
                    }
                }

                if (column.field !== 'currency' || customCalculated) {
                    totalObject[column.field] = value;
                }

                if (column.hide) return null;

                if (
                    col.hide ||
                    (!redemptionValuesCheckbox &&
                        ['redemptionValue', 'grossRedemption', 'isCourseRedemption'].includes(col.field))
                )
                    return null;

                const alignment = (() => {
                    if (col.type === 'group') {
                        if (index === i) {
                            return 'left';
                        } else {
                            return 'right';
                        }
                    } else if (isRightAligned(col.type)) {
                        return 'right';
                    } else {
                        return 'left';
                    }
                })();

                const style = (() => {
                    if (col.type === 'group') {
                        if (index === i) {
                            return { borderLeft: '1px solid rgba(224, 224, 224, 1)', ...totalCellStyle };
                        } else if (index === columns.length + 1) {
                            return { borderRight: '1px solid rgba(224, 224, 224, 1)', ...totalCellStyle };
                        } else {
                            return totalCellStyle;
                        }
                    } else if (typeof value === 'number') {
                        return { paddingLeft: '2.5rem', ...totalCellStyle };
                    } else if (index === 0 && haveGroup) {
                        return { borderRight: '1px solid rgba(224, 224, 224, 1)', ...totalCellStyle };
                    } else {
                        return { minWidth: '100px', ...totalCellStyle };
                    }
                })();

                return (
                    <TableCellBody key={`col_${column.field}`} style={style} align={alignment}>
                        {value}
                    </TableCellBody>
                );
            };

            if (col.type === 'group') {
                if (col.subColumns.length === 1) return getCell(col.subColumns[0], i + 1);
                return col.subColumns.map((c, localIndex) => getCell(c, localIndex + i));
            } else {
                return getCell(col, i);
            }
        });
        setTotalRow(totalObject);
        return <TableRow key={'rows_total'}>{cells}</TableRow>;
        //eslint-disable-next-line
    }, [rows, searchRows, isUsingSearch, redemptionValuesCheckbox, rowsToShow]);

    const getFormattedData = () => {
        if (!updatedData) return;
        const totalObject = Object.keys(totalRow).length ? [totalRow, ...(grandTotalsRef.current || [])] : [];

        const data = map([...totalObject, ...updatedData], row => {
            const formattedValues = Object.keys(row).map(name => {
                if (typeof row[name] !== 'number') return row[name];

                const column = columns.find(
                    col => col.field === name || (col.subColumns && col.subColumns.find(sub => sub.field === name))
                );

                if (!column) return toLocaleString(row[name]);

                if (column.type === 'group') {
                    const subColumn = column.subColumns.find(sub => sub.field === name);

                    if (!subColumn) return row[name];
                    if (subColumn.type === 'currency') return toLocaleString(row[subColumn.field]);
                    return row[subColumn.field];
                }

                if (column.type === 'currency') return toLocaleString(row[name]);
                return row[name];
            });
            const newRow = { ...row };
            Object.keys(newRow).forEach((name, index) => {
                newRow[name] = formattedValues[index];
            });

            if (
                !Object.prototype.hasOwnProperty.call(newRow, 'redemptionValue') ||
                !Object.prototype.hasOwnProperty.call(newRow, 'grossRedemption')
            ) {
                newRow.redemptionValue = toLocaleString(0);
                newRow.grossRedemption = toLocaleString(0);
            }

            return newRow;
        });
        return data;
    };

    const handleCheckboxChange = e => {
        setRedemptionValuesCheckbox(e.target.checked);
    };

    return (
        <div ref={ref}>
            <TableContainer>
                <AdditionalHeadRow>
                    <ReportFilterList openFilterModal={filterBtn.onClick} runReport={filterBtn.runReport}>
                        <DropdownButton
                            label={'Options'}
                            options={[
                                {
                                    label: 'Export as CSV',
                                    handleClick: () => {
                                        exportCsv(
                                            getFormattedData(updatedData),
                                            tableInfo,
                                            canShowFilters,
                                            columnsExport
                                        );
                                    }
                                }
                            ]}
                        />
                    </ReportFilterList>
                    <SearchRow>
                        <SearchInput
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            onSearch={() => handleSearch()}
                            onCancel={() => {
                                setIsUsingSearch(false);
                                setSearchValue('');
                            }}
                        />
                        {customHead}
                    </SearchRow>
                    <CustomHeadRowContainer>{customHeadRow}</CustomHeadRowContainer>
                </AdditionalHeadRow>
                {tableInfo.filters && (
                    <TableInfoWrapper>
                        <TableInfo {...tableInfo} />
                        {tableInfo.redemptionValuesCheckbox && (
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={redemptionValuesCheckbox}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label="Show redemption values"
                                />
                            </div>
                        )}
                        {tableInfo.showSelectAmountOfRows && (
                            <SelectAmountOfRows rowsToShow={rowsToShow} setRowsToShow={setRowsToShow} />
                        )}
                    </TableInfoWrapper>
                )}
                {/* contentTop */}
                <Table aria-label="collums table" id="collums-table" size="large">
                    <MuiTableHead>
                        <TableRow>
                            {columns &&
                                columns
                                    .filter(
                                        el =>
                                            !el.hide &&
                                            (redemptionValuesCheckbox ||
                                                !['redemptionValue', 'grossRedemption', 'isCourseRedemption'].includes(
                                                    el.field
                                                ))
                                    )
                                    .map(col => {
                                        return col.type === 'group' ? (
                                            <MuiTableCell
                                                colSpan={col.subColumns.length}
                                                style={{
                                                    borderBottom: 0,
                                                    paddingBottom: 0,
                                                    paddingTop: 0,
                                                    border: '1px solid rgb(224, 224, 224)'
                                                }}
                                                key={col.field}
                                                align="center"
                                            >
                                                <Typography style={{ fontSize: 14, fontWeight: '500' }}>
                                                    {col.title}
                                                </Typography>
                                            </MuiTableCell>
                                        ) : (
                                            <MuiTableCell
                                                style={haveGroup ? { borderBottom: 0, paddingBottom: 0 } : {}}
                                                key={col.field}
                                                align={(() => {
                                                    if (isRightAligned(col.type)) {
                                                        return 'right';
                                                    }
                                                    return 'left';
                                                })()}
                                            >
                                                {!haveGroup && (
                                                    <TableSortLabel
                                                        active={orderBy === col.field}
                                                        direction={orderBy === col.field ? direction : 'asc'}
                                                        onClick={() => handleSort(col.field, col.type)}
                                                        hideSortIcon={orderBy !== col.field}
                                                    >
                                                        <Typography style={{ fontSize: 14, fontWeight: '500' }}>
                                                            {col.title}
                                                        </Typography>
                                                    </TableSortLabel>
                                                )}
                                            </MuiTableCell>
                                        );
                                    })}
                        </TableRow>
                        {haveGroup && (
                            <TableRow>
                                {columns.map(col => {
                                    if (col.type !== 'group') {
                                        return (
                                            <MuiTableCell
                                                style={{ paddingTop: 0, borderTop: '1px solid rgb(224, 224, 224)' }}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === col.field}
                                                    direction={orderBy === col.field ? direction : 'asc'}
                                                    onClick={() => handleSort(col.field, col.type)}
                                                    hideSortIcon={orderBy !== col.field}
                                                >
                                                    <Typography style={{ fontSize: 14, fontWeight: '500' }}>
                                                        {col.title}
                                                    </Typography>
                                                </TableSortLabel>
                                            </MuiTableCell>
                                        );
                                    }
                                    return col.subColumns.map((subCol, index) => {
                                        const alignment = (() => {
                                            if (col.subColumns.length === 1) {
                                                return isRightAligned(subCol.type) ? 'right' : 'left';
                                            } else if (isRightAligned(subCol.type)) {
                                                return 'right';
                                            } else {
                                                return 'left';
                                            }
                                        })();
                                        return (
                                            <MuiTableCell
                                                style={(() => {
                                                    if (index === 0) {
                                                        return {
                                                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                            paddingTop: 0
                                                        };
                                                    }
                                                    if (index === col.subColumns.length - 1) {
                                                        return {
                                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                            paddingTop: 0
                                                        };
                                                    }
                                                    return { paddingTop: 0 };
                                                })()}
                                                key={subCol.field}
                                                align={alignment}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === subCol.field}
                                                    direction={orderBy === subCol.field ? direction : 'asc'}
                                                    onClick={() => handleSort(subCol.field, subCol.type)}
                                                    hideSortIcon={orderBy !== subCol.field}
                                                >
                                                    <Typography style={{ fontSize: 14, fontWeight: '500' }}>
                                                        {subCol.title}
                                                    </Typography>
                                                </TableSortLabel>
                                            </MuiTableCell>
                                        );
                                    });
                                })}
                            </TableRow>
                        )}
                    </MuiTableHead>
                    {isLoading && loadingComponent}
                    <TableBody>
                        {TotalRow}
                        {Boolean(useGrandTotalRows) && (
                            <GrandTotalRows
                                data={isUsingSearch ? searchRows : rows}
                                columns={columns}
                                grandTotalsRef={grandTotalsRef}
                                allData={rows}
                            />
                        )}
                        {generateRows}
                    </TableBody>
                    <MuiTableFooter></MuiTableFooter>
                </Table>
            </TableContainer>
            {columns && !dataRows?.length && (
                <NoDataToDisplay style={{ height: 33 * emptyRows }}>No data to display</NoDataToDisplay>
            )}
            {!!rows?.length && rowsToShow === 'all' && (
                <PaginationTable
                    page={page === 0 ? page + 1 : page}
                    countDivRows={Math.ceil(count / rowsPerPage)}
                    handleChangePage={handleChangePage}
                    count={count}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    labelDisplayedRows={() => getDisplayedRows(page, count, rowsPerPage)}
                />
            )}
            {showWarning && <WarningMessage leaveAction={leaveAction} setShowWarning={setShowWarning} />}
        </div>
    );
}

const Table = styled(MuiTable)`
    position: relative;
    min-height: 100px;
    height: 100px;
`;

const TableBody = styled(MuiTableBody)`
    width: 100%;
    min-width: 650px;
`;
const TableRow = styled(MuiTableRow)`
    width: 100%;
    height: 53px;
    max-height: 53px;
`;
const TableCellBody = styled(MuiTableCell)`
    font-size: 14px;
    font-weight: 400;
`;

const AdditionalHeadRow = styled(TableRow)`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    height: auto;
    max-height: 165px;
`;

const SearchRow = styled.div`
    display: flex;
    width: 21.875rem;
`;

const TableInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -8px;
`;

const NoDataToDisplay = styled(TableRow)`
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

const CustomHeadRowContainer = styled.div`
    width: 100%;
`;

export { CollumsTable };
