export const InvoiceModalStyles = theme => ({
    invoiceDetails: {
        '& p': {
            textAlign: 'start',
            display: 'flex',
            gap: '4px',
            '& span': {
                textAlign: 'end',
                flex: 1
            }
        }
    },
    optionsMenu: {
        '& li': {
            fontSize: '14px'
        }
    },
    dialog: {
        '& .MuiTypography-root': {
            fontSize: 14
        },
        '& .MuiPaper-root': {
            maxWidth: 700,
            maxHeight: 700
        }
    },
    dialogLarge: {
        '& .MuiTypography-root': {
            fontSize: 14
        },
        '& .MuiPaper-root': {
            width: 1200,
            maxHeight: 700
        }
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    title: {
        width: '100%',
        textAlign: 'center'
    },
    marginTop8px: {
        marginTop: 8
    },
    boldFont: {
        fontWeight: 500
    },
    fontRed: {
        color: 'red'
    },
    fontSize18px: {
        fontSize: 18
    },
    paymentStatus: {
        fontSize: 14,
        textTransform: 'uppercase'
    },
    fontSize14px: {
        fontSize: 14
    },
    textsPopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 5,
        fontSize: 18
    },
    dialogContent: {
        overflowY: 'scroll',
        paddingTop: 0,
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    box: {
        marginBottom: '15px'
    },
    tableRow: {
        cursor: 'auto'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    tableBodyCell: {
        borderColor: 'black',
        opacity: 1,
        fontSize: 14,
        height: 50,
        '&> span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box'
        },
        '&.editable': {
            cursor: 'pointer',
            color: '#2b78e4'
        }
    },
    tablePagination: {
        textTransform: 'uppercase'
    },
    button: {
        background: '#B41678',
        textTransform: 'none',
        fontSize: '14px',
        color: 'white',
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
            background: '#B41678',
            boxShadow: 'none'
        }
    },
    buttonsBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        '& > button': {
            height: '44px',
            padding: '6px 14px',
            borderRadius: 0,
            fontSize: '12px'
        },
        padding: '12px',
        borderTop: '1px solid #0000001f'
    },
    cancelBtn: {
        background: 'white',
        textTransform: 'none',
        fontSize: '14px',
        boxShadow: 'none',
        color: '#575A64',
        borderColor: '#575A64',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    },
    notesInput: {
        width: '100%',
        height: '75px',
        '& > div': {
            height: 'inherit !important',
            padding: '13.5px 14px !important'
        },
        '& textarea': {
            height: 'inherit',
            lineHeight: '1.1rem',
            boxSizing: 'content-box'
        }
    },
    modal: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto'
    },
    paperPopup: {
        width: '20%',
        height: 'auto',
        padding: 7
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    saveButton: {
        margin: '1%',
        color: 'white',
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover,
            boxShadow: 'none'
        },
        boxShadow: 'none',
        borderRadius: 0
    },
    cancelButton: {
        margin: '1%',
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            boxShadow: 'none'
        },
        boxShadow: 'none',
        borderRadius: 0
    },
    bodyFooter: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    staffSelection: {
        '& .MuiOutlinedInput-root': {
            fontSize: 12,
            height: 'auto'
        }
    },
    printContainer: {
        margin: '50px 100px'
    },
    editableCellWrapper: {
        display: 'flex',
        justifyContent: 'end',

        '& > .MuiIconButton-root': {
            margin: '.4rem 0'
        }
    },
    editableCellWrapperLeft: {
        justifyContent: 'start'
    },
    editCellAutocomplete: {
        '& .MuiInputBase-root': {
            paddingRight: '0 !important'
        },
        '& .MuiInputBase-input': {
            maxHeight: '40px',
            padding: '10px 4px'
        }
    },
    editCellInput: {
        marginRight: 4,
        '&.small': {
            // width: 80
        },
        '& .MuiInputBase-root': {
            margin: 'auto',
            height: '40px'
        },
        '& input': {
            textAlignLast: 'center'
        },
        '& fieldset': {
            borderColor: theme.palette.black.full
        }
    },
    paymentLinkAmountWrapper: {
        margin: '20px auto 20px'
    },
    paymentLinkOptionWrapper: {
        marginTop: '10px',
        flexDirection: 'column',
        alignItems: 'center'
    },
    paymentLinkMessage: {
        fontSize: '15px',
        margin: '21px 5px 10px'
    },
    paymentLinkButtons: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginTop: '30px'
    }
});
