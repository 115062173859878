import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

const AccountPaymentsFilter = ({ handleFilterChange, filters, label, property }) => {
    const getIsChecked = () => {
        return filters?.[property];
    };

    const handleChange = e => {
        const data = {};
        data[property] = e.target.checked || undefined;
        handleFilterChange(data);
    };

    return (
        <Box marginTop={4} style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel control={<Checkbox checked={getIsChecked()} onClick={handleChange} />} label={label} />
        </Box>
    );
};

export default AccountPaymentsFilter;
