import React from 'react';
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiFormControl from '@material-ui/core/FormControl';
import { SearchOutlined, ClearOutlined } from '@material-ui/icons';
import { IconButton, OutlinedInput } from '@material-ui/core';

function SearchInput({ value, onChange, onSearch, onCancel }) {
    return (
        <FormControl>
            <OutlinedInput
                value={value}
                onChange={onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            style={{ marginRight: 0, padding: '0.25rem' }}
                            onClick={onCancel}
                            disabled={!value}
                        >
                            <ClearOutlined style={!value ? { color: 'transparent' } : {}} />
                        </IconButton>
                        <IconButton
                            aria-label="toggle password visibility"
                            style={{ marginLeft: 0, padding: '0.25rem' }}
                            onClick={onSearch}
                        >
                            <SearchOutlined />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}

const FormControl = styled(MuiFormControl)`
    margin-right: 1rem;
`;

export default SearchInput;
