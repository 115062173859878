import React, { useMemo } from 'react';
import _ from 'lodash';
import MaterialTable from 'material-table';
import TableInfo from '../../../../common/CollumsTable/components/TableInfo';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import { toLocaleString } from '../../../../../collums-components/helpers';

const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
};

function Month({ values, query }) {
    const filters = useMemo(() => _.keys(query).map(key => `${key}: ${query[key]}`), [query]);
    const table = useMemo(() => {
        return {
            columns: [
                {
                    title: 'Month',
                    field: 'month',
                    customSort: (a, b) =>
                        Object.keys(months).find(key => months[key] === a.month) -
                        Object.keys(months).find(key => months[key] === b.month)
                },
                { title: '#', field: 'quantity' },
                {
                    title: 'List price',
                    field: 'listPrice',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'listPrice')
                },
                {
                    title: 'refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'Ave Net Sales',
                    field: 'aveNetSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'aveNetSales')
                },
                {
                    title: 'Discounts',
                    field: 'discount',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'discount')
                },
                {
                    title: 'Refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'Net Sales',
                    field: 'netSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netSales')
                },
                {
                    title: 'Tax',
                    field: 'tax',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netSales')
                },
                {
                    title: 'Total Sales',
                    field: 'totalSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalSales')
                }
            ],
            data: values.map(value => {
                const { month, quantity, totalSales, listPrice, discount, refunds, tax, aveNetSales, netSales } = value;

                return {
                    month,
                    quantity,
                    listPrice: toLocaleString(listPrice),
                    discount: toLocaleString(discount || 0),
                    refunds: toLocaleString(refunds || 0),
                    netSales: toLocaleString(netSales || 0),
                    aveNetSales: toLocaleString(aveNetSales || 0),
                    tax: toLocaleString(tax || 0),
                    totalSales: toLocaleString(totalSales || 0)
                };
            })
        };
    }, [values]);
    return (
        <>
            <MaterialTable
                title={<TableInfo type="Month" filters={filters} />}
                columns={table.columns}
                data={table.data}
                options={{ sorting: true }}
            />
        </>
    );
}

export default Month;
