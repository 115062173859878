import React, { useMemo } from 'react';
import { formatCurrencyFromValue } from '../../../../../utils/helpers/common';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import { toLocaleString } from '../../../../../collums-components/helpers';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';

function Client({ values, query, filterBtn, loading }) {
    const filters = query;
    const table = useMemo(() => {
        return {
            columns: [
                { title: 'Client', field: 'client' },
                { title: '#', field: 'quantity' },
                {
                    title: 'aveNetSales',
                    field: 'aveNetSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'aveNetSales')
                },
                {
                    title: 'Gross Discounts',
                    field: 'discount',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'discount')
                },
                {
                    title: 'Refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'Net Sales',
                    field: 'netSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netSales')
                },
                {
                    title: 'Tax',
                    field: 'tax',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'tax')
                },
                {
                    title: 'Total Sales',
                    field: 'totalSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalSales')
                }
            ],
            data: values.map(value => {
                const {
                    customer: client,
                    quantity,
                    totalSales,
                    listPrice,
                    discount,
                    refunds,
                    tax,
                    aveNetSales,
                    netSales
                } = value;

                return {
                    client: `${client.firstName} ${client.surname}`,
                    quantity,
                    listPrice: `${formatCurrencyFromValue(listPrice)}`,
                    discount: toLocaleString(discount || 0),
                    refunds: toLocaleString(refunds || 0),
                    netSales: toLocaleString(netSales || 0),
                    aveNetSales: toLocaleString(aveNetSales || 0),
                    tax: toLocaleString(tax || 0),
                    totalSales: toLocaleString(totalSales || 0)
                };
            })
        };
    }, [values]);
    return (
        <>
            <DefaultMaterialTable
                title="Sales By Client"
                columns={table.columns}
                data={table.data}
                options={{ sorting: true }}
                filterBtn={filterBtn}
                loading={loading}
                filters={filters}
            />
        </>
    );
}

export default Client;
