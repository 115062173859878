export const isEqual = (obj1, obj2) => {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;
    return (
        Object.keys(obj1).every(el => {
            return obj1[el] === obj2[el];
        }) &&
        Object.keys(obj2).every(el => {
            return obj1[el] === obj2[el];
        })
    );
};
