const reportsWithSingleLocationFilter = [
    'toxin-usage-summary',
    'toxin-usage-by-area',
    'toxin-usage-by-staff',
    'toxin-usage-log',
    'appointment-log-and-slippage',
    'consultation-conversion-log',
    'upsell-log',
    'payment-log'
];

export default reportsWithSingleLocationFilter;
