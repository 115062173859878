import querystring from 'querystring';
import _ from 'lodash';
import moment from 'moment-timezone';

export const getQueryParams = location => querystring.parse(location.search.substring(1, location.search.length));
export const generateQueryString = object =>
    Object.keys(object)
        .map(key => `${key}=${object[key]}`)
        .join('&');
export const formatCurrencySpaceSeparators = text => {
    const parts = text.split('.');
    const formatRecursively = text => {
        if (3 < text.length) {
            return formatRecursively(text.substring(0, text.length - 3)) + ' ' + text.substring(text.length - 3);
        } else {
            return text;
        }
    };
    return formatRecursively(parts[0]) + (2 === parts.length ? '.' : '') + (parts[1] ? parts[1] : '');
};
export const formatCurrencyFromValue = value =>
    formatCurrencySpaceSeparators(
        `${_.floor(value)}.${_.padStart(_.floor(_.round(100 * (value - _.floor(value)))), 2, '0')}`
    );

export const calculateNetPrice = (listPrice, discount) => listPrice - (discount || 0);

export const calculateTotalPrice = (netPrice, taxValue) => netPrice + (taxValue || 0);

export const calculateTaxValue = (listPrice, discount, tax) => calculateNetPrice(listPrice, discount) * (tax / 100);

export const dataURLtoFile = (dataurl, filename) => {
    const bstr = atob(dataurl);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: 'text/plain' });
};

export const printFilters = filters => {
    delete filters[''];
    return Object.keys(filters).map((item, index) => {
        return `${index !== 0 ? ', ' : ''} ${capitalize(item.replace(/([A-Z])/g, ' $1'))}`;
    });
};

export const capitalize = word => {
    return `${word.charAt(0).toUpperCase() + word.slice(1)}`;
};

export const clearHoursAndMinutes = (date, isEndOfDay) => {
    const time = moment(date);
    if (isEndOfDay) {
        return time.utc(true).endOf('day').toISOString();
    }
    return time.utc(true).startOf('day').toISOString();
};

export const scrollAnimation = () => {
    const element = document.querySelector('.content-width');
    if (element) element.scrollTo(0, 0);
};
