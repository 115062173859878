import Axios from './config';

class XeroApi {
    static async deleteInvoice(id) {
        return Axios({
            method: 'delete',
            url: `/xero/invoice/${id}`
        })
            .then(res => res.data)
            .catch(console.error);
    }

    static async sendToXero(id) {
        return Axios({
            method: 'GET',
            url: `/xero/propagate/${id}?force=true`
        }).then(res => res.data);
    }

    static isXeroActive() {
        return Axios({
            method: 'GET',
            url: '/xero/is-connected'
        });
    }
}

export default XeroApi;
