import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

const ShowVoidedFilter = ({ handleFilterChange, filters }) => {
    const getIsChecked = () => {
        return filters?.includeVoided;
    };

    const handleChange = e => {
        handleFilterChange({
            includeVoided: e.target.checked
        });
    };

    return (
        <Box marginTop={4} style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
                control={<Checkbox checked={getIsChecked()} onClick={handleChange} />}
                label={'Show voided invoices'}
            />
        </Box>
    );
};

export default ShowVoidedFilter;
