import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import WarningMessage from '../../../../common/CollumsTable/components/WarningMessage';

import TableInfo from '../../../../common/CollumsTable/components/TableInfo';

import { categoryStyles } from './styles';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { DropdownButton } from '../../../../common/DropdownButton';
import SearchInput from '../../../../common/CollumsTable/components/SearchInput';
import styled from 'styled-components';
import LoadingScreen from '../../../../../collums-components/components/common/loadingScreen';
import { toLocaleString, useDebounce } from '../../../../../collums-components/helpers';
import exportCsv from '../../../../common/CollumsTable/helpers/exportCSV';
import { scrollAnimation } from '../../../../../utils/helpers/common';
import PaginationTable from '../../../../common/CollumsTable/components/PaginationTable';
import { getDisplayedRows } from '../../../../common/CollumsTable/helpers/pagination';
import { useHistory } from 'react-router-dom';
import { Columns } from './columns';
import ReportFilterList from '../../../../common/ReportFilterList';

const StyledTableCell = withStyles(categoryStyles)(({ className, children, classes }) => (
    <TableCell className={classnames(classes.tableCell, className)}>{children}</TableCell>
));

const CustomHeadWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 5rem;
    &:first-child {
        margin-right: 1rem;
    }
`;

const AdditionalHeadRow = styled(TableRow)`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
`;

const SearchRow = styled.div`
    display: flex;
    width: 21.875rem;
`;

const TableInfoWrapper = styled.div`
    margin-left: -8px;
`;

const columns = ['promotionDiscount'];

function Items({ values, query, classes, filterBtn, loading, title = 'Special Offer Sales' }) {
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [isUsingSearch, setIsUsingSearch] = useState(false);
    const [search, setSearch] = useState('');
    const debouncer = useDebounce(search, 300);
    const ref = useRef();
    const [showWarning, setShowWarning] = useState(false);
    const redirectTo = useRef();
    const canRedirect = useRef(false);
    const history = useHistory();

    const releaseAction = () => {
        setShowWarning(true);
    };

    const leaveAction = () => {
        setShowWarning(false);
        canRedirect.current = true;
        if (redirectTo.current) {
            const name = redirectTo.current?.innerText?.toLowerCase();
            if (['scheduled reports', 'reports'].includes(name)) {
                if ('scheduled reports' === name) {
                    history.push('/scheduled-reports');
                } else {
                    history.push('/reports');
                }
            } else {
                try {
                    redirectTo.current.click();
                } catch (err) {
                    return;
                }
            }
        }
    };

    useEffect(() => {
        setPage(0);
        setIsUsingSearch(Boolean(search));
        scrollAnimation();
        //eslint-disable-next-line
    }, [debouncer]);

    useEffect(() => {
        scrollAnimation();
    }, [page, rowsPerPage]);

    const handleSearch = rows => {
        if (!rows) return;

        const _searchedValues = rows.filter(row => {
            const check = columns.filter(col => {
                return row[col]
                    ?.toString()
                    .toLowerCase()
                    .includes((search || '').toLowerCase());
            });

            if (check.length > 0) return true;

            return false;
        });

        return _searchedValues;
    };

    useEffect(() => {
        setPage(0);
        //eslint-disable-next-line
    }, [values]);

    const handleClickOutside = event => {
        if (event.target?.id === 'confirm-leave-button') return;

        if (event.target?.download) return;

        const isFromSearchButton = (event.path || []).some(el => el?.id === 'searchIcon');

        if (isFromSearchButton) return;

        const tagName = event.target?.tagName?.toLowerCase();
        if (tagName !== 'a' && tagName !== 'img') return;

        if (canRedirect.current) return;

        if (ref.current && !ref.current.contains(event.target) && totalRows?.length) {
            redirectTo.current = event.target;
            event.stopPropagation();
            event.preventDefault();
            releaseAction();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const filters = query;

    const handlePageChange = (_, newPage) => {
        setPage(newPage - 1);
    };
    const handleRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getDisplayingContent = () => {
        const rows = values?.items || [];
        let totalRows = (() => {
            if (isUsingSearch) {
                const searchedValues = handleSearch(rows);

                return searchedValues;
            }
            return rows;
        })();

        totalRows = totalRows.map(row => {
            return {
                ...row,
                percentage: parseFloat(((row.discountGross / row.originalPrice) * 100).toFixed(2))
            };
        });

        const actualIndex = rowsPerPage * page;
        const finalIndex = actualIndex + rowsPerPage;

        const displayingRows = totalRows.slice(actualIndex, finalIndex);

        return { totalRows, rows: displayingRows };
    };

    const { totalRows } = getDisplayingContent();

    const displayingItemsTotals = (() => {
        const rows = isUsingSearch ? totalRows : values?.items || [];
        const groupedDiscountsObj = [];
        rows.forEach(discount => {
            const currentGroup = groupedDiscountsObj[discount.promotionDiscount] || {
                promotionDiscount: discount.promotionDiscount,
                discountGross: 0,
                discountNet: 0,
                discountTax: 0,
                originalPrice: 0,
                quantity: 0
            };
            currentGroup.quantity += 1;
            currentGroup.discountGross = parseFloat((currentGroup.discountGross + discount.discountGross).toFixed(2));
            currentGroup.discountNet = parseFloat((currentGroup.discountNet + discount.discountNet).toFixed(2));
            currentGroup.discountTax = parseFloat((currentGroup.discountTax + discount.discountTax).toFixed(2));
            currentGroup.originalPrice = parseFloat((currentGroup.originalPrice + discount.originalPrice).toFixed(2));
            groupedDiscountsObj[discount.promotionDiscount] = currentGroup;
        });
        const groupedDiscounts = Object.values(groupedDiscountsObj).map(el => {
            return {
                ...el,
                percentage: parseFloat(((el.discountGross / el.originalPrice) * 100).toFixed(2))
            };
        });

        const grandTotal = {
            quantity: rows?.length || 0,
            discountGross: rows?.reduce((sum, el) => parseFloat((sum + el.discountGross).toFixed(2)), 0) || 0,
            discountNet: rows?.reduce((sum, el) => parseFloat((sum + el.discountNet).toFixed(2)), 0) || 0,
            discountTax: rows?.reduce((sum, el) => parseFloat((sum + el.discountTax).toFixed(2)), 0) || 0,
            originalPrice: rows?.reduce((sum, el) => parseFloat((sum + el.originalPrice).toFixed(2)), 0) || 0
        };
        grandTotal.percentage = parseFloat(((grandTotal.discountGross / grandTotal.originalPrice) * 100).toFixed(2));

        return {
            grandTotal,
            groupedDiscounts
        };
    })();

    const RenderTotals = ({ data }) => {
        return (
            <>
                {data.map((item, index) => (
                    <TableRow>
                        <StyledTableCell className={`${classes.defaultHeader} ${classes.leftAlign}`}>
                            {index === 0 ? 'Totals' : ''}
                        </StyledTableCell>
                        <StyledTableCell>{item.promotionDiscount}</StyledTableCell>
                        <StyledTableCell>{item.quantity}</StyledTableCell>
                        <StyledTableCell>{toLocaleString(item.discountNet)}</StyledTableCell>
                        <StyledTableCell>{toLocaleString(item.discountTax)}</StyledTableCell>
                        <StyledTableCell>{toLocaleString(item.discountGross)}</StyledTableCell>
                        <StyledTableCell>{item.percentage}%</StyledTableCell>
                        <StyledTableCell>{toLocaleString(item.originalPrice)}</StyledTableCell>
                    </TableRow>
                ))}
            </>
        );
    };

    const RenderTableBody = useCallback(() => {
        return (
            <TableBody>
                <TableRow>
                    <StyledTableCell className={`${classes.defaultHeader} ${classes.leftAlign}`}>
                        Grand Total
                    </StyledTableCell>
                    <StyledTableCell className={`${classes.defaultHeader} ${classes.rightAlign}`}></StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        {displayingItemsTotals.grandTotal.quantity}
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        {toLocaleString(displayingItemsTotals.grandTotal.discountNet)}
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        {toLocaleString(displayingItemsTotals.grandTotal.discountTax)}
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        {toLocaleString(displayingItemsTotals.grandTotal.discountGross)}
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        {displayingItemsTotals.grandTotal.percentage}%
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        {toLocaleString(displayingItemsTotals.grandTotal.originalPrice)}
                    </StyledTableCell>
                </TableRow>

                {<RenderTotals data={displayingItemsTotals.groupedDiscounts} />}
            </TableBody>
        );
        //eslint-disable-next-line
    }, [page, rowsPerPage, values, isUsingSearch, search]);

    const processCsv = () => {
        const csvRows = (() => {
            const arr = [];
            const totRows = [];
            arr.push(...totalRows);
            return [...totRows, ...arr];
        })();
        if (!csvRows.length) return;
        exportCsv(csvRows, tableInfo, false, Columns);
    };

    const tableInfo = { title, filters };
    return (
        <div ref={ref}>
            {/* header */}
            <AdditionalHeadRow>
                <ReportFilterList openFilterModal={filterBtn.onClick} runReport={filterBtn.runReport}>
                    <DropdownButton
                        label={'Options'}
                        options={[
                            {
                                label: 'Export as CSV',
                                handleClick: processCsv
                            }
                        ]}
                    />
                </ReportFilterList>
                <SearchRow>
                    <SearchInput
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value);
                        }}
                        onSearch={() => handleSearch}
                        onCancel={() => {
                            setSearch('');
                        }}
                    />

                    <CustomHeadWrapper>
                        <Button
                            color="secondary"
                            variant="none"
                            onClick={filterBtn.onClick}
                            className={classes.filterBtn}
                        >
                            {filterBtn.label || 'Filters'}
                        </Button>
                    </CustomHeadWrapper>
                </SearchRow>
            </AdditionalHeadRow>
            {filters && (
                <TableInfoWrapper>
                    <TableInfo {...{ title, filters, showFilters: false }} />
                </TableInfoWrapper>
            )}
            {/* header */}
            {loading ? (
                <LoadingScreen />
            ) : (
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={`${classes.defaultHeader} ${classes.leftAlign}`}>Date</TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Promotion Discount
                                </TableCell>

                                <TableCell
                                    className={`${classes.defaultHeader} ${classes.rightAlign}`}
                                    style={{ width: '5%' }}
                                >
                                    Invoice
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Net Discount
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Discount Tax
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Gross Discount
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Percentage
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Original Price
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        {<RenderTableBody />}
                    </Table>
                    {
                        <PaginationTable
                            count={totalRows.length}
                            rowsPerPage={rowsPerPage}
                            countDivRows={Math.ceil(totalRows.length / rowsPerPage)}
                            page={page + 1}
                            handleChangePage={handlePageChange}
                            handleChangeRowsPerPage={handleRowsPerPageChange}
                            labelDisplayedRows={() => getDisplayedRows(page + 1, totalRows.length, rowsPerPage)}
                        />
                    }
                </TableContainer>
            )}
            {showWarning && <WarningMessage leaveAction={leaveAction} setShowWarning={setShowWarning} />}
        </div>
    );
}

Items.propTypes = {
    values: PropTypes.object.isRequired,
    query: PropTypes.object,
    classes: PropTypes.object.isRequired
};

export default withStyles(categoryStyles)(Items);
