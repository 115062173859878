import React from 'react';

const generateAreaColumn = area => {
    const lowerCaseArea = area.toLowerCase();
    return {
        title: area,
        field: lowerCaseArea,
        type: 'quantity',
        // eslint-disable-next-line
        render: data => {
            return <span>{data?.[lowerCaseArea]}</span>;
        }
    };
};

export default generateAreaColumn;
