import Day from './Day';
import HourOfDay from './HourOfDay';
import Month from './Month';
import Quarter from './Quarter';
import Year from './Year';

export default {
    Day,
    HourOfDay,
    Month,
    Quarter,
    Year
};
