import React from 'react';
import { Grid } from '@material-ui/core';
import FilterSelector from './components/FilterSelector';

function AppointmentLogFilters({ handleFilterChange, filters }) {
    return (
        <Grid container>
            <Grid item md={4}>
                <FilterSelector
                    group={'action'}
                    handleFilterChange={handleFilterChange}
                    label={'action'}
                    filters={filters}
                    report={'appointment-log'}
                />
            </Grid>
        </Grid>
    );
}

export default AppointmentLogFilters;
