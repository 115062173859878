import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { listActiveClinics } from '../api/ClinicApi';

const ClinicsContext = createContext({});

export const ClinicsProvider = ({ children }) => {
    const [clinics, setClinics] = useState([]);

    const updateClinics = useCallback(newClinics => {
        setClinics(newClinics);
    }, []);

    const fetchClinics = async () => {
        const newClinics = await listActiveClinics();
        setClinics(newClinics);
    };

    return (
        <ClinicsContext.Provider value={{ clinics, updateClinics, fetchClinics }}>{children}</ClinicsContext.Provider>
    );
};

ClinicsProvider.propTypes = {
    children: PropTypes.element
};

export const useClinics = () => useContext(ClinicsContext);
