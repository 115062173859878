const { toLocaleString } = require('../../../../../../collums-components/helpers/index');

const getColumns = () => {
    return [
        {
            title: 'Staff',
            field: 'displayName'
        },
        {
            title: '#',
            field: 'count',
            type: 'quantity'
        },
        {
            title: '# CCS',
            field: 'converted'
        },
        {
            title: '# CCT',
            field: 'converted_to_treatment'
        },
        {
            title: '# CCP',
            field: 'converted_to_product'
        },
        {
            title: '% CCS',
            field: 'converted_rate',
            type: 'percentage',
            render: data => (data.converted_rate * 100).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%'
        },
        {
            title: '% CCT',
            field: 'cct_rate',
            type: 'percentage',
            render: data => (data.cct_rate * 100).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%'
        },
        {
            title: '% CCP',
            field: 'ccp_rate',
            type: 'percentage',
            render: data => (data.ccp_rate * 100).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%'
        },
        {
            title: 'Total CCS',
            field: 'net_total',
            type: 'currency',
            render: data => toLocaleString(data.net_total)
        },
        {
            title: 'Total CCT',
            field: 'net_total_treatment',
            type: 'currency',
            render: data => toLocaleString(data.net_total_treatment)
        },
        {
            title: 'Total CCP',
            field: 'net_total_product',
            type: 'currency',
            render: data => toLocaleString(data.net_total_product)
        },
        {
            title: 'Avg CCS',
            field: 'avg_net_total',
            type: 'currency',
            render: data => toLocaleString(data.avg_net_total)
        },
        {
            title: 'Avg CCT',
            field: 'avg_net_total_treatment',
            type: 'currency',
            render: data => toLocaleString(data.avg_net_total_treatment)
        },
        {
            title: 'Avg CCP',
            field: 'avg_net_total_product',
            type: 'currency',
            render: data => toLocaleString(data.avg_net_total_product)
        }
    ];
};

export default getColumns;
