import React from 'react';
import config from '../../../../../config';
import { linkableCell } from '../../../../../style/reportStyles';
import { toLocaleString } from '../../../../../collums-components/helpers';
import moment from 'moment';

const columns = [
    {
        title: 'Client Name',
        field: 'fullName',
        hideCSV: true,
        // eslint-disable-next-line
        render: data => {
            return (
                <span
                    style={linkableCell}
                    onClick={() => {
                        window.open(`${config.calendarUrl}/customer/${data.id}/general`, '_blank');
                    }}
                >
                    {data.fullName}
                </span>
            );
        }
    },
    { title: 'First name', field: 'firstName', hide: true },
    { title: 'Surname', field: 'surname', hide: true },
    { title: 'Email', field: 'email', hide: true },
    { title: 'Mobile', field: 'mobilePhone', hide: true },
    {
        title: 'First Visit',
        field: 'firstVisit',
        type: 'date',
        // eslint-disable-next-line
        render: data => {
            const dateFormatted = data.firstVisit
                ? moment(data.firstVisit, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
                : '';
            return <span>{dateFormatted}</span>;
        }
    },
    {
        title: 'Total Spend',
        field: 'totalSpend',
        type: 'currency',
        // eslint-disable-next-line
        render: data => {
            return (
                <span style={{ width: '100%', display: 'block', textAlign: 'right' }}>
                    {toLocaleString(data.totalSpend)}
                </span>
            );
        }
    },
    { title: 'Total Visits', field: 'totalVisits', type: 'quantity' }
];

export default columns;
