import React from 'react';
import config from '../../../../../../config';
import { toLocaleString } from '../../../../../../collums-components/helpers';
import { linkableCell } from '../../../../../../style/reportStyles';

const getColumns = () => {
    return [
        {
            title: 'Staff',
            field: 'staff'
        },
        {
            title: 'Date',
            field: 'date',
            type: 'date'
        },
        {
            title: 'Client',
            field: 'client',
            render: data => {
                return (
                    <span
                        style={linkableCell}
                        onClick={() => {
                            window.open(`${config.calendarUrl}/customer/${data.clientId}/general`, '_blank');
                        }}
                    >
                        {data.client}
                    </span>
                );
            }
        },
        {
            title: 'Service booked',
            field: 'service'
        },
        {
            title: 'Upsell',
            field: 'isUpsell',
            type: 'bool',
            render(data) {
                return data.isUpsell ? 'Yes' : 'No';
            }
        },
        {
            title: 'Items sold',
            field: 'items',
            type: 'quantity'
        },
        {
            title: 'Upsell Value',
            field: 'upsellGroup',
            type: 'group',
            subColumns: [
                {
                    title: 'Service',
                    field: 'upsellService',
                    type: 'currency'
                },
                {
                    title: 'Course',
                    field: 'upsellCourse',
                    type: 'currency'
                },
                {
                    title: 'Product',
                    field: 'upsellProduct',
                    type: 'currency'
                },
                {
                    title: 'Total',
                    field: 'upsellTotal',
                    type: 'currency'
                },
                {
                    title: 'Ave',
                    field: 'upsellAvg',
                    type: 'currency'
                }
            ],
            renders: [
                data => {
                    return toLocaleString(data.upsellService);
                },
                data => {
                    return toLocaleString(data.upsellCourse);
                },
                data => {
                    return toLocaleString(data.upsellProduct);
                },
                data => {
                    return toLocaleString(data.upsellTotal);
                },
                data => {
                    return toLocaleString(data.upsellAvg);
                }
            ]
        }
    ];
};

export default getColumns;
