import React from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import columns from './columns';

function Result({ values, query, filterBtn, loading, setValues }) {
    const filters = query;

    return (
        <DefaultMaterialTable
            title={'Toxin usage summary'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            canShowFilters={false}
            showFiltersOnHeader={true}
        />
    );
}

export default Result;
