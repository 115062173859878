import React, { useMemo } from 'react';
import { calculateTotalPrice, calculateNetPrice } from '../../../../../utils/helpers/common';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import { toLocaleString } from '../../../../../collums-components/helpers';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';

function Staff({ values, query, filterBtn, loading }) {
    const filters = query;

    const table = useMemo(() => {
        return {
            columns: [
                { title: 'Staff', field: 'staff' },
                { title: '#', field: 'quantity' },
                {
                    title: 'refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'aveNetSales',
                    field: 'aveNetSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'aveNetSales')
                },
                {
                    title: 'Gross Discounts',
                    field: 'discount',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'discount')
                },
                {
                    title: 'Refunds',
                    field: 'refunds',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'refunds')
                },
                {
                    title: 'List Price',
                    field: 'listPrice',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'listPrice')
                },
                {
                    title: 'Net Sales',
                    field: 'netSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netSales')
                },
                {
                    title: 'Tax',
                    field: 'tax',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'tax')
                },
                {
                    title: 'Total Sales',
                    field: 'totalSales',
                    customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'totalSales')
                }
            ],
            data: values.map(value => {
                const {
                    staff: { displayName: staff },
                    quantity,
                    listPrice,
                    discount,
                    refunds,
                    netSale,
                    aveNetSales,
                    tax
                } = value;

                return {
                    staff,
                    quantity,
                    listPrice: toLocaleString(listPrice),
                    discount: toLocaleString(discount || 0),
                    refunds: toLocaleString(refunds || 0),
                    netSales: toLocaleString(netSale || 0),
                    aveNetSales: toLocaleString(aveNetSales || 0),
                    tax: toLocaleString(tax || 0),
                    totalSales: toLocaleString(calculateTotalPrice(calculateNetPrice(listPrice, discount), tax))
                };
            })
        };
    }, [values]);
    return (
        <DefaultMaterialTable
            title="Sales By Staff"
            columns={table.columns}
            data={table.data}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            filters={filters}
        />
    );
}

export default Staff;
