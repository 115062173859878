import React, { useMemo } from 'react';
import { AppBar, withStyles, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';

import { reports, dashboard } from '../../constants/tabs';
import { header } from './styles';
import { useHistory, useLocation } from 'react-router-dom';

function Header({ classes }) {
    const history = useHistory();
    const location = useLocation();

    const currentTab = useMemo(() => location.pathname.split('/')[1], [location.pathname]);

    const tabs = useMemo(
        () =>
            [dashboard, reports].map(tab => (
                <Tab
                    key={tab.value}
                    className={classes.mainTab}
                    value={tab.value}
                    //eslint-disable-next-line
                    label={<a onClick={() => history.push(`/${tab.value}`)}>{tab.label}</a>}
                />
            )),
        [classes.mainTab, history]
    );

    return (
        <AppBar position="static" color="secondary" classes={{ root: classes.noShadow }}>
            <Tabs
                value={currentTab}
                onChange={(e, value) => history.push(`/${value}`)}
                className={classes.tabsContainer}
                TabIndicatorProps={{ className: classes.tabIndicator }}
            >
                {tabs}
            </Tabs>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object
};

export default withStyles(header)(Header);
