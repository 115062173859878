import React, { useEffect, useState } from 'react';
import excludedFilters from '../../constants/excludedFilters';
import withStyles from '@material-ui/styles/withStyles';
import { useReportFilters } from '../../hooks/ReportFilters';
import QueryItemBox from './QueryItemBox';
import { reportFilterListStyles } from './styles';
import getFilterLabel from '../../utils/helpers/getFilterLabel';
import getFiltersInUse from '../../utils/helpers/getFiltersInUse';
import { useParams } from 'react-router-dom';
import reportsWithoutPeriod from '../../constants/reportsWithoutPeriod';
import isRunReportDisabled from '../../utils/helpers/isRunReportDisabled';
import { Button, makeStyles } from '@material-ui/core';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import isArray from 'lodash/isArray';

const ReportFilterList = ({ children, classes, openFilterModal, runReport }) => {
    const { group, report } = useParams();
    const [columns, setColumns] = useState([]);
    const [isRunDisabled, setIsRunDisabled] = useState(false);
    const { savedFilters } = useReportFilters();
    const buttonClasses = makeStyles(modalsButtonStyles)();

    useEffect(() => {
        setIsRunDisabled(
            isRunReportDisabled({
                filters: savedFilters,
                group,
                report
            })
        );
    }, [savedFilters, report, group]);

    useEffect(() => {
        const checkArray = item => {
            if (isArray(item)) return item.length > 0;
            return true;
        };

        const reportFilters = getFiltersInUse(report);

        const newColumns = Object.keys(savedFilters || {})
            .filter(
                key =>
                    !excludedFilters.includes(key) &&
                    savedFilters[key] &&
                    reportFilters.includes(key) &&
                    checkArray(savedFilters[key])
            )
            .sort();
        setColumns(newColumns);
    }, [savedFilters, report]);

    if (!Object.keys(savedFilters || {}).length) return children;

    return (
        <div className={classes.boxContainer}>
            {children}
            {Boolean(savedFilters.from && savedFilters.to) && !reportsWithoutPeriod.includes(report) && (
                <QueryItemBox label={getFilterLabel('dateRange', savedFilters)} action={openFilterModal} />
            )}
            {columns.map(column => {
                return (
                    <QueryItemBox key={column} label={getFilterLabel(column, savedFilters)} action={openFilterModal} />
                );
            })}
            {!isRunDisabled && (
                <Button className={buttonClasses.confirmButton} onClick={runReport}>
                    Run report
                </Button>
            )}
        </div>
    );
};

export default withStyles(reportFilterListStyles)(ReportFilterList);
