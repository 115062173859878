import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from '../../collums-components/components/common/Sidebar';
import { useAuth } from '../../utils/hooks/common';
import ShowPINModal from './../../collums-components/components/common/PINModal/index';
import api from '../../api/api';
import { isAvailableByPlan } from '../../collums-constants/utils';
import { COLLUMS_APPS_ACCESS_TIER } from '../../collums-constants';
import EnvironmentDisabled from './../../collums-components/components/common/EnvironmentDisabled';
import './../../collums-constants/styles/css/global.css';
import LogoutModal from '../../collums-components/components/common/LogoutModal';
import { useCookies } from 'react-cookie';

const App = ({ children }) => {
    const authData = useAuth();
    const [cookies] = useCookies(['token']);

    if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.REPORTING)) {
        return <EnvironmentDisabled />;
    }

    if (!authData?.isInitialized) return null;

    return (
        <>
            <ShowPINModal
                onEnterPin={async (_, token) => {
                    api.config({ headers: { Authorization: token } });
                }}
            />
            {cookies.logoutActive === 'true' && <LogoutModal />}
            <div className="content-flex">
                <div className="content-width">
                    <div>{children}</div>
                </div>
                <Sidebar appName="reports" />
            </div>
        </>
    );
};

App.propTypes = {
    children: PropTypes.any.isRequired
};

export default App;
