import reports from '../../constants/reports';
import flattenDeep from 'lodash/flattenDeep';

const getFiltersInUse = report => {
    const reportList = flattenDeep(Object.values(reports).map(reportSection => Object.values(reportSection)));

    const reportProps = reportList.find(reportItem => reportItem.id === report);

    if (!reportProps || !reportProps.filters) return [];

    return Object.keys(reportProps.filters);
};

export default getFiltersInUse;
