import moment from 'moment';
import get from 'lodash/get';

export const currencySorter = (a, b, direction, sortBy) => {
    const value = get(a, sortBy) || 0;
    const otherValue = get(b, sortBy) || 0;
    if (direction === 'desc') {
        return parseFloat(value) - parseFloat(otherValue);
    } else {
        return parseFloat(otherValue) - parseFloat(value);
    }
};

export const dateSorter = (a, b, direction, sortBy) => {
    const firstTime = moment(a[sortBy], 'DD/MM/YYYY HH:mm:ss');
    const secondTime = moment(b[sortBy], 'DD/MM/YYYY HH:mm:ss');
    if (!firstTime.isValid()) {
        return direction === 'desc' ? -1 : 1;
    }
    if (!secondTime.isValid()) {
        return direction === 'desc' ? 1 : -1;
    }
    if (direction === 'desc') {
        return firstTime.isAfter(secondTime) ? 1 : -1;
    } else {
        return firstTime.isBefore(secondTime) ? 1 : -1;
    }
};

export const boolSorter = (a, b, direction, sortBy) => {
    if (direction === 'desc') {
        return a[sortBy] > b[sortBy] ? 1 : -1;
    } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
    }
};

export const defaultSorter = (a, b, direction, sortBy) => {
    if (direction === 'desc') {
        return (a[sortBy] || '').localeCompare(b[sortBy] || '');
    } else {
        return (b[sortBy] || '').localeCompare(a[sortBy] || '');
    }
};
