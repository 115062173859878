import React, { useCallback, useEffect, useState } from 'react';
import { Box, Switch, FormControlLabel } from '@material-ui/core';

function IncludeTaxFilter({ handleSetFilters, filters }) {
    const [checked, setChecked] = useState(filters?.includeTaxes !== undefined ? filters.includeTaxes : true);

    const changeFilter = useCallback(() => {
        handleSetFilters({
            ...filters,
            includeTaxes: !checked // use not checked because the state did not change yet
        });
        //eslint-disable-next-line
    }, [checked, filters]);

    useEffect(() => {
        if (filters.includeTaxes) {
            setChecked(true);
        } else {
            if (filters.includeTaxes === undefined) {
                //default value to true
                setChecked(true);
            } else {
                setChecked(false);
            }
        }
        //eslint-disable-next-line
    }, []);

    const handleChange = () => {
        setChecked(!checked);
        changeFilter();
    };

    return (
        <Box marginTop={4}>
            <FormControlLabel control={<Switch checked={checked} onClick={handleChange} />} label="Ex Tax / Inc Tax" />
        </Box>
    );
}

export default IncludeTaxFilter;
