export const styles = () => ({
    dashboardMainTitle: {
        margin: '10px'
    },
    reportTitleAlone: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 12,
        fontSize: 18,
        fontWeight: 500,
        '& a': {
            color: ' rgba(0, 0, 0, 0.87)'
        }
    }
});
