import React from 'react';
import { Grid } from '@material-ui/core';
import FilterSelector from './components/FilterSelector';

function VatFilters({ group, handleSetFilters, filters }) {
    return (
        <Grid container>
            <Grid item md={4}>
                <FilterSelector
                    group={group}
                    handleFilterChange={handleSetFilters}
                    label={'Location'}
                    filters={filters}
                />
            </Grid>
        </Grid>
    );
}

export default VatFilters;
