import React from 'react';
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const AccountPaymentsFilter = ({ handleFilterChange, filters, label, tooltipText }) => {
    const controlLabel = label || 'Hide account payments and gift vouchers';
    const controlTooltipText =
        tooltipText ||
        'When deposits or account payments are taken, they appear on an invoice as if they are a sale when in fact they are not.\n' +
            'When a new invoice is created and paid by the deposit or account payment, it will be counted as a sale again.\n' +
            'Hide the “sale” of the deposit/account item to avoid double counting.';

    const getIsChecked = () => {
        return filters?.excludeAccountPayments;
    };

    const handleChange = e => {
        handleFilterChange({
            excludeAccountPayments: e.target.checked || undefined
        });
    };

    return (
        <Box marginTop={4} style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
                control={<Checkbox checked={getIsChecked()} onClick={handleChange} />}
                label={controlLabel}
            />
            <Tooltip title={<Typography style={{ fontSize: 14 }}>{controlTooltipText}</Typography>} aria-label="help">
                <HelpIcon />
            </Tooltip>
        </Box>
    );
};

export default AccountPaymentsFilter;
