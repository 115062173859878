import React, { useState } from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { InvoiceModalStyles } from './styles';

const EditableCell = ({ defaultValue, children, onConfirm, onCancel, classes, toLeft = false }) => {
    const [value, setValue] = useState(defaultValue);

    return (
        <div className={`${classes.editableCellWrapper} ${toLeft ? classes.editableCellWrapperLeft : ''}`}>
            {children ? children({ value, setValue }) : ''}
            <IconButton
                className={classes.editCellBtn}
                onClick={() => {
                    onConfirm(value);
                }}
            >
                <Check />
            </IconButton>
            <IconButton className={classes.editCellBtn} onClick={onCancel}>
                <Close />
            </IconButton>
        </div>
    );
};

EditableCell.propTypes = {
    defaultValue: PropTypes.any,
    children: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    toLeft: PropTypes.bool
};

export default withStyles(InvoiceModalStyles)(EditableCell);
