import React from 'react';
import { toLocaleString } from '../../../../../../collums-components/helpers';
import DefaultMaterialTable from '../../../../../common/DefaultMaterialTable';

function Category({ values, query, filterBtn, loading, setValues }) {
    const filters = query;

    const columns = [
        {
            title: 'Category',
            field: 'category',
            // eslint-disable-next-line react/display-name
            render: data => <span style={data.category < 0 ? { color: 'red' } : {}}>{data.category}</span>
        },
        {
            title: '#',
            field: 'appointmentAmount',
            type: 'quantity',
            // eslint-disable-next-line react/display-name
            render: data => (
                <span style={data.appointmentAmount < 0 ? { color: 'red' } : {}}>{data.appointmentAmount}</span>
            )
        },
        {
            title: 'Net Price',
            field: 'netPrice',
            type: 'currency',
            // eslint-disable-next-line react/display-name
            render: data => (
                <span style={data.netPrice < 0 ? { color: 'red' } : {}}>{toLocaleString(data.netPrice)}</span>
            )
        },
        {
            title: 'Tax',
            field: 'tax',
            type: 'currency',
            // eslint-disable-next-line react/display-name
            render: data => <span style={data.tax < 0 ? { color: 'red' } : {}}>{toLocaleString(data.tax)}</span>
        },
        {
            title: 'Total',
            field: 'total',
            type: 'currency',
            // eslint-disable-next-line react/display-name
            render: data => <span style={data.total < 0 ? { color: 'red' } : {}}>{toLocaleString(data.total)}</span>
        },
        {
            title: '% Net Total',
            field: 'percentageOfTotal',
            type: 'percentage',
            // eslint-disable-next-line react/display-name
            render: data => (
                <span style={data.percentageOfTotal < 0 ? { color: 'red' } : {}}>{data.percentageOfTotal}%</span>
            )
        }
    ];

    return (
        <DefaultMaterialTable
            title={'Value of future appointments'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
        />
    );
}

export default Category;
