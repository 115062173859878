import moment from 'moment';
import getSelectedPeriod from '../../../../filters/helpers/getSelectedPeriod';

const removeCommas = field => {
    let value = field === 0 ? '0' : (field || '').toString();

    value = value.replace(/,/g, '');

    return value;
};

const getGroupRows = el => new Array(el.subColumns.length - 1).fill('').reduce(acc => acc + ',', '');

const convertToCSV = (arr, columns) => {
    if (columns) {
        const myArr = arr
            .map(toxinItem => {
                const hasGroups = columns.filter(el => el.type === 'group').length;
                const columnsName = columns
                    .map(el => {
                        if (el.field === 'name') return toxinItem.name;
                        return `${el.title}${el.type === 'group' ? getGroupRows(el) : ''}`;
                    })
                    .join(',');
                const subColumnsNames = columns
                    .map(el => (el.type === 'group' ? el.subColumns.map(sub => sub.title).join(',') : ''))
                    .join(',');

                const staffList = toxinItem.items
                    .map(arrItem => {
                        return columns
                            .map(el => {
                                if (el.type === 'group') {
                                    return el.subColumns
                                        .map(sub => {
                                            if (sub.field?.includes('.')) {
                                                const path = sub.field.split('.');
                                                const item = path.reduce((acc, key) => acc?.[key], arrItem);
                                                return removeCommas(item);
                                            }
                                            return removeCommas(arrItem[sub.field]);
                                        })
                                        .join(',');
                                }

                                return removeCommas(arrItem[el.field]);
                            })
                            .join(',');
                    })
                    .join('\n');

                return `${columnsName}\n${hasGroups ? subColumnsNames + '\n' : ''}${staffList}`;
            })
            .join('\n\n');
        return myArr;
    }

    const array = [Object.keys(arr[0])].concat(arr);

    return array
        .map(it => {
            return Object.values(it)
                .map(el => removeCommas(el))
                .toString();
        })
        .join('\n');
};

const downloadCsvFile = (fileName, csvString) => {
    var downloadLink = document.createElement('a');
    var blob = new Blob(['\ufeff', csvString]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `${fileName}.csv`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

const exportCSV = (data, tableInfo, showFilters = true, columns) => {
    if (!data?.length) return;
    let _data = convertToCSV(data, columns);
    const generatedAt = moment().format('DD/MM/YYYY HH:mm');
    const renderFilters = () => {
        let { to, from, period, ..._filters } = tableInfo?.filters || {};

        let __filters = Object.keys(_filters).map(filter => {
            let value = tableInfo?.filters[filter];

            if (filter === 'dateError') {
                return '';
            }

            if (typeof value === 'boolean') {
                value = value.toString();
            }

            if (filter === 'clinics') {
                value = value.join(',');
            }

            return `${filter}: ${value}; `;
        });

        to = moment(to).format('DD/MM/YYYY');
        from = moment(from).format('DD/MM/YYYY');

        const reportInfo = `${removeCommas(tableInfo?.title)}\nGenerated: ${generatedAt} \n${
            tableInfo?.report &&
            (tableInfo?.report === 'outstanding-courses' || tableInfo?.report === 'outstanding-acc-balances')
                ? ''
                : `Reporting Period: ${from} - ${to} ${period && getSelectedPeriod(period)}`
        } \n${showFilters ? `Filters: ${__filters}` : ''}`;
        return reportInfo;
    };

    _data = `${renderFilters()} \n${_data}`;
    const fileName = `${(tableInfo?.title || '').toLowerCase().replace(' ', '-')}-${generatedAt}`;
    downloadCsvFile(fileName, _data);
};

export default exportCSV;
