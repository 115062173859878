import React from 'react';
import DefaultMaterialTable from '../../../../../common/DefaultMaterialTable';
import getColumns from './columns';

function Items({ values, query, filterBtn, loading, setValues }) {
    const filters = query;
    const columns = getColumns();

    return (
        <DefaultMaterialTable
            title={'Consultation Conversion Log'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            canShowFilters={false}
            showFiltersOnHeader={false}
        />
    );
}

export default Items;
