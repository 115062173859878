import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TagsApi from '../../../collums-components/api/TagsApi';
import { filters } from './styles';

function TagsFilter({ classes, label, handleFilterChange, filters }) {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const tags = await TagsApi.listTags();
            setItems(tags);

            setLoading(false);
        };

        fetch();
    }, []);

    useEffect(() => {
        if (items.length > 0) {
            const value = filters['tags'];
            if (value) {
                const splitted_values = value.split(',');
                setSelectedItems(splitted_values.map(sv => items.find(s => s.id === sv)));
            }
        }
    }, [filters, items]);

    const handleChange = (_, items) => {
        const _items = items.map(i => i.id).join(',');
        handleFilterChange({
            tags: _items
        });
    };

    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={classes.autocomplete}
                options={items}
                value={selectedItems}
                onChange={handleChange}
                loading={loading}
                label={label}
                getOptionLabel={option => option.name}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{option.name}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(TagsFilter);
