export const filters = () => ({
    formControl: {
        minWidth: 140
    },
    filterMaxWidth: {
        maxWidth: '33.33%'
    },
    smallAutocompleteMaxWidth: {
        maxWidth: '200px'
    },
    inputLabel: {
        fontSize: 12
    },
    selectRoot: {
        backgroundColor: 'white'
    },
    selectInputProps: {
        fontSize: 14,
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'space-between',
        alignItems: 'center'
    },
    menuProps: {
        '&&& $li': {
            fontSize: 14
        }
    },
    runBtn: {
        background: '#B41678',
        color: '#FFF',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
            background: '#B41678',
            boxShadow: 'none'
        },
        '&.MuiButton-root': {
            '&.Mui-disabled': {
                backgroundColor: '#ccc'
            }
        }
    },
    backBtn: {
        background: 'white',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        color: '#7C7E86',
        borderColor: '#7C7E86',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    },
    autocomplete: {
        marginRight: 5,
        '& .MuiInputBase-root': {
            padding: 2,
            paddingRight: '45px !important'
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        }
    },
    datepickerContainer: {
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    datepicker: {
        width: 200
    },
    inputDatepicker: {
        fontSize: 14,
        fontWeight: 400,
        height: 38
    },
    marginLeft12px: {
        marginLeft: 12
    },
    autocompleteOptions: {
        fontSize: 14,
        fontWeight: 400,
        padding: 0,
        margin: 0.5
    },
    autocompleteInputProps: {
        fontSize: 14,
        fontWeight: 400
    },
    autocompleteLabelProps: {
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center'
    },
    autocompleteChipProps: {
        maxWidth: '73%',
        margin: 1,
        padding: 0
    },
    alignedContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    marginRight: {
        marginRight: '20px'
    }
});
