import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import { materialTableCurrencySorter } from '../../../../../utils/helpers/reports';
import { Link } from '@material-ui/core';
import { INVOICE_PAYMENT_STATUS } from '../../../../../collums-constants';
import { InvoiceLogTable } from './InvoiceLogTable';
import config from '../../../../../config';

function Status({ values, query, filterBtn, setInvoice, loading, setValues, generateReport }) {
    const filters = query;
    const columns = [
        { title: 'Date/Time', field: 'createdAt' },
        {
            title: 'Invoice',
            field: 'code',
            // eslint-disable-next-line react/display-name
            render: data => (
                <Link
                    onClick={evt => {
                        evt.stopPropagation();
                        setInvoice({ id: data.id });
                    }}
                    style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
                >
                    {data.code}
                </Link>
            )
        },
        {
            title: 'Status',
            field: 'paymentStatus',
            // eslint-disable-next-line react/display-name
            render: data => (
                <span style={data.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND ? { color: 'red' } : {}}>
                    {data.paymentStatus}
                </span>
            )
        },
        {
            title: 'Discount',
            field: 'discount',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'discount'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.discount < 0 ? { color: 'red' } : {}}>{toLocaleString(data.discount)}</span>
            )
        },
        {
            title: 'Net Price',
            field: 'netPrice',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netPrice'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.netPrice < 0 ? { color: 'red' } : {}}>{toLocaleString(data.netPrice)}</span>
            )
        },
        {
            title: 'Tax',
            field: 'tax',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'tax'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => <span style={data.tax < 0 ? { color: 'red' } : {}}>{toLocaleString(data.tax)}</span>
        },
        {
            title: 'Total',
            field: 'grossPrice',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'grossPrice'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.grossPrice < 0 ? { color: 'red' } : {}}>{toLocaleString(data.grossPrice)}</span>
            )
        },
        {
            title: 'Paid',
            field: 'paid',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'paid'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => <span style={data.paid < 0 ? { color: 'red' } : {}}>{toLocaleString(data.paid)}</span>
        },
        {
            title: 'Payment Method',
            field: 'paymentMethods',
            type: 'text',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'paymentMethods')
        },
        {
            title: 'Outstanding',
            field: 'outstanding',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'outstanding'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.outstanding !== 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.outstanding || 0)}
                </span>
            )
        },
        {
            title: 'Client Name',
            field: 'customerName',
            // eslint-disable-next-line react/display-name
            render: data => (
                <Link href={`${config.calendarUrl}/customer/${data.customerId}/general`} target="_blank">
                    {data?.customerName || '-'}
                </Link>
            )
        }
    ];

    const onChangeTableFilter = (key, value) => {
        filters[key] = value;
        generateReport(filters, 'status');
    };

    return (
        <DefaultMaterialTable
            title={'Invoice Log'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            TargetComponent={InvoiceLogTable}
            targetComponentProps={{
                onChangeTableFilter
            }}
        />
    );
}

export default Status;
