import React from 'react';
import { Grid } from '@material-ui/core';
import FilterSelector from './components/FilterSelector';
import IncludeExpiredFilter from './components/IncludeExpiredFilter';

function OutstandingCoursesFilters({ handleSetFilters, filters }) {
    return (
        <Grid container spacing={4}>
            <Grid item md={4}>
                <FilterSelector
                    group={'location'}
                    handleFilterChange={handleSetFilters}
                    label={'Location'}
                    filters={filters}
                />
            </Grid>
            <Grid item md={4}>
                <IncludeExpiredFilter handleFilterChange={handleSetFilters} filters={filters} />
            </Grid>
        </Grid>
    );
}

export default OutstandingCoursesFilters;
