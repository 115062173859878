import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

function IncludeZeroSalesFilter({ handleFilterChange, filters }) {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (checked) {
            handleFilterChange({
                includeZeroSales: 'true'
            });
        } else {
            handleFilterChange({
                includeZeroSales: false
            });
        }
        //eslint-disable-next-line
    }, [checked]);

    useEffect(() => {
        if (filters.includeZeroSales) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [filters]);

    const handleChange = () => {
        setChecked(!checked);
    };

    return (
        <Box marginTop={4}>
            <FormControlLabel
                control={<Checkbox checked={checked} onClick={handleChange} />}
                label="Include zero sales"
            />
        </Box>
    );
}

export default IncludeZeroSalesFilter;
