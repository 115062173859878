export const modal = theme => ({
    filtersArea: {
        width: '100%',
        marginTop: '15px'
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 25%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        padding: '3vh 1vh',
        outline: 'none',
        '& h4': {
            fontWeight: 'normal'
        }
    },
    closeBtn: {
        position: 'absolute',
        top: '0.25rem',
        right: '0.25rem'
    },
    header: {
        position: 'absolute',
        top: '1rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& h3': {
            margin: '0 0 14px 0',
            fontSize: '20px',
            fontWeight: 500
        }
    },
    content: {
        padding: '20px',
        overflowY: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    form: {
        width: '85%',
        display: 'flex',
        flexDirection: 'column',
        '& h4': {
            margin: 0,
            width: '100%',
            textAlign: 'left',
            fontFamily: 'Gilmer Regular'
        },
        '& p': {
            margin: '8px 0',
            fontFamily: 'Gilmer Regular'
        },
        '& form': {
            display: 'flex',
            flexDirection: 'column',
            '& p': {
                width: '25%',
                textAlign: 'right',
                marginRight: 4
            },
            '& div': {
                display: 'flex',
                margin: '3px 0',
                alignItems: 'center'
            },
            '& span': {
                display: 'flex',
                alignItems: 'center',
                margin: '0 4px'
            }
        }
    },
    frequencyForm: {
        display: 'flex',
        flexDirection: 'row'
    },
    frequencyInput: {
        display: 'flex',
        width: '50%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& p': {
            width: '50% !important'
        }
    },
    emailInput: {
        width: '70%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'start',
        '& div': {
            width: '100%'
        }
    },
    sendEmailForm: {
        display: 'flex',
        width: '85%',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            fontFamily: 'Gilmer Regular, Roboto'
        },
        '& .MuiTextField-root': {
            width: '100%',
            marginLeft: '2.5%'
        }
    },
    helpIcon: {
        color: 'lightblue',
        cursor: 'pointer',
        marginLeft: 4
    },
    sendTest: {
        color: 'blue',
        cursor: 'pointer',
        marginLeft: '12.5% !important'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '1vh',
        marginTop: '6vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14,
            fontWeight: 500,
            padding: '6px 14px'
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        },
        '& button:disabled': {
            color: theme.palette.gray.main,
            backgroundColor: theme.palette.gray.light
        }
    },
    marginButtons: {
        '& button:': {
            marginRight: 10
        }
    },
    centered: {
        width: 'unset !important',
        marginTop: 'unset'
    },
    disableRangeInputs: {
        pointerEvents: 'none',
        backgroundColor: '#f1f1f1'
    }
});
