import Moment from 'moment/moment';

const getDefaultFilters = (report, organisation) => {
    const includeTaxes = organisation?.reporting?.includeTaxes ?? true;
    const additionalFilters = {
        'staff-commission-sales': { includeTaxes },
        'staff-sales-and-performance': { includeTaxes },
        'gift-voucher-totals': { includeExpired: false },
        'future-appointments': {
            futureDatesOnly: true
        }
    };

    const excludeFilterTime = ['outstanding-acc-balances', 'outstanding-courses', 'outstanding-gift-vouchers'];

    const defaultFilters = additionalFilters[report] ?? {};

    if (!excludeFilterTime.includes(report)) {
        Object.assign(defaultFilters, {
            from: Moment().format('YYYY-MM-DD'),
            to: Moment().format('YYYY-MM-DD')
        });
    }

    return defaultFilters;
};

export default getDefaultFilters;
