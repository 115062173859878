import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { filters } from './styles';
import getMissingFilters from '../../../utils/helpers/getMissingFilters';
import { useReportFilterItems } from '../../../hooks/reportFilterItems';

function ToxinListFilter({ classes, label, handleFilterChange, filters, report }) {
    const { toxins } = useReportFilterItems();
    const [selectedItems, setSelectedItems] = useState([]);
    const [isFieldRequired, setIsFieldRequired] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const handleChange = items => {
        handleFilterChange({
            toxins: (items ?? []).map(el => el.name)
        });
        setSelectedItems(items ?? []);
    };

    useEffect(() => {
        setIsFieldRequired(getMissingFilters(report, {}).includes('toxins'));
    }, [report]);

    useEffect(() => {
        if (!isFirstRender) return;
        if (toxins.length > 0) {
            const values = filters['toxins'];
            if (values) {
                handleChange(values.map(value => toxins.find(item => item.name === value)).filter(el => el));
            } else {
                handleChange();
            }
            setIsFirstRender(false);
        }
        // eslint-disable-next-line
    }, [filters, toxins]);

    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={`${classes.autocomplete} ${classes.filterMaxWidth}`}
                options={toxins}
                value={selectedItems}
                onChange={(_, item) => handleChange(item)}
                loading={!toxins.length}
                label={label}
                required={isFieldRequired}
                getOptionLabel={option => option.name}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        error={!selectedItems && isFieldRequired}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{`${option.name}`}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filters)(ToxinListFilter);
