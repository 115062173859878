import React, { useMemo } from 'react';

import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';

import styled from 'styled-components';

import uniqBy from 'lodash/uniqBy';
import { standardizeTimeSize } from '../../../collums-constants/utils';

const GrandTotalRows = ({ data, columns, grandTotalsRef, allData }) => {
    const clinics = useMemo(() => {
        if (!allData) {
            grandTotalsRef.current = [];
            return;
        }
        const appearingClinics = uniqBy(
            allData.map(el => ({ id: el.locationId, locationName: el.locationName })),
            'id'
        );
        const clinicValues = appearingClinics.map(clinic => {
            const content = data.filter(el => el.locationId === clinic.id);

            if (!content || !content?.length) return null;
            const totalObject = {};
            columns.forEach((col, i) => {
                const getCell = (column, index) => {
                    let value;
                    if (column.type === 'rows') {
                        value = content.length;
                    }
                    if (column.type === 'currency') {
                        if (column.total === 'average') {
                            const sumOfItems = content.reduce((counter, row) => {
                                const newValue = row[column.field] || 0;
                                return counter + newValue;
                            }, 0);

                            totalObject[column.field] = value;
                            value = sumOfItems / content.length;
                        } else {
                            const sumOfItems = content.reduce((counter, row) => {
                                const newValue = row[column.field] || 0;
                                return counter + newValue;
                            }, 0);
                            totalObject[column.field] = value;
                            value = sumOfItems;
                        }
                    }

                    if (column.type === 'quantity') {
                        const sumOfItems = content.reduce((counter, row) => {
                            const newValue = row[column.field];
                            return counter + newValue;
                        }, 0);
                        value = sumOfItems;
                    }

                    if (column.type === 'unique') {
                        const nameSet = new Set();
                        content.forEach(el => {
                            nameSet.add(el[column.field]);
                        });
                        const array = Array.from(nameSet);
                        value = array.length;
                    }

                    if (col.type === 'percentage') {
                        value = '100.0%';
                    }

                    if (col.field === 'code') {
                        value = content.length;
                    }

                    if (col.type === 'hoursAndMinutes') {
                        const [hours, minutes] = content.reduce(
                            (acc, item) => {
                                const splited = item[col.field]?.split(':');

                                if (!splited) return acc;

                                return [acc[0] + parseInt(splited[0]), acc[1] + parseInt(splited[1])];
                            },
                            [0, 0]
                        );

                        value = `${standardizeTimeSize(hours + parseInt(minutes / 60))}:${standardizeTimeSize(
                            minutes % 60
                        )}`;
                    }

                    if (index === 0 && column.type !== 'currency' && column.field !== 'code') {
                        value = `${clinic.locationName} Total`;
                    }

                    if (column.field !== 'currency') {
                        totalObject[column.field] = value;
                    }

                    if (column.hide) return null;

                    /* 
                            if (
                                col.hide ||
                                (!redemptionValuesCheckbox && (col.field === 'redemptionValue' || col.field === 'grossRedemption'))
                            )
                                return null; 
                    */

                    return value;
                };

                if (col.type === 'group') {
                    if (col.subColumns.length === 1) return getCell(col.subColumns[0], i + 1);
                    col.subColumns.forEach((c, localIndex) => getCell(c, localIndex + i));
                } else {
                    getCell(col, i);
                }
            });
            return totalObject;
        });
        grandTotalsRef.current = clinicValues;
        return clinicValues;

        //eslint-disable-next-line
    }, [data, columns]);

    const RowData = useMemo(() => {
        if (!clinics?.length) return null;
        return clinics.map((row, i) => {
            const cells = columns.map((col, j) => {
                let value = row[col.field];

                if (col.Component) {
                    value = col.Component(row);
                }

                if (col.render) {
                    value = col.render(row);
                }

                if (col.type === 'group') {
                    return col.renders.map((render, index) => {
                        value = render(row);
                        const alignment = (() => {
                            if (col.subColumns[index].title === 'Commission') {
                                return 'right';
                            }
                            if (col.subColumns.length === 1) {
                                if (col.subColumns[index].type === 'currency') {
                                    return 'right';
                                } else {
                                    return 'left';
                                }
                            } else if (index === 1) {
                                return 'right';
                            } else {
                                return 'left';
                            }
                        })();

                        const cellBodyStyle = (() => {
                            if (index === 0) {
                                return {
                                    borderLeft: '1px solid rgba(224, 224, 224, 1)'
                                };
                            }
                            return { borderRight: '1px solid rgba(224, 224, 224, 1)' };
                        })();

                        return (
                            <TableCellBody
                                key={`col_${j}_${col.field}_${index}`}
                                align={alignment}
                                style={cellBodyStyle}
                            >
                                {value}
                            </TableCellBody>
                        );
                    });
                } else {
                    return (
                        <TableCellBody
                            key={`col_${j}_${col.field}`}
                            align={col.type === 'currency' || col.type === 'percentage' ? 'right' : 'left'}
                        >
                            {value}
                        </TableCellBody>
                    );
                }
            });

            return <TableRow key={`row_${i}`}>{cells}</TableRow>;
        });
        //eslint-disable-next-line
    }, [clinics]);

    return <>{RowData}</>;
};

const TableRow = styled(MuiTableRow)`
    width: 100%;
    height: 53px;
    max-height: 53px;
`;

const TableCellBody = styled(MuiTableCell)`
    min-width: 100px;
    font-size: 14px;
    font-weight: 500;
`;

export default GrandTotalRows;
