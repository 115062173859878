import React from 'react';
import config from '../../../../../config';
import { linkableCell } from '../../../../../style/reportStyles';

const columns = [
    { title: 'Staff', field: 'practitioner' },
    { title: 'Date', field: 'date', type: 'date' },
    {
        title: 'Client',
        field: 'customerName',
        // eslint-disable-next-line
        render: data => {
            return (
                <span
                    style={linkableCell}
                    onClick={() => {
                        window.open(`${config.calendarUrl}/customer/${data.customerId}/general`, '_blank');
                    }}
                >
                    {data.customerName}
                </span>
            );
        }
    },
    { title: 'Toxin', field: 'toxin' }
];

export default columns;
