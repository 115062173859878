import { FINANCIALS, STAFF, GIFT_VOUCHERS, COMMISSION, STOCK, APPOINTMENT } from '../../../constants/reportGroups';

export default function getSelectedReport(category, report) {
    const reportKey = report.toUpperCase().replace(/-/g, '_');

    switch (category) {
        case 'staff':
            return STAFF[reportKey];
        case 'gift':
            return GIFT_VOUCHERS[reportKey];
        case 'commission':
            return COMMISSION[reportKey];
        case 'stock':
            return STOCK[reportKey];
        case 'appointments':
            return APPOINTMENT[reportKey];
        default:
            return FINANCIALS[reportKey];
    }
}
