import { Box, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import { filters } from './styles';
import React from 'react';

const options = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '250', label: '250' }
];

function ResultsFilter({ classes, handleFilterChange, filters }) {
    const getValue = () => {
        return filters?.results || options[0].value;
    };

    const handleChange = e => {
        handleFilterChange({
            results: e.target.value
        });
    };

    return (
        <Box marginTop={4}>
            <InputLabel id="results-label" style={{ marginBottom: '10px' }}>
                Results
            </InputLabel>
            <Select
                label="Reults"
                variant="outlined"
                className={classes.selectRoot}
                labelId="results-label"
                value={getValue()}
                onChange={handleChange}
                inputProps={{
                    className: classes.selectInputProps
                }}
                MenuProps={{
                    className: classes.menuProps
                }}
            >
                {options.map(option => {
                    return (
                        <MenuItem key={`${option.value}-option`} value={option.value}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </Box>
    );
}

export default withStyles(filters)(ResultsFilter);
