import React from 'react';

const generateToxinColumn = area => {
    const lowerCaseArea = area.toLowerCase();
    return {
        title: area,
        field: lowerCaseArea,
        type: 'group',
        subColumns: [
            { title: '#', field: `${lowerCaseArea}.quantity`, type: 'quantity' },
            {
                title: 'Min',
                field: `${lowerCaseArea}.min`,
                type: 'quantity'
            },
            {
                title: 'Max',
                field: `${lowerCaseArea}.max`,
                type: 'quantity'
            },
            {
                title: 'Total',
                field: `${lowerCaseArea}.total`,
                type: 'quantity'
            },
            {
                title: 'Ave',
                field: `${lowerCaseArea}.ave`,
                type: 'quantity'
            }
        ],
        renders: [
            data => {
                return <span>{data?.[lowerCaseArea]?.quantity}</span>;
            },
            data => {
                return <span>{data?.[lowerCaseArea]?.min}</span>;
            },
            data => {
                return <span>{data?.[lowerCaseArea]?.max}</span>;
            },
            data => {
                return <span>{data?.[lowerCaseArea]?.total}</span>;
            },
            data => {
                return <span>{data?.[lowerCaseArea]?.ave}</span>;
            }
        ]
    };
};

export default generateToxinColumn;
