import React, { useEffect, useState } from 'react';
import DashboardFilter from './components/DashboardFilter';
import { Box, Grid, Link, withStyles, Breadcrumbs as MaterialUIBreadcrumbs } from '@material-ui/core';
import TypeSalesComponent from './components/TypeSalesComponent';
import { styles } from './styles';
import ReportApi from '../../api/ReportApi';
import CourseServiceCategories from './components/CourseServiceCategories';
// import StaffSalesComponent from './components/StaffSalesComponent';
// import AppointmentListComponent from './components/AppointmentListComponent';
// import PaymentsTotalComponent from './components/PaymentsTotalComponent';
import { useHistory } from 'react-router-dom';

const Dashboard = ({ classes }) => {
    const cacheMinutes = 10;
    const history = useHistory();
    const [dashboardFilters, setDashboardFilters] = useState(
        localStorage.getItem('reportingDashboardFilters')
            ? JSON.parse(localStorage.getItem('reportingDashboardFilters'))
            : {}
    );
    const [categoryTypeSales, setCategoryTypeSales] = useState(null);
    // const [staffSales, setStaffSales] = useState(null);
    // const [appointmentLog, setAppointmentLog] = useState(null);
    // const [paymentTotals, setPaymentTotals] = useState(null);
    // console.log(dashboardFilters);

    useEffect(() => {
        setCategoryTypeSales(null);
        // setStaffSales(null);
        // setAppointmentLog(null);
        // setPaymentTotals(null);
        getCategoryTypeSales();
        // getStaffSales();
        // getPaymentTotals();
        // getAppointmentLog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardFilters]);

    const setLocalStorage = (key, data) => {
        const currentDate = new Date().toISOString();
        const from = dashboardFilters.from.substring(0, 10);
        const to = dashboardFilters.to.substring(0, 10);
        const cacheObject = {
            date: currentDate,
            data: data
        };
        clearLocalStorage(key);
        localStorage.setItem(`${key}-${from}-${to}`, JSON.stringify(cacheObject));
    };

    const clearLocalStorage = (key, force = false) => {
        const currentDate = new Date();
        const tenMinutesAgo = new Date(currentDate.getTime() - cacheMinutes * 60 * 1000);
        for (let i = 0; i < localStorage.length; i++) {
            const keyToCheck = localStorage.key(i);

            if (keyToCheck.startsWith(key)) {
                const item = JSON.parse(localStorage.getItem(keyToCheck));
                if (item && item.date) {
                    const cachedDate = new Date(item.date);
                    if (force || cachedDate < tenMinutesAgo) {
                        localStorage.removeItem(keyToCheck);
                    }
                }
            }
        }
    };

    const getLocalStorage = key => {
        const from = dashboardFilters.from.substring(0, 10);
        const to = dashboardFilters.to.substring(0, 10);
        if (localStorage.getItem(`${key}-${from}-${to}`)) {
            const cachedObject = JSON.parse(localStorage.getItem(`${key}-${from}-${to}`));

            const currentDate = new Date();
            const tenMinutesAgo = new Date(currentDate.getTime() - cacheMinutes * 60 * 1000);

            const cachedDate = new Date(cachedObject.date);
            if (cachedDate > tenMinutesAgo) {
                return cachedObject.data;
            }
        }
        return null;
    };

    const getCategoryTypeSales = async () => {
        if (!dashboardFilters.from) {
            return false;
        }

        const cachedData = getLocalStorage('cacheCategoryTypeSales');
        if (cachedData) {
            setCategoryTypeSales(cachedData);
            return;
        }

        const category = 'financials';
        const report = 'category-and-type-sales';
        const group = 'list';

        const result = await ReportApi.getReport({ category, report, group, query: { ...dashboardFilters } });
        if (result) {
            setCategoryTypeSales(result);
            setLocalStorage('cacheCategoryTypeSales', result);
        }
    };

    // const getStaffSales = async () => {
    //     if (!dashboardFilters.from) {
    //         return false;
    //     }
    //
    //     const cachedData = getLocalStorage('cacheStaffSales');
    //     if (cachedData) {
    //         setStaffSales(cachedData);
    //         return;
    //     }
    //     const category = 'financials';
    //     const report = 'staff-sales-and-performance';
    //     const group = 'list';
    //
    //     const result = await ReportApi.getReport({ category, report, group, query: { ...dashboardFilters } });
    //     if (result) {
    //         setStaffSales(result);
    //         setLocalStorage('cacheStaffSales', result);
    //     }
    // };

    // const getAppointmentLog = async () => {
    //     if (!dashboardFilters.from) {
    //         return false;
    //     }
    //
    //     const cachedData = getLocalStorage('cacheAppointmentLog');
    //     if (cachedData) {
    //         setAppointmentLog(cachedData);
    //         return;
    //     }
    //
    //     const category = 'appointments';
    //     const report = 'appointment-log';
    //     const group = 'list';
    //
    //     const result = await ReportApi.getReport({ category, report, group, query: { ...dashboardFilters } });
    //     if (result) {
    //         setAppointmentLog(result);
    //         setLocalStorage('cacheAppointmentLog', result);
    //     }
    // };

    // const getPaymentTotals = async () => {
    //     if (!dashboardFilters.from) {
    //         return false;
    //     }
    //
    //     const cachedData = getLocalStorage('cachePaymentTotals');
    //     if (cachedData) {
    //         setPaymentTotals(cachedData);
    //         return;
    //     }
    //     const category = 'financials';
    //     const report = 'payment-totals';
    //     const group = 'list';
    //
    //     const result = await ReportApi.getReport({
    //         category,
    //         report,
    //         group,
    //         query: { ...dashboardFilters, excludeAccountPayments: true }
    //     });
    //     if (result) {
    //         setPaymentTotals(result);
    //         setLocalStorage('cachePaymentTotals', result);
    //     }
    // };

    const reloadAll = () => {
        setCategoryTypeSales(null);
        clearLocalStorage('cacheCategoryTypeSales', true);
        getCategoryTypeSales();
    };

    return (
        <div>
            <Box m={3} mt={1.5} mb={0}>
                <MaterialUIBreadcrumbs aria-label="breadcrumb" className={classes.reportTitleAlone}>
                    <Link onClick={() => history.push('/dashboard')}>Dashboard</Link>
                </MaterialUIBreadcrumbs>
            </Box>

            <Grid container>
                <Grid item md={3} sm={1}></Grid>
                <Grid item md={6}>
                    <DashboardFilter
                        cachedFilters={dashboardFilters}
                        setDashboardFilters={setDashboardFilters}
                        handleReload={reloadAll}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={4} sm={6}>
                    <TypeSalesComponent data={categoryTypeSales} filters={dashboardFilters} />
                </Grid>
                <Grid item md={4} sm={6}>
                    <CourseServiceCategories data={categoryTypeSales} filters={dashboardFilters} type="service" />
                </Grid>
                <Grid item md={4} sm={6}>
                    <CourseServiceCategories data={categoryTypeSales} filters={dashboardFilters} type="course" />
                </Grid>
                {/*<Grid item md={4} sm={6}>*/}
                {/*    <StaffSalesComponent data={staffSales} filters={dashboardFilters} />*/}
                {/*</Grid>*/}
                {/*<Grid item md={4} sm={6}>*/}
                {/*    <AppointmentListComponent data={appointmentLog} filters={dashboardFilters} />*/}
                {/*</Grid>*/}
                {/*<Grid item md={4} sm={6}>*/}
                {/*    <PaymentsTotalComponent data={paymentTotals} filters={dashboardFilters} />*/}
                {/*</Grid>*/}
            </Grid>
        </div>
    );
};

export default withStyles(styles)(Dashboard);
