import React from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CloseOutline } from '../../assets/icons';
import { closeBtnStyles as styles } from './styles';

const CloseBtn = ({ onClick, className, classes }) => {
    return (
        <IconButton className={classNames(classes.button, className)} onClick={onClick}>
            <CloseOutline />
        </IconButton>
    );
};

CloseBtn.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CloseBtn);
