const { toLocaleString } = require('../../../../../../collums-components/helpers/index');

const getColumns = () => {
    return [
        {
            title: 'Staff',
            field: 'staff'
        },
        {
            title: 'Client',
            field: 'client'
        },
        {
            title: 'Consultation',
            field: 'consultation'
        },
        {
            title: 'Date',
            field: 'date',
            render: data => data.date.toLocaleString()
        },
        {
            title: 'Converted',
            field: 'converted',
            render: data => (data.converted ? 'Yes' : 'No')
        },
        {
            title: 'Items sold',
            field: 'items_sold',
            render: data => JSON.stringify(data.items_sold)
        },
        {
            title: 'Product total',
            field: 'net_total_product',
            type: 'currency',
            render: data => toLocaleString(data.net_total_product)
        },
        {
            title: 'Treatment total',
            field: 'net_total_treatment',
            type: 'currency',
            render: data => toLocaleString(data.net_total_treatment)
        },
        {
            title: 'Sale total',
            field: 'sale_total',
            type: 'currency',
            render: data => toLocaleString(data.sale_total)
        },
        {
            title: 'Total CCT',
            field: 'net_total_treatment',
            type: 'currency',
            render: data => toLocaleString(data.net_total_treatment)
        }
    ];
};

export default getColumns;
