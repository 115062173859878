export const Columns = [
    {
        title: 'Promotion Discount',
        field: 'promotionDiscount'
    },
    {
        title: 'Net Discount',
        field: 'discountNet'
    },
    {
        title: 'Discount Tax',
        field: 'discountTax'
    },
    {
        title: 'Gross Discount',
        field: 'discountGross'
    },
    {
        title: 'Percentage',
        field: 'percentage'
    }
];
