import * as React from 'react';
import { Button } from '@material-ui/core';
import { CollumsTable } from './CollumsTable';
import styled from 'styled-components';

import LoadingScreen from '../../collums-components/components/common/loadingScreen';

function DefaultMaterialTable({
    filters,
    data,
    title,
    columns,
    filterBtn,
    loading,
    showTotalRow,
    setValues,
    canShowFilters = true,
    showFiltersOnHeader = true,
    report,
    useGrandTotalRows,
    TargetComponent = CollumsTable,
    targetComponentProps = {}
}) {
    /**
     * hide - not visible in table
     * hideCSV - not visible in csv
     */
    return (
        <>
            <TargetComponent
                columns={(() => {
                    return columns?.length ? columns.filter(el => !el?.hide) || [] : [];
                })()}
                columnsExport={(() => {
                    return columns?.length ? columns.filter(el => !el?.hideCSV) || [] : [];
                })()}
                isLoading={loading}
                rows={data}
                tableInfo={{
                    title,
                    filters,
                    showFilters: showFiltersOnHeader,
                    redemptionValuesCheckbox: title === 'Detailed Invoice Log',
                    showSelectAmountOfRows: title === 'Service Sales Performance',
                    report
                }}
                report={report}
                filterBtn={filterBtn}
                showTotalRow={showTotalRow}
                customLoadingComponent={<LoadingScreen />}
                setValues={setValues}
                canShowFilters={canShowFilters}
                useGrandTotalRows={useGrandTotalRows}
                customHead={
                    <CustomHeadWrapper>
                        <Button
                            color="secondary"
                            variant="none"
                            onClick={filterBtn.onClick}
                            style={{
                                backgroundColor: '#565a65',
                                color: '#FFF',
                                borderRadius: 0,
                                textTransform: 'none',
                                fontSize: 14,
                                fontWeight: 400,
                                padding: '6px 16px'
                            }}
                        >
                            {filterBtn.label || 'Filters'}
                        </Button>
                    </CustomHeadWrapper>
                }
                {...targetComponentProps}
            />
        </>
    );
}

const CustomHeadWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 5rem;
    &:first-child {
        margin-right: 1rem;
    }
`;

export default DefaultMaterialTable;
