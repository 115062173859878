import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import DatePeriodsFilter from '../../filters/components/DatePeriodsFilter';
import DateFilterInputs from '../../filters/components/DateFilterInputs';
import { Refresh } from '@material-ui/icons';

const DashboardFilter = ({ setDashboardFilters, handleReload, cachedFilters }) => {
    const [filters, setFilters] = useState(cachedFilters);
    const [toValue, setToValue] = useState(cachedFilters?.to);
    const [fromValue, setFromValue] = useState(cachedFilters?.from);

    useEffect(() => {
        if (filters.to && filters.from) {
            setToValue(filters.to);
            setFromValue(filters.from);
        }
        localStorage.setItem('reportingDashboardFilters', JSON.stringify(filters));
        setDashboardFilters(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const handleSetFilters = useCallback(
        filter => {
            setFilters({ ...filters, ...filter });
        },
        [filters]
    );

    return (
        <Box>
            <Typography variant="h4" style={{ fontSize: 12, fontWeight: 500 }}>
                Select a time period
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DatePeriodsFilter
                    handleFilterChange={handleSetFilters}
                    periodValue={filters.period || 'today'}
                    futureDatesOnly={false}
                    simple={true}
                />
                <DateFilterInputs
                    handleFilterChange={handleSetFilters}
                    filters={filters}
                    toValue={toValue}
                    fromValue={fromValue}
                    minDate={null}
                    disabled={false}
                    simple={true}
                />
                <IconButton onClick={handleReload}>
                    <Refresh />
                </IconButton>
            </div>
        </Box>
    );
};

export default DashboardFilter;
