import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import _ from 'lodash';
import { toLocaleString } from '../../../../../collums-components/helpers/index';

import TableInfo from '../../../../common/CollumsTable/components/TableInfo';
import { calculateNetPrice, calculateTaxValue } from '../../../../../utils/helpers/common';

import { categoryStyles } from './styles';
import LoadingScreen from '../../../../../collums-components/components/common/loadingScreen';
import exportCsv from '../../../../common/CollumsTable/helpers/exportCSV';
import { DropdownButton } from '../../../../common/DropdownButton';
import SearchInput from '../../../../common/CollumsTable/components/SearchInput';
import styled from 'styled-components';
import ReportFilterList from '../../../../common/ReportFilterList';

const StyledTableCell = withStyles(categoryStyles)(({ className, children, classes }) => (
    <TableCell className={classnames(classes.tableCell, className)}>{children}</TableCell>
));

const CustomHeadWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 5rem;
    &:first-child {
        margin-right: 1rem;
    }
`;

const AdditionalHeadRow = styled(TableRow)`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
`;

const SearchRow = styled.div`
    display: flex;
    width: 21.875rem;
`;

function Category({ values, query, classes, loading, filterBtn }) {
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const handleSearch = () => 1;

    const filters = query;

    const capitalize = s => (typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : '');
    const mapValues = cb => Object.keys(values).map(key => cb(values[key]));
    const calculatePercentageOf = (x, total) => {
        const percentage = (x / total) * 100;

        return (percentage + '').indexOf('.') > -1 ? percentage.toFixed(1) : percentage;
    };
    const getTotalListPrice = () => _.sum(mapValues(arr => _.sumBy(arr, o => o.listPrice)));
    const getTotalQuantity = () => _.sum(mapValues(arr => _.sumBy(arr, o => o.quantity)));
    const getTotalRefunds = () => _.sum(mapValues(arr => _.sumBy(arr, o => o.refunds)));
    const getTotalNetSales = () =>
        _.sum(mapValues(arr => _.sumBy(arr, o => calculateNetPrice(o.listPrice, o.discounts))));
    const getTotalTax = () =>
        _.sum(mapValues(arr => _.sumBy(arr, o => calculateTaxValue(o.listPrice, o.discounts, o.tax))));
    const getTotalDiscount = () => _.sum(mapValues(arr => _.sumBy(arr, o => o.discounts)));
    const getTotalSales = () => _.sum(mapValues(arr => _.sumBy(arr, o => o.totalNetSales)));

    const handlePageChange = (_, newPage) => setPage(newPage);
    const handleRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <AdditionalHeadRow>
                <ReportFilterList openFilterModal={filterBtn.onClick} runReport={filterBtn.runReport}>
                    <DropdownButton
                        label={'Options'}
                        options={[
                            {
                                label: 'Export as CSV',
                                handleClick: () => exportCsv([], { title: 'Sales By Category', filters })
                            }
                        ]}
                    />
                </ReportFilterList>
                <SearchRow>
                    <SearchInput
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value);
                        }}
                        onSearch={() => handleSearch}
                        onCancel={() => {
                            setSearch('');
                        }}
                    />
                    <CustomHeadWrapper>
                        <Button
                            color="secondary"
                            variant="none"
                            onClick={filterBtn.onClick}
                            style={{
                                backgroundColor: '#565a65',
                                color: '#FFF',
                                borderRadius: 4,
                                textTransform: 'none',
                                fontSize: 14,
                                fontWeight: 500,
                                padding: '6px 16px'
                            }}
                        >
                            {filterBtn.label || 'Filters'}
                        </Button>
                    </CustomHeadWrapper>
                </SearchRow>
            </AdditionalHeadRow>
            {loading && <LoadingScreen />}
            <TableInfo type="Category" sorting={true} filters={filters} />
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.boldCell}>Category</TableCell>

                            <TableCell className={classes.boldCell}>#</TableCell>

                            <TableCell className={classes.boldCell}>List Price</TableCell>

                            <TableCell className={classes.boldCell}>Gross Discounts</TableCell>

                            <TableCell className={classes.boldCell}>Refunds</TableCell>

                            <TableCell className={classes.boldCell}>Net Sales</TableCell>

                            <TableCell className={classes.boldCell}>% of total</TableCell>

                            <TableCell className={classes.boldCell}>Ave Net Sales</TableCell>

                            <TableCell className={classes.boldCell}>Tax</TableCell>

                            <TableCell className={classes.boldCell}>Total Sales</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <StyledTableCell className={classes.boldCell}>Total</StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>{getTotalQuantity()}</StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>
                                {toLocaleString(getTotalListPrice())}
                            </StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>
                                {toLocaleString(getTotalDiscount())}
                            </StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>
                                {toLocaleString(getTotalRefunds())}
                            </StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>
                                {toLocaleString(getTotalNetSales())}
                            </StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>100%</StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>
                                {toLocaleString(getTotalNetSales() / getTotalQuantity())}
                            </StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>
                                {toLocaleString(getTotalTax())}
                            </StyledTableCell>

                            <StyledTableCell className={classes.boldCell}>
                                {toLocaleString(getTotalSales())}
                            </StyledTableCell>
                        </TableRow>

                        {Object.keys(values).map(type => (
                            <React.Fragment key={`${type}-totalRow`}>
                                <TableRow>
                                    <StyledTableCell className={classes.boldCell}>
                                        {capitalize(type)} Total
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {_.sumBy(values[type], o => o.quantity)}
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {toLocaleString(_.sumBy(values[type], o => o.listPrice))}
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {toLocaleString(_.sumBy(values[type], o => o.discounts))}
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {toLocaleString(_.sumBy(values[type], o => o.refunds))}
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {toLocaleString(
                                            _.sumBy(values[type], o => calculateNetPrice(o.listPrice, o.discounts))
                                        )}
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {calculatePercentageOf(
                                            _.sumBy(values[type], o => o.quantity),
                                            _.sum(mapValues(arr => _.sumBy(arr, o => o.quantity)))
                                        )}
                                        %
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {toLocaleString(
                                            _.sumBy(
                                                values[type],
                                                o => calculateNetPrice(o.listPrice, o.discounts) / o.quantity
                                            )
                                        )}
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {toLocaleString(
                                            _.sumBy(values[type], o =>
                                                calculateTaxValue(o.listPrice, o.discounts, o.tax)
                                            )
                                        )}
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.boldCell}>
                                        {toLocaleString(_.sumBy(values[type], o => o.totalNetSales))}
                                    </StyledTableCell>
                                </TableRow>

                                {values[type].map((item, i) => (
                                    <TableRow key={i}>
                                        <StyledTableCell>
                                            {/*eslint-disable-next-line */}
                                            <a href="#" className={classes.categoryLink}>
                                                {item.name}
                                            </a>
                                        </StyledTableCell>

                                        <StyledTableCell>{item.quantity}</StyledTableCell>

                                        <StyledTableCell>{toLocaleString(item.listPrice)}</StyledTableCell>

                                        <StyledTableCell>{toLocaleString(item.discounts)}</StyledTableCell>

                                        <StyledTableCell>{toLocaleString(item.refunds)}</StyledTableCell>

                                        <StyledTableCell>
                                            {toLocaleString(calculateNetPrice(item.listPrice, item.discount))}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {calculatePercentageOf(
                                                item.quantity,
                                                _.sumBy(values[type], o => o.quantity)
                                            )}
                                            %
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {toLocaleString(
                                                calculateNetPrice(item.listPrice, item.discounts) / item.quantity
                                            )}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {toLocaleString(
                                                calculateTaxValue(item.listPrice, item.discounts, item.tax)
                                            )}
                                        </StyledTableCell>

                                        <StyledTableCell>{toLocaleString(item.totalNetSales)}</StyledTableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    component="div"
                    count={Object.keys(values).length + _.sum(Object.values(values).map(arr => arr.length + 1))}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
            </TableContainer>
        </>
    );
}

Category.propTypes = {
    values: PropTypes.object.isRequired,
    query: PropTypes.object,
    classes: PropTypes.object.isRequired
};

export default withStyles(categoryStyles)(Category);
