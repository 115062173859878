export const categoryStyles = () => ({
    boldCell: {
        fontWeight: 'bold'
    },
    categoryLink: {
        color: '#0000EE',
        textDecoration: 'underline'
    },
    toolbarTitle: {
        display: 'block'
    },
    table: {
        '& *': {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
        }
    },
    tableCell: {
        width: 'calc((100% - 0px) / 12)',
        boxSizing: 'border-box',
        fontSize: 'inherit',
        fontFamily: 'inherit'
    }
});
