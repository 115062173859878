import React from 'react';
import { Breadcrumbs as MaterialUIBreadcrumbs, Link, Typography, withStyles } from '@material-ui/core';

import { useParams, useHistory } from 'react-router-dom';
import { getCategoryObj, getReportObj, isValidCategory, isValidReport } from '../../utils/helpers/reports';
import { breadcrumbStyles } from './styles';

function Breadcrumb({ classes }) {
    const history = useHistory();
    const { category, report, group } = useParams();
    return (
        <MaterialUIBreadcrumbs
            aria-label="breadcrumb"
            className={isValidCategory(category) ? classes.reportTitle : classes.reportTitleAlone}
        >
            <Link onClick={() => history.push('/reports')}>Reports</Link>
            {isValidCategory(category) && <Typography color="textPrimary">{getCategoryObj(category).label}</Typography>}
            {isValidReport(category, report) && (
                <Typography color="textPrimary">{getReportObj(category, report).label}</Typography>
            )}
            {group && <Typography color="textPrimary">Groups</Typography>}
            {group && <Typography color="textPrimary">{group}XXX</Typography>}
        </MaterialUIBreadcrumbs>
    );
}

export default withStyles(breadcrumbStyles)(Breadcrumb);
