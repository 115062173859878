import React from 'react';
import config from '../../../../../config';
import { linkableCell } from '../../../../../style/reportStyles';
import { toLocaleString } from '../../../../../collums-components/helpers/index';

const columns = [
    { title: 'Staff', field: 'practitioner' },
    { title: 'Date', field: 'date', type: 'date' },
    {
        title: 'Client',
        field: 'customerName',
        // eslint-disable-next-line
        render: data => {
            return (
                <span
                    style={linkableCell}
                    onClick={() => {
                        window.open(`${config.calendarUrl}/customer/${data.customerId}/general`, '_blank');
                    }}
                >
                    {data.customerName}
                </span>
            );
        }
    },
    { title: 'Clients Email', field: 'customerEmail', hide: true },
    { title: 'Clients Phone', field: 'customerPhone', hide: true },
    { title: 'SMS Marketing', field: 'customerMarketingSMS', hide: true },
    { title: 'Email Marketing', field: 'customerMarketingEmail', hide: true },
    { title: 'Service Booked', field: 'bookedService' },
    {
        title: 'Service Performed',
        field: 'performedService'
    },
    {
        title: 'Price',
        field: 'price',
        type: 'currency',
        // eslint-disable-next-line
        render: data => <span style={data.price < 0 ? { color: 'red' } : {}}>{toLocaleString(data.price)}</span>
    },
    {
        title: 'Days since',
        field: 'daysSinceGroup',
        type: 'group',
        subColumns: [
            {
                title: 'Last Category Sale',
                field: 'lastCategorySaleDate',
                type: 'quantity'
            },
            {
                title: 'Last Visit',
                field: 'lastVisit',
                type: 'quantity'
            }
        ],
        renders: [
            data => {
                return <span>{data?.lastCategorySaleDate}</span>;
            },
            data => {
                return <span>{data?.lastVisit}</span>;
            }
        ]
    },
    {
        title: 'Future cat service booked',
        field: 'haveFutureBooking'
    }
];

export default columns;
