export const periodFilter = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: '7days', label: 'Last 7 Days' },
    { value: '30days', label: 'Last 30 Days' },
    { value: '90days', label: 'Last 90 Days' },
    { value: 'lastWeek', label: 'Last week' },
    { value: 'lastMonth', label: 'Last month' },
    { value: 'lastYear', label: 'Last Year' },
    { value: 'weekToDate', label: 'Week to date' },
    { value: 'monthToDate', label: 'Month to date' },
    { value: 'quarterToDate', label: 'Quarter to date' },
    { value: 'yearToDate', label: 'Year to date' },
    { value: 'allTime', label: 'All time' },
    { value: 'custom', label: 'Custom' }
];

export const futurePeriodFilter = [
    { value: 'today', label: 'Today' },
    { value: 'restOfWeek', label: 'Rest of week' },
    { value: 'nextWeek', label: 'Next week' },
    { value: 'restOfMonth', label: 'Rest of month' },
    { value: 'nextMonth', label: 'Next month' },
    { value: 'custom', label: 'Custom' }
];

export const piecesOfTimeInPast = [
    { value: 'allTime', label: 'All time' },
    { value: 'lastThreeMonths', label: 'Last 3 months' },
    { value: 'lastSixMonths', label: 'Last 6 months' },
    { value: 'lastYear', label: 'Last year' },
    { value: 'lastThreeYears', label: 'Last 3 years' }
];
