const reportsWithoutFilters = [
    'invoice-log',
    'top-spender',
    'consultation-conversion-log',
    'conversion-by-staff-basic',
    'conversion-by-staff-advanced',
    'conversion-by-location',
    'upsell-log',
    'upsell-by-staff',
    'upsell-by-location',
    'rebookings',
    'category-and-type-sales',
    'special-offer-sales',
    'staff-commission-sales',
    'staff-sales-and-performance',
    'detailed-invoice-log',
    'payment-log',
    'payment-totals',
    'outstanding-courses',
    'outstanding-acc-balances',
    'gift-voucher-totals',
    'outstanding-gift-vouchers',
    'vat',
    'service-sales-performance',
    'toxin-usage-summary',
    'toxin-usage-by-area',
    'toxin-usage-by-staff',
    'toxin-usage-log',
    'appointment-log-and-slippage',
    'appointment-log'
];
export default reportsWithoutFilters;
