export const Columns = [
    {
        title: 'Category',
        field: 'name'
    },
    {
        title: '#',
        field: 'quantity'
    },
    {
        title: 'Discount',
        field: 'discount'
    },
    {
        title: 'Net Price',
        field: 'netPrice'
    },
    {
        title: 'Tax',
        field: 'tax'
    },
    {
        title: 'Total',
        field: 'grossPrice'
    },
    {
        title: 'Ave Net Sale',
        field: 'aveTotalSale'
    },
    {
        title: '% Net Total',
        field: 'percentage'
    }
];
