import { toLocaleString } from '../../../../../../collums-components/helpers';

const getColumns = () => {
    return [
        {
            title: 'Staff',
            field: 'displayName'
        },
        {
            title: '#',
            field: 'count',
            type: 'quantity'
        },
        {
            title: 'Conv #',
            field: 'converted'
        },
        {
            title: 'Conv %',
            field: 'converted_rate',
            type: 'percentage',
            render: data =>
                (Number(data.converted_rate) * 100).toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%'
        },
        {
            title: 'Net Total',
            field: 'net_total',
            type: 'currency',
            render: data => toLocaleString(data.net_total)
        },
        {
            title: 'Avg Net Total',
            field: 'avg_net_total',
            type: 'currency',
            render: data => toLocaleString(data.avg_net_total)
        }
    ];
};

export default getColumns;
