import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';

function Vat({ values, query, filterBtn, loading, setValues }) {
    const filters = query;

    const columns = [
        {
            title: 'Tax name',
            field: 'taxName',
            type: 'grandTotal',
            // eslint-disable-next-line react/display-name
            render: data => <span style={data.taxName < 0 ? { color: 'red' } : {}}>{data.taxName}</span>
        },
        {
            title: 'Net Sales',
            field: 'netSales',
            type: 'currency',
            // eslint-disable-next-line react/display-name
            render: data => (
                <span style={data.netSales < 0 ? { color: 'red' } : {}}>{toLocaleString(data.netSales)}</span>
            )
        },
        {
            title: 'Tax %',
            field: 'taxPercentage',
            type: 'percentage',
            // eslint-disable-next-line react/display-name
            render: data => <span style={data.taxPercentage < 0 ? { color: 'red' } : {}}>{data.taxPercentage}%</span>
        },
        {
            title: 'Tax',
            field: 'tax',
            type: 'currency',
            // eslint-disable-next-line react/display-name
            render: data => <span style={data.tax < 0 ? { color: 'red' } : {}}>{toLocaleString(data.tax)}</span>
        },
        {
            title: 'Total',
            field: 'total',
            type: 'currency',
            // eslint-disable-next-line react/display-name
            render: data => <span style={data.total < 0 ? { color: 'red' } : {}}>{toLocaleString(data.total)}</span>
        },
        {
            title: 'Location',
            field: 'location',
            // eslint-disable-next-line react/display-name
            render: data => <span style={data.location < 0 ? { color: 'red' } : {}}>{data.location}</span>
        }
    ];

    return (
        <DefaultMaterialTable
            title={'VAT'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
        />
    );
}

export default Vat;
