import React, { useEffect, useState } from 'react';
import { Button, Popover, withStyles } from '@material-ui/core';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { startOfDay, endOfDay } from 'date-fns';
import locale from 'date-fns/locale/en-GB';
import { modal } from '../styles';

function DateRangeFilter({
    classes,
    handleFilterChange,
    fromValue,
    toValue,
    open,
    onClose,
    anchorEl,
    minDate,
    disabled
}) {
    const [from, setFrom] = useState(startOfDay(new Date()));
    const [to, setTo] = useState(endOfDay(new Date()));

    useEffect(() => {
        if (toValue) {
            setTo(endOfDay(new Date(toValue)));
        }
        if (fromValue) {
            setFrom(startOfDay(new Date(fromValue)));
        }
    }, [toValue, fromValue]);

    const onConfirmChanges = () => {
        const newFromAsMoment = moment(from);

        const isSameDay = dateToCompare => {
            return newFromAsMoment.isSame(moment(dateToCompare), 'day');
        };

        // Need to overwrite time zone to prevent incorrect hours format for only date picker.
        handleFilterChange({
            to: moment(to).format('YYYY-MM-DD'),
            from: moment(from).format('YYYY-MM-DD'),
            period: isSameDay(to) && isSameDay() ? 'today' : 'custom'
        });

        onClose();
    };

    return (
        <Popover
            id="popover-id"
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOriginal={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <div className={`${disabled ? classes.disableRangeInputs : ''}`}>
                <DateRangePicker
                    ranges={[
                        {
                            startDate: from,
                            endDate: to,
                            key: 'selection'
                        }
                    ]}
                    onChange={range => {
                        const newFrom = range.selection.startDate;
                        const newTo = range.selection.endDate;
                        setFrom(newFrom);
                        setTo(newTo);
                    }}
                    renderStaticRangeLabel={() => <></>}
                    staticRanges={[]}
                    inputRanges={[]}
                    locale={locale}
                    calendarStartDay={1}
                    minDate={minDate || undefined}
                />
            </div>
            <div className={`${classes.actions} ${classes.marginButtons} ${classes.centered}`}>
                <Button onClick={onClose} variant="outlined" disabled={disabled}>
                    Cancel
                </Button>
                <Button onClick={onConfirmChanges} disabled={disabled}>
                    Confirm
                </Button>
            </div>
        </Popover>
    );
}

export default withStyles(modal)(DateRangeFilter);
