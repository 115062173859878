import React, { useEffect, useState } from 'react';
import { Box, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BOTOX_TREATMENT_AREAS } from '../../../collums-constants';
import getMissingFilters from '../../../utils/helpers/getMissingFilters';
import { filters as filterStyles } from './styles';

function AreaFilter({ classes, label, handleFilterChange, filters, report }) {
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [isFieldRequired, setIsFieldRequired] = useState(false);

    useEffect(() => {
        setIsFieldRequired(getMissingFilters(report, {}).includes('areas'));
    }, [report]);

    useEffect(() => {
        const value = filters['areas'] || [];
        setSelectedAreas(value);
    }, [filters]);

    const handleChange = (_, items) => {
        setSelectedAreas(items);
        handleFilterChange({
            areas: items
        });
    };

    return (
        <Box marginTop={4}>
            <Autocomplete
                multiple
                className={classes.autocomplete}
                options={Object.values(BOTOX_TREATMENT_AREAS)}
                value={selectedAreas}
                onChange={handleChange}
                label={label}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInputProps
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            className: classes.autocompleteLabelProps
                        }}
                        error={!selectedAreas?.length && isFieldRequired}
                    />
                )}
                renderOption={option => {
                    return <p className={classes.autocompleteOptions}>{option}</p>;
                }}
                ChipProps={{ className: classes.autocompleteChipProps }}
            />
        </Box>
    );
}

export default withStyles(filterStyles)(AreaFilter);
