import * as actions from './types';

/**
 * @param {Object} payload
 * @param {String} payload.searchBarFilter
 */
export const setSearchBarFilter = payload => dispatch => {
    return dispatch({
        type: actions.SET_REPORTS_SEARCH_BAR_FILTER,
        payload
    });
};

export const setAccordionsState = payload => dispatch => {
    return dispatch({
        type: actions.SET_ACCORDIONS_STATE,
        payload
    });
};

export const setFiltersState = payload => dispatch => {
    return dispatch({
        type: actions.SET_FILTERS_STATE,
        payload
    });
};

export const clearFiltersState = payload => dispatch => {
    return dispatch({
        type: actions.CLEAR_FILTERS_STATE,
        payload
    });
};
