import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';

function Loading() {
    return (
        <>
            <LoadingBox
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </LoadingBox>
        </>
    );
}

const LoadingBox = styled(Box)`
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    z-index: 10000;
`;

export default Loading;
