import React, { useState } from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import EditGiftcardExpiryModal from '../../../../common/EditGiftcardExpiryModal';
import getColumns from './columns';

function Result({ values, query, filterBtn, loading, setValues, setInvoice }) {
    const [editingGiftcard, setEditingGiftcard] = useState(null);

    const openEditGiftcardModal = (giftcard) => {
        setEditingGiftcard(giftcard);
    };

    const filters = query;
    const columns = getColumns(setInvoice, openEditGiftcardModal);

    return (
        <>
            <DefaultMaterialTable
                title={'Outstanding Gift Vouchers'}
                filters={filters}
                columns={columns}
                data={values}
                options={{ sorting: true }}
                filterBtn={filterBtn}
                loading={loading}
                setValues={setValues}
            />
          <EditGiftcardExpiryModal
            giftcard={editingGiftcard}
            setGiftcard={setEditingGiftcard}
            refreshReport={filterBtn.runReport}
            />
        </>
    );
}

export default Result;
