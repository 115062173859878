import Axios from 'axios';
import moment from 'moment';
import { TOKEN } from '../constants/LocalStorage';

const config = {
    backendUrl: process.env.REACT_APP_BACKEND_URL || 'https://staging.api.collums.co.uk',
    token: null
};

export const setup = ({ token }) => {
    config.token = token;
};

export default options =>
    Axios({
        ...options,
        headers: {
            Authorization: localStorage.getItem(TOKEN),
            ...(options.headers || {})
        },
        baseURL: config.backendUrl,
        transformResponse: [data => stringToMomentTransformer(JSON.parse(data))]
    });

function stringToMomentTransformer(o) {
    Object.keys(o).forEach(function (k) {
        if (o[k] !== null && typeof o[k] === 'object') {
            o[k] = stringToMomentTransformer(o[k]);
            return;
        }
        if (
            typeof o[k] === 'string' &&
            /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)(\.\d+([+-][0-2]\d:[0-5]\d|Z)|(\+\d{2}:\d{2}))/.test(o[k])
        ) {
            o[k] = moment(o[k]);
        }
    });
    return o;
}
