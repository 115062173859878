import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers/index';

const getColumns = () => [
    { title: '', field: 'id' },
    { title: 'Sold', field: 'sold', type: 'quantity' },
    {
        title: 'Vouchers sold',
        field: 'valueSold',
        type: 'currency',
        //eslint-disable-next-line
        render: data => <span style={data.valueSold < 0 ? { color: 'red' } : {}}>{toLocaleString(data.valueSold)}</span>
    },
    {
        title: 'Ave Value',
        field: 'avgValue',
        type: 'currency',
        //eslint-disable-next-line
        render: data => <span style={data.avgValue < 0 ? { color: 'red' } : {}}>{toLocaleString(data.avgValue)}</span>
    },
    {
        title: 'Used',
        field: 'used',
        type: 'currency',
        //eslint-disable-next-line
        render: data => <span style={data.used < 0 ? { color: 'red' } : {}}>{toLocaleString(data.used)}</span>
    },
    {
        title: 'Outstanding',
        field: 'outstandingValue',
        type: 'currency',
        //eslint-disable-next-line
        render: data => (
            <span style={data.outstandingValue < 0 ? { color: 'red' } : {}}>
                {toLocaleString(data.outstandingValue)}
            </span>
        )
    }
];

export default getColumns;
