import React from 'react';
import { Button } from '@material-ui/core';
import { useState } from 'react';
import BarChartIcon from '@material-ui/icons/BarChart';
import CloseIcon from '@material-ui/icons/Close';

export const HidableChartContainer = ({ children }) => {
    const [closed, setClosed] = useState(false);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => setClosed(c => !c)}>{closed ? <BarChartIcon /> : <CloseIcon />}</Button>
            </div>
            <div style={{ height: '500px', display: closed ? 'none' : 'block' }}>{children}</div>
        </>
    );
};
