import React, { useLayoutEffect, useState, useEffect } from 'react';
import DefaultMaterialTable from '../../../../common/DefaultMaterialTable';
import defaultColumns from './defaultColumns';
import generateToxinColumn from '../../../../../utils/helpers/generateToxinColumn';
import ToxinTableResults from './ToxinTableResults';

function Result({ values, query, filterBtn, loading }) {
    const filters = query;
    const [columns, setColumns] = useState([...defaultColumns, generateToxinColumn('Total')]);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (query.areas) {
            const rowValues = values.reduce((acc, el) => {
                return [
                    ...acc,
                    { id: el.id, name: el.name, isToxinRow: true },
                    ...el.staffs.map(staff => ({ ...staff, id: `${staff.id}-${el.id}`, toxin: el.id }))
                ];
            }, []);
            setData(rowValues);
        }
    }, [values, query]);

    useLayoutEffect(() => {
        if (query.areas) {
            const newColumns = [
                ...defaultColumns,
                ...query.areas.map(generateToxinColumn),
                generateToxinColumn('Total')
            ];
            setColumns(newColumns);
        }
    }, [query]);

    return (
        <DefaultMaterialTable
            title={'Toxin usage by staff'}
            filters={filters}
            columns={columns}
            data={data}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setData}
            canShowFilters
            showFiltersOnHeader
            TargetComponent={ToxinTableResults}
        />
    );
}

export default Result;
