import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers';
import { useReportFilters } from '../../../../../hooks/ReportFilters';
import config from '../../../../../config';
import Moment from 'moment';
import { linkableCell } from '../../../../../style/reportStyles';

const columns = [
    {
        title: 'Toxin',
        field: 'name',
        // eslint-disable-next-line
        Component: data => {
            const { savedFilters } = useReportFilters();

            return (
                <span
                    style={linkableCell}
                    onClick={() => {
                        const getFormattedTime = value => {
                            return Moment(value).format('DD-MM-YYYY');
                        };

                        window.open(
                            `${config.reportingUrl}/reports/category/stock/report/toxin-usage-by-area?toxin=${
                                data.name
                            }&from=${getFormattedTime(savedFilters.from)}&to=${getFormattedTime(
                                savedFilters.to
                            )}&clinic=${savedFilters.clinic}&period=${savedFilters.period}`,
                            '_blank'
                        );
                    }}
                >
                    {data.name}
                </span>
            );
        }
    },
    { title: 'Clients', field: 'clientCount', type: 'quantity' },
    {
        title: 'Units Used',
        field: 'units',
        type: 'quantity'
    },
    {
        title: 'Vials Used',
        field: 'vialsUsed',
        type: 'quantity'
    },
    {
        title: 'Ave units',
        field: 'aveUnits',
        type: 'quantity'
    },
    {
        title: 'Total Cost',
        field: 'totalCost',
        type: 'currency',
        // eslint-disable-next-line
        render: data => <span style={data.totalCost < 0 ? { color: 'red' } : {}}>{toLocaleString(data.totalCost)}</span>
    },
    {
        title: 'Ave cost per client',
        field: 'aveCostPerClient',
        type: 'currency',
        // eslint-disable-next-line
        render: data => (
            <span style={data.totalCost < 0 ? { color: 'red' } : {}}>{toLocaleString(data.aveCostPerClient)}</span>
        )
    }
];

export default columns;
