import React from 'react';

import WithStyles from '@material-ui/styles/withStyles';
import { queryItemStyles } from './styles';

import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const QueryItemBox = ({ classes, label, action }) => {
    return (
        <Button variant="outlined" className={classes.button} endIcon={<OpenInNewIcon />} onClick={action}>
            {label}
        </Button>
    );
};

export default WithStyles(queryItemStyles)(QueryItemBox);
