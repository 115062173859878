export const header = theme => ({
    noShadow: {
        boxShadow: 'none',
        '& button': {
            padding: 0,
            '& span': {
                height: '38px'
            }
        },
        height: 38,
        minHeight: 38
    },
    whiteBar: {
        background: 'white',
        color: 'black'
    },
    tabsContainer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '28px',
        height: '38px',
        backgroundColor: '#69767E',
        padding: 0
    },
    tabIndicator: {
        backgroundColor: theme.palette.white.main,
        height: 0
    },
    mainTab: {
        opacity: 1,
        fontSize: 15,
        fontWeight: 400,
        backgroundColor: '#69767E',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '&.Mui-selected': {
            backgroundColor: '#565A65'
        },
        '& a': {
            color: 'white',
            height: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            textAlign: 'center',
            whiteSpace: 'nowrap'
        },
        maxWidth: 250,
        width: 250
    },
    subTab: {
        opacity: 1,
        fontSize: 12,
        fontWeight: 'bold',
        color: 'black',
        '& a': {
            color: 'black',
            width: '100%',
            height: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
});

export const queryItemStyles = () => ({
    button: {
        backgroundColor: '#4a4d57',
        color: '#fff',
        width: 'auto',
        height: 38,
        borderRadius: 0,
        marginBottom: 16,
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 400,
        padding: '6px 16px',

        '&:hover': {
            backgroundColor: '#41444d'
        }
    }
});

export const reportFilterListStyles = () => ({
    boxContainer: {
        '& button': {
            marginRight: '10px'
        },
        display: 'flex'
    }
});

export const breadcrumbStyles = () => ({
    reportTitle: {
        fontWeight: 400,
        '& a': {
            fontSize: 14,
            color: '#3083EC'
        },
        '& p': {
            fontSize: 14,
            fontWeight: 400
        }
    },
    reportTitleAlone: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 12,
        fontSize: 18,
        fontWeight: 500,
        '& a': {
            color: ' rgba(0, 0, 0, 0.87)'
        }
    }
});

export const closeBtnStyles = () => ({
    button: {
        padding: 8,
        '& .material-icons': {
            width: 18,
            height: 18
        },
        '& .img': {
            width: 18,
            height: 18
        }
    }
});

export const modal = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 25%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none'
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& h3': {
            fontFamily: 'Gilmer Bold',
            margin: '0 0 14px 0'
        }
    },
    content: {
        overflowY: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    form: {
        width: '85%',
        display: 'flex',
        flexDirection: 'column',
        '& h4': {
            margin: 0,
            width: '100%',
            textAlign: 'left',
            fontFamily: 'Gilmer Regular'
        },
        '& p': {
            margin: '8px 0',
            fontFamily: 'Gilmer Regular'
        },
        '& form': {
            display: 'flex',
            flexDirection: 'column',
            '& p': {
                width: '25%',
                textAlign: 'right',
                marginRight: 4
            },
            '& div': {
                display: 'flex',
                margin: '3px 0',
                alignItems: 'center'
            },
            '& span': {
                display: 'flex',
                alignItems: 'center',
                margin: '0 4px'
            }
        }
    },
    frequencyForm: {
        display: 'flex',
        flexDirection: 'row'
    },
    frequencyInput: {
        display: 'flex',
        width: '50%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& p': {
            width: '50% !important'
        }
    },
    emailInput: {
        width: '70%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'start',
        '& div': {
            width: '100%'
        }
    },
    sendEmailForm: {
        display: 'flex',
        width: '85%',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            fontFamily: 'Gilmer Regular, Roboto'
        },
        '& .MuiTextField-root': {
            width: '100%',
            marginLeft: '2.5%'
        }
    },
    helpIcon: {
        color: 'lightblue',
        cursor: 'pointer',
        marginLeft: 4
    },
    sendTest: {
        color: 'blue',
        cursor: 'pointer',
        marginLeft: '12.5% !important'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    }
});

export const salesBy = () => ({
    formControl: {
        minWidth: 140
    },
    selectRoot: {
        backgroundColor: 'white'
    },
    runBtn: {
        background: '#B41678',
        color: '#FFF',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
            background: '#B41678',
            boxShadow: 'none'
        },
        '&.MuiButton-root': {
            '&.Mui-disabled': {
                backgroundColor: '#ccc'
            }
        }
    },
    backBtn: {
        background: 'white',
        textTransform: 'none',
        fontSize: 12,
        boxShadow: 'none',
        color: '#7C7E86',
        borderColor: '#7C7E86',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    },
    autocomplete: {
        '& .MuiInputBase-root': {
            height: 40
        },
        '& .MuiAutocomplete-tag': {
            marginTop: -9
        }
    },
    datepicker: {
        backgroundColor: '#FFF',
        width: 180
    },
    marginLeft12px: {
        marginLeft: 12
    }
});

export const filter = () => ({
    formControl: {
        minWidth: 170
    },
    selectRoot: {
        backgroundColor: 'white'
    }
});

export const dropdownButtonStyles = () => ({
    optionsButton: {
        backgroundColor: '#3083EC',
        color: '#fff',
        width: 80,
        height: 38,
        borderRadius: 0,
        marginBottom: 16,
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 400,
        padding: '6px 16px',

        '&:hover': {
            backgroundColor: '#3083EC'
        }
    }
});
