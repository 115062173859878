import { useState, useEffect } from 'react';

import { getSearchBarFilter } from '../../redux/selectors/reports';
import { useSelector } from 'react-redux';

import ReportApi from '../../api/ReportApi';
import reports from '../../constants/reports';
import categories from '../../constants/reportCategories';

/**
 * @returns {Array}
 */
export const useFavourites = () => {
    const [favourites, setFavourites] = useState([]);
    useEffect(() => {
        ReportApi.getFavourites().then(setFavourites).catch(console.error);
    }, []);
    return favourites;
};

export const useScheduled = () => {
    const [scheduled, setScheduled] = useState([]);
    useEffect(() => {
        ReportApi.getScheduleds().then(setScheduled).catch(console.error);
    }, []);
    return scheduled;
};

/**
 * @typedef {Object} Filtered
 * @property {String} categories - Categories filtered.
 * @property {String} reports - Reports filtered.
 */

/**
 * Filter by category and report.
 * If the category matches, it returns all the reports of the given category.
 * If the category don't match but the report does, it returns only the matching reports of that category.
 @todo Needs improvement and simplification.
 @returns {Filtered} Filtered report and categories.
 */
export const useFiltered = () => {
    const [filtered, setFiltered] = useState({ categories: [], reports: [] });

    const searchBarFilter = useSelector(getSearchBarFilter);

    useEffect(() => {
        let filtered = { categories: {}, reports: {} };
        for (const categoryKey in categories) {
            //category match
            if (categories[categoryKey].label.toLowerCase().includes(searchBarFilter.toLowerCase())) {
                filtered.categories[categoryKey] = categories[categoryKey];
                filtered.reports[categoryKey] = reports[categoryKey];
            }
            //report match
            else {
                for (const reportKey in reports[categoryKey]) {
                    if (reports[categoryKey][reportKey].label.toLowerCase().includes(searchBarFilter.toLowerCase())) {
                        filtered.categories[categoryKey] = categories[categoryKey];
                        filtered.reports[categoryKey] = filtered.reports[categoryKey]
                            ? filtered.reports[categoryKey]
                            : {};
                        filtered.reports[categoryKey][reportKey] = reports[categoryKey][reportKey];
                    }
                }
            }
        }
        setFiltered(filtered);
    }, [searchBarFilter]);

    return filtered;
};
