import React from 'react';
import { toLocaleString } from '../../../../../collums-components/helpers/index';
import { Link } from '@material-ui/core';
import apps from '../../../../../config';

const getColumns = (setInvoice, openEditGiftcardModal) => [
    { title: 'Date/Time', field: 'createdAt' },
    {
        title: 'Invoice',
        field: 'invoice',
        type: 'unique',
        //eslint-disable-next-line
        render: data => (
            <Link
                onClick={evt => {
                    evt.stopPropagation();
                    setInvoice({ id: data.invoiceId });
                }}
                style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
            >
                {data.invoice}
            </Link>
        )
    },
    {
        title: 'Client',
        field: 'client',
        type: 'unique',
        //eslint-disable-next-line
        render: data => (
            <Link
                onClick={() => {
                    window.open(`${apps.calendarUrl}/customer/${data.clientId}/general`);
                }}
                style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
            >
                {data.client}
            </Link>
        )
    },
    {
        title: 'Voucher No',
        field: 'voucherNo',
        type: 'number',
        render: data => (
            <Link
                onClick={() => {
                    openEditGiftcardModal(data);
                }}
                style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
            >
                {data.voucherNo}
            </Link>
        )
    },
    {
        title: 'Voucher Value',
        field: 'value',
        type: 'currency',
        //eslint-disable-next-line
        render: data => <span style={data.value < 0 ? { color: 'red' } : {}}>{toLocaleString(data.value)}</span>
    },
    {
        title: 'Outstanding',
        field: 'outstandingValue',
        type: 'currency',
        //eslint-disable-next-line
        render: data => (
            <span style={data.outstandingValue < 0 ? { color: 'red' } : {}}>
                {toLocaleString(data.outstandingValue)}
            </span>
        )
    },
    {
        title: 'Exp Date',
        field: 'expDate'
    },
    {
        title: 'Location',
        field: 'location'
    }
];

export default getColumns;
