import { periodFilter, futurePeriodFilter } from './periodFilter';

export default function getSelectedPeriod(period) {
    let findPeriod = periodFilter.find(periodItem => periodItem.value === period);

    if (!findPeriod) {
        findPeriod = futurePeriodFilter.find(periodItem => periodItem.value === period);
    }

    if (findPeriod) return `(${findPeriod.label})`;
    return '';
}
