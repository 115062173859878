export const list = theme => ({
    accordion: {
        '&.Mui-disabled': {
            backgroundColor: 'white !important'
        }
    },
    reportLabel: {
        color: 'black'
    },
    accordionSummary: {
        '& p': {
            lineHeight: 2.5,
            fontSize: 18,
            fontWeight: 400
        }
    },
    tableHeadStyles: {
        '& tr': {
            '& th': {
                '& p': {
                    fontSize: 14,
                    fontWeight: 500
                }
            }
        }
    },
    tableBodyStyles: {
        '& tr': {
            '& td': {
                fontSize: 14,
                fontWeight: 400
            },
            '& a': {
                color: theme.palette.blue.main
            }
        }
    }
});

export const search = () => ({});
