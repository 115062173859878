const reportsWithStaffListFilter = [
    'appointment-log-and-slippage',
    'toxin-usage-by-staff',
    'toxin-usage-log',
    'conversion-by-staff-basic',
    'conversion-by-staff-advanced',
    'consultation-conversion-log',
    'upsell-log'
];

export default reportsWithStaffListFilter;
