import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import DefaultMaterialTable from '../../../../../common/DefaultMaterialTable';
import getColumns from './columns';
import { toLocaleString } from '../../../../../../collums-components/helpers';
import { HidableChartContainer } from '../../../../../common/HidableChartContainer';

function Items({ values, query, filterBtn, loading, setValues }) {
    const filters = query;
    const columns = getColumns();

    return (
        <DefaultMaterialTable
            title={'Upsell by Location'}
            filters={filters}
            columns={columns}
            data={values}
            options={{ sorting: true }}
            filterBtn={filterBtn}
            loading={loading}
            setValues={setValues}
            canShowFilters={false}
            showFiltersOnHeader={false}
            targetComponentProps={{
                contentTop: (
                    <HidableChartContainer>
                        <ResponsiveBar
                            groupMode="grouped"
                            data={values.map(data => ({
                                label: data.clinic,
                                Service: data.upsellService,
                                Course: data.upsellCourse,
                                Product: data.upsellProduct,
                                Total: data.upsellTotal
                            }))}
                            valueFormat={val => toLocaleString(val)}
                            colors={{ scheme: 'nivo' }}
                            keys={['Service', 'Course', 'Product', 'Total']}
                            indexBy="label"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            borderColor={{
                                from: 'color',
                                modifiers: [['darker', 1.6]]
                            }}
                            axisTop={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendPosition: 'middle'
                            }}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Location',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Upsell value',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                                from: 'color',
                                modifiers: [['darker', 1.6]]
                            }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 2,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            role="application"
                            ariaLabel="Upsell by Location"
                            barAriaLabel={function (e) {
                                return e.id + ': ' + e.formattedValue + ' by location: ' + e.indexValue;
                            }}
                        />
                    </HidableChartContainer>
                )
            }}
        />
    );
}

export default Items;
