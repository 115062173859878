import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { filters as filterStyles } from './styles';
import styled from 'styled-components';
import DaysSinceInput from './DaysSinceInput';
import getMissingFilters from '../../../utils/helpers/getMissingFilters';

const futureServiceBookedOptions = ['Yes', 'No'];

const types = ['Greater than', 'Less than', 'Exactly', 'Between'];

function ApptSlippageFilters({ classes, handleFilterChange, filters, report }) {
    const [daysSinceCateg, setDaysSinceCateg] = useState({
        type: '',
        value: '',
        from: '',
        to: ''
    });
    const [hasErrorOnDaysSinceCateg, setHasErrorOnDaysSinceCateg] = useState(false);
    const [selectedFutureBooked, setSelectedFutureBooked] = useState([]);

    useEffect(() => {
        const futureBookedValue = filters['futureBooked'];
        const newDaysSinceCateg = filters['daysSinceCateg'] || {};
        setHasErrorOnDaysSinceCateg(getMissingFilters(report, filters).includes('daysSinceCateg'));
        setDaysSinceCateg(newDaysSinceCateg);
        setSelectedFutureBooked(futureBookedValue);
    }, [filters, report]);

    const handleChangeDaysSince = newValue => {
        setDaysSinceCateg(newValue);
        handleFilterChange({
            daysSinceCateg: newValue
        });
    };

    const handleChange = (_, value) => {
        setSelectedFutureBooked(value);
        handleFilterChange({
            futureBooked: value
        });
    };

    return (
        <>
            <DaysSinceBox marginTop={4}>
                <Autocomplete
                    className={classes.autocomplete}
                    options={types}
                    value={daysSinceCateg.type}
                    onChange={(_, value) => {
                        const newDaysSinceCateg = { ...daysSinceCateg, type: value || '' };
                        if (!['Between', null].includes(value)) {
                            Object.assign(newDaysSinceCateg, {
                                from: '',
                                to: ''
                            });
                        } else {
                            newDaysSinceCateg.value = '';
                        }
                        handleChangeDaysSince(newDaysSinceCateg);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Days since last cat sale"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    input: classes.autocompleteInputProps
                                }
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                className: classes.autocompleteLabelProps
                            }}
                            style={{
                                minWidth: 200
                            }}
                            error={hasErrorOnDaysSinceCateg}
                        />
                    )}
                    renderOption={option => {
                        return <p className={classes.autocompleteOptions}>{option}</p>;
                    }}
                />

                {['Greater than', 'Less than', 'Exactly'].includes(daysSinceCateg.type) && (
                    <DaysSinceInput
                        value={daysSinceCateg.value}
                        onChange={newValue => handleChangeDaysSince({ ...daysSinceCateg, value: newValue })}
                        error={hasErrorOnDaysSinceCateg}
                    />
                )}

                {'Between' === daysSinceCateg.type && (
                    <>
                        <DaysSinceInput
                            value={daysSinceCateg.from}
                            onChange={newValue => handleChangeDaysSince({ ...daysSinceCateg, from: newValue })}
                            error={hasErrorOnDaysSinceCateg}
                        />
                        <Typography style={{ margin: '0px 10px' }}>and</Typography>
                        <DaysSinceInput
                            value={daysSinceCateg.to}
                            onChange={newValue => handleChangeDaysSince({ ...daysSinceCateg, to: newValue })}
                            error={hasErrorOnDaysSinceCateg}
                        />
                    </>
                )}
            </DaysSinceBox>
            <Box marginTop={4}>
                <Autocomplete
                    id="future-service-booked"
                    className={`${classes.autocomplete} ${classes.smallAutocompleteMaxWidth}`}
                    options={futureServiceBookedOptions}
                    value={selectedFutureBooked}
                    onChange={handleChange}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Future service booked"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    input: classes.autocompleteInputProps
                                }
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                className: classes.autocompleteLabelProps
                            }}
                        />
                    )}
                    renderOption={option => {
                        return <p className={classes.autocompleteOptions}>{option}</p>;
                    }}
                    ChipProps={{ className: classes.autocompleteChipProps }}
                />
            </Box>
        </>
    );
}

const DaysSinceBox = styled(Box)`
    display: flex;
    width: 100%;
    align-items: center;
`;

export default withStyles(filterStyles)(ApptSlippageFilters);
