export const sliceRows = (rows, rowsPerPage, page) => {
    return rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;
};

export const getDisplayedRows = (page, count, rowsPerPage) => {
    let _page = page === 0 ? page + 1 : page;

    _page = rowsPerPage * _page;

    if (_page > count) {
        _page = count;
    }

    return `${_page} of ${count}`;
};
