import React from 'react';
import { Link } from '@material-ui/core';
import { toLocaleString } from '../../../../../../collums-components/helpers';
import { INVOICE_PAYMENT_STATUS } from '../../../../../../collums-constants';
import { materialTableCurrencySorter } from '../../../../../../utils/helpers/reports';
import apps from '../../../../../../config';

const getColumns = setInvoice => {
    return [
        {
            title: 'Date/Time',
            field: 'createdAt'
        },
        {
            title: 'Invoice',
            field: 'invoice',
            type: 'unique',
            //eslint-disable-next-line
            render: data => (
                <Link
                    onClick={evt => {
                        evt.stopPropagation();
                        setInvoice({ id: data.invoiceId });
                    }}
                    style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
                >
                    {data.invoice}
                </Link>
            )
        },
        {
            title: 'Client',
            field: 'client',
            type: 'unique',
            //eslint-disable-next-line
            render: data => (
                <Link
                    onClick={() => {
                        window.open(`${apps.calendarUrl}/customer/${data.clientId}/general`);
                    }}
                    style={{ textDecoration: 'underline', color: '#0000EE', cursor: 'pointer' }}
                >
                    {data.client}
                </Link>
            )
        },
        {
            title: 'Status',
            field: 'status',
            //eslint-disable-next-line
            render: data => (
                <span style={data.status === INVOICE_PAYMENT_STATUS.REFUND ? { color: 'red' } : {}}>{data.status}</span>
            )
        },
        { title: 'Sold By', field: 'staff' },
        { title: 'Type', field: 'typeLabel' },
        { title: 'Category', field: 'categoryLabel' },
        { title: 'Item', field: 'item' },
        { title: '#', field: 'quantity', type: 'quantity' },
        {
            title: 'Discount',
            field: 'discount',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'discount'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.discount < 0 ? { color: 'red' } : {}}>{toLocaleString(data.discount)}</span>
            )
        },
        {
            title: 'Net Price',
            field: 'netPrice',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'netPrice'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.netPrice < 0 ? { color: 'red' } : {}}>{toLocaleString(data.netPrice)}</span>
            )
        },
        {
            title: 'Tax',
            field: 'tax',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'tax'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => <span style={data.tax < 0 ? { color: 'red' } : {}}>{toLocaleString(data.tax)}</span>
        },
        {
            title: 'Total',
            field: 'grossPrice',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'grossPrice'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.grossPrice < 0 ? { color: 'red' } : {}}>{toLocaleString(data.grossPrice)}</span>
            )
        },
        {
            title: 'Net Redemption',
            field: 'redemptionValue',
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.grossPrice < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.redemptionValue || 0)}
                </span>
            )
        },
        {
            title: 'Gross Redemption',
            field: 'grossRedemption',
            type: 'currency',
            //eslint-disable-next-line
            render: data => (
                <span style={data.grossPrice < 0 ? { color: 'red' } : {}}>
                    {toLocaleString(data.grossRedemption || 0)}
                </span>
            )
        },
        {
            title: 'Course Redemption',
            field: 'isCourseRedemption'
        }
    ];
};

export default getColumns;
