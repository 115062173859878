import React, { useEffect, useState, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

function IncludeExpiredFilter({ handleFilterChange, filters }) {
    const [checked, setChecked] = useState(false);

    const changeFilter = useCallback(() => {
        handleFilterChange({
            ...filters,
            includeExpired: !checked // use not checked because the state did not change yet
        });
        //eslint-disable-next-line
    }, [checked, filters]);

    useEffect(() => {
        if (filters.includeExpired) {
            setChecked(true);
        } else {
            if (filters.includeExpired !== undefined) {
                setChecked(false);
            } else {
                setChecked(false);
            }
        }
        //eslint-disable-next-line
    }, []);

    const handleChange = () => {
        setChecked(!checked);
        changeFilter();
    };

    return (
        <Box marginTop={4}>
            <FormControlLabel control={<Checkbox checked={checked} onClick={handleChange} />} label="Include expired" />
        </Box>
    );
}

export default IncludeExpiredFilter;
