import React from 'react';
import { toLocaleString } from '../../../../../../collums-components/helpers';
import { materialTableCurrencySorter } from '../../../../../../utils/helpers/reports';

const getColumns = () => {
    return [
        {
            title: 'Method',
            field: 'id'
        },
        {
            title: '#',
            field: 'amount_of_occurrences',
            type: 'quantity',
            //eslint-disable-next-line
            render: data => <span style={data.total < 0 ? { color: 'red' } : {}}>{data.amount_of_occurrences}</span>
        },
        {
            title: 'Total',
            field: 'total',
            customSort: (a, b, direction) => materialTableCurrencySorter(a, b, direction, 'total'),
            type: 'currency',
            //eslint-disable-next-line
            render: data => <span style={data.total < 0 ? { color: 'red' } : {}}>{toLocaleString(data.total)}</span>
        },
        {
            title: '% of total',
            field: 'percentage_of_occurrences',
            type: 'percentage',
            //eslint-disable-next-line
            render: data => <span>{data.percentage_of_occurrences}%</span>
        }
    ];
};

export default getColumns;
