import moment from 'moment';

const queryParamsReducer = (acc, key, queryParams) => {
    const fromAndTo = selectedKey => {
        const value = queryParams.get(selectedKey);
        if (typeof value === 'string' && value.match(/([0-9]{2}[-]){2}[0-9]{4}/)) {
            const momentInstance = moment(value, 'DD-MM-YYYY');
            if (selectedKey === 'from') {
                return momentInstance.startOf('day').toISOString();
            }
            return momentInstance.endOf('day').toISOString();
        }
        return null;
    };

    /* 
        const getArrayOfIDs = () => {
            const value = queryParams.get(key);
            if (typeof value === 'string' && value) {
                if (value.includes(',')) return value.split(',').filter(isValidMongoIdString);
                if (isValidMongoIdString(value)) return [value];
            }
            return null;
        }; 
    */

    const getArrayOfStrings = () => {
        const value = queryParams.get(key);
        if (typeof value === 'string' && value) {
            if (value.includes(',')) return value.split(',').filter(value => value);
            return [value];
        }
        return null;
    };

    const getDefaultValue = () => {
        const value = queryParams.get(key);
        return value ?? null;
    };

    const queryProperties = {
        from: () => fromAndTo('from'),
        to: () => fromAndTo('to'),
        locations: getArrayOfStrings,
        clinics: getArrayOfStrings
    };

    const result = (queryProperties[key] ?? getDefaultValue)(key);
    if (result === null) return acc;
    return {
        ...acc,
        [key]: result
    };
};

export default queryParamsReducer;
