import React, { /* useMemo, */ useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { groupBy, sumBy } from 'lodash';
//import TableSortLabel from '@material-ui/core/TableSortLabel';
import WarningMessage from '../../../../common/CollumsTable/components/WarningMessage';

import TableInfo from '../../../../common/CollumsTable/components/TableInfo';

import { categoryStyles } from './styles';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { DropdownButton } from '../../../../common/DropdownButton';
import SearchInput from '../../../../common/CollumsTable/components/SearchInput';
import styled from 'styled-components';
import LoadingScreen from '../../../../../collums-components/components/common/loadingScreen';
import { convertLocaleString, toLocaleString, useDebounce } from '../../../../../collums-components/helpers';
import exportCsv from '../../../../common/CollumsTable/helpers/exportCSV';
import { scrollAnimation } from '../../../../../utils/helpers/common';
import PaginationTable from '../../../../common/CollumsTable/components/PaginationTable';
import { getDisplayedRows } from '../../../../common/CollumsTable/helpers/pagination';
import { useHistory } from 'react-router-dom';
import { INVOICE_ITEM_TYPES } from '../../../../../collums-constants';
import { isNegative } from '../../../../../utils/helpers/reports';
import { Columns } from './columns';
import { uniq } from 'lodash';
import ReportFilterList from '../../../../common/ReportFilterList';
//import { sorter, localeSorter, percentageSorter } from './sort';

const StyledTableCell = withStyles(categoryStyles)(({ className, children, classes }) => (
    <TableCell className={classnames(classes.tableCell, className)}>{children}</TableCell>
));

const CustomHeadWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 5rem;
    &:first-child {
        margin-right: 1rem;
    }
`;

const AdditionalHeadRow = styled(TableRow)`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
`;

const SearchRow = styled.div`
    display: flex;
    width: 21.875rem;
`;

const TableInfoWrapper = styled.div`
    margin-left: -8px;
`;

const columns = ['name', 'quantity', 'netPrice', 'tax', 'grossPrice', 'discount', 'aveTotalSale', 'percentage'];

function Category({ values, query, classes, filterBtn, loading, title = 'Category & Type Sales' }) {
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [isUsingSearch, setIsUsingSearch] = useState(false);
    const [search, setSearch] = useState('');
    const debouncer = useDebounce(search, 300);
    const ref = useRef();
    const [showWarning, setShowWarning] = useState(false);
    const redirectTo = useRef();
    const canRedirect = useRef(false);
    const history = useHistory();

    const releaseAction = () => {
        setShowWarning(true);
    };

    const calcPercentageValue = pct => {
        return Math.floor(pct * 100 * 100) / 100;
    };

    const leaveAction = () => {
        setShowWarning(false);
        canRedirect.current = true;
        if (redirectTo.current) {
            const name = redirectTo.current?.innerText?.toLowerCase();
            if (['scheduled reports', 'reports'].includes(name)) {
                if ('scheduled reports' === name) {
                    history.push('/scheduled-reports');
                } else {
                    history.push('/reports');
                }
            } else {
                try {
                    redirectTo.current.click();
                } catch (err) {
                    return;
                }
            }
        }
    };

    /* const [orderBy, setOrderBy] = useState('name');
    const [direction, setDirection] = useState('asc'); */

    /* const handleSort = (column) => {
        if(column === orderBy){
            const newDirection = direction === 'asc' ? 'desc' : 'asc';
            setDirection(newDirection);
        }
        else{
            setOrderBy(column);
            setDirection('asc');
        }
    }; */

    useEffect(() => {
        setPage(0);
        setIsUsingSearch(Boolean(search));
        scrollAnimation();
        //eslint-disable-next-line
    }, [debouncer]);

    useEffect(() => {
        scrollAnimation();
    }, [page, rowsPerPage]);

    const handleSearch = rows => {
        if (!rows) return;

        const _searchedValues = rows.filter(row => {
            const check = columns.filter(col => {
                return row[col]
                    .toString()
                    .toLowerCase()
                    .includes((search || '').toLowerCase());
            });

            if (check.length > 0) return true;

            return false;
        });

        return _searchedValues;
    };

    useEffect(() => {
        setPage(0);
        //eslint-disable-next-line
    }, [values]);

    const handleClickOutside = event => {
        if (event.target?.id === 'confirm-leave-button') return;

        if (event.target?.download) return;

        const isFromSearchButton = (event.path || []).some(el => el?.id === 'searchIcon');

        if (isFromSearchButton) return;

        const tagName = event.target?.tagName?.toLowerCase();
        if (tagName !== 'a' && tagName !== 'img') return;

        if (canRedirect.current) return;

        if (ref.current && !ref.current.contains(event.target) && totalRows?.length) {
            redirectTo.current = event.target;
            event.stopPropagation();
            event.preventDefault();
            releaseAction();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const filters = query;

    const handlePageChange = (_, newPage) => {
        setPage(newPage - 1);
    };
    const handleRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    /* const sortRows = (rows) => {
        const sortHandler = (() => {
            if(['name', 'quantity'].includes(orderBy)) return sorter;
            if(orderBy === 'percentage') return percentageSorter;
            return localeSorter;
        })();
        const newRows = sortHandler(rows, orderBy, direction);
        return newRows;
    } */

    const getNewValuesToSearchRows = _searchedValues => {
        const groupedRows = groupBy(_searchedValues, 'type');

        const getTotal = rows => {
            return rows.reduce((acc, item) => {
                const unformattedTotal = convertLocaleString(item.total || 0);
                return acc + unformattedTotal;
            }, 0);
        };

        const getNewValue = (_rows, totalValue) => {
            let totalPercentage = 100;
            return _rows.map((el, index) => {
                const svcTotal = convertLocaleString(el.total || 0);
                const newPercentage = (() => {
                    if (!el.total || !totalValue) return 0;
                    const arrSlice = _rows.slice(index + 1).map(el => convertLocaleString(el.total));
                    const lastItemsAreZero = arrSlice.every(el => el <= 0);
                    if ((lastItemsAreZero && arrSlice.length) || index === _rows.length - 1) return totalPercentage;
                    return calcPercentageValue(svcTotal / totalValue);
                })();
                totalPercentage -= Number(newPercentage).toFixed(2);
                const displayedPercentage = `${newPercentage.toFixed(2)}%`;
                return { ...el, percentage: displayedPercentage };
            });
        };

        const getRows = rowName => {
            const _rows = groupedRows ? groupedRows[rowName] || [] : [];
            const totalValue = getTotal(_rows);
            return getNewValue(_rows, totalValue);
        };

        const courses = getRows(INVOICE_ITEM_TYPES.COURSE);
        const products = getRows(INVOICE_ITEM_TYPES.PRODUCT);
        const services = getRows(INVOICE_ITEM_TYPES.SERVICE);

        return [...services, ...courses, ...products];
    };

    const getDisplayingContent = () => {
        const servicesRows = values?.services?.items || [];
        const coursesRows = values?.courses?.items || [];
        const productsRows = values?.products?.items || [];

        const totalRows = (() => {
            const rows = [...servicesRows, ...coursesRows, ...productsRows];
            if (isUsingSearch) {
                const searchedValues = handleSearch(rows);

                return getNewValuesToSearchRows(searchedValues);
            }
            return rows;
        })();

        const actualIndex = rowsPerPage * page;
        const finalIndex = actualIndex + rowsPerPage;

        const displayingRows = totalRows.slice(actualIndex, finalIndex);

        return { totalRows, rows: displayingRows };
    };

    const { totalRows, rows } = getDisplayingContent();

    const getRowsContent = (arr = rows) => {
        const groupedRows = groupBy(arr, 'type');
        const servicesRows = groupedRows[INVOICE_ITEM_TYPES.SERVICE] || [];
        const productsRows = groupedRows[INVOICE_ITEM_TYPES.PRODUCT] || [];
        const coursesRows = groupedRows[INVOICE_ITEM_TYPES.COURSE] || [];

        return {
            servicesRows,
            coursesRows,
            productsRows
        };
    };

    const { servicesRows, coursesRows, productsRows } = getRowsContent();

    const RenderRow = ({ item }) => {
        return (
            <TableRow>
                <StyledTableCell className={classes.leftAlign}>{item.name}</StyledTableCell>
                <StyledTableCell className={classes.rightAlign}>{item.quantity}</StyledTableCell>
                <StyledTableCell>
                    <span style={isNegative(item.discount) ? { color: 'red' } : {}}>{item.discount}</span>
                </StyledTableCell>
                <StyledTableCell>
                    <span style={isNegative(item.netPrice) ? { color: 'red' } : {}}>{item.netPrice}</span>
                </StyledTableCell>
                <StyledTableCell>
                    <span style={isNegative(item.tax) ? { color: 'red' } : {}}>{item.tax}</span>
                </StyledTableCell>
                <StyledTableCell>
                    <span style={isNegative(item.grossPrice) ? { color: 'red' } : {}}>{item.grossPrice}</span>
                </StyledTableCell>
                <StyledTableCell>
                    <span style={isNegative(item.aveTotalSale) ? { color: 'red' } : {}}>{item.aveTotalSale}</span>
                </StyledTableCell>
                <StyledTableCell>{item.percentage}</StyledTableCell>
            </TableRow>
        );
    };

    const displayingItemsTotals = (() => {
        if (!isUsingSearch) {
            const services = values?.services?.totals || {};
            const products = values?.products?.totals || {};
            const courses = values?.courses?.totals || {};
            const grandTotal = values?.grandTotal || {};
            return { services, products, courses, grandTotal };
        }

        const getTotal = (row, grandTotalValue, type, typeList, remainPercentage) => {
            const quantity = sumBy(row, 'quantity');
            const netPrice = toLocaleString(sumBy(row, o => convertLocaleString(o.netPrice)));
            const tax = toLocaleString(sumBy(row, o => convertLocaleString(o.tax)));
            const grossPrice = sumBy(row, o => convertLocaleString(o.grossPrice));
            const discount = toLocaleString(sumBy(row, o => convertLocaleString(o.discount)));
            const aveTotalSale = toLocaleString(grossPrice / (quantity || 1));

            const getPercentage = () => {
                if (!grandTotalValue || !grossPrice) return 0;
                if (typeList.includes(type) && typeList.length === 1) return remainPercentage;
                return calcPercentageValue(grossPrice / grandTotalValue);
            };

            const percentage = grandTotalValue !== undefined ? getPercentage() : '100.0%';

            return {
                quantity,
                netPrice,
                tax,
                grossPrice: toLocaleString(grossPrice),
                discount,
                aveTotalSale,
                percentage: percentage
            };
        };

        const totalValues = getRowsContent(totalRows);

        const grandTotal = getTotal(totalRows);
        const grandTotalValue = convertLocaleString(grandTotal.grossPrice);

        const typeList = uniq(totalRows.filter(el => el.grossPrice).map(el => el.type));

        let grandTotalPercentage = 100;

        const removeFromList = itemToRemove => {
            if (typeList.includes(itemToRemove)) typeList.splice(typeList.indexOf(itemToRemove), 1);
        };

        const services = getTotal(totalValues.servicesRows, grandTotalValue, 'Service', typeList, grandTotalPercentage);
        grandTotalPercentage -= Number(services.percentage.toFixed(1));
        services.percentage = `${services.percentage.toFixed(1)}%`;
        removeFromList('Service');

        const courses = getTotal(totalValues.coursesRows, grandTotalValue, 'Course', typeList, grandTotalPercentage);
        grandTotalPercentage -= Number(courses.percentage.toFixed(1));
        courses.percentage = `${courses.percentage.toFixed(1)}%`;
        removeFromList('Course');

        const products = getTotal(totalValues.productsRows, grandTotalValue, 'Product', typeList, grandTotalPercentage);
        grandTotalPercentage -= Number(products.percentage.toFixed(1));
        products.percentage = `${products.percentage.toFixed(1)}%`;
        removeFromList('Product');

        return { grandTotal, services, courses, products };
    })();

    const RenderCategoryHeader = ({ categoryName, data }) => {
        if (!data?.quantity) return null;
        return (
            <>
                <TableRow>
                    <StyledTableCell className={`${classes.defaultHeader} ${classes.leftAlign}`}>
                        {categoryName} Totals
                    </StyledTableCell>
                    <StyledTableCell className={`${classes.defaultHeader} ${classes.rightAlign}`}>
                        {data.quantity}
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span style={isNegative(data.discount) ? { color: 'red' } : {}}>{data.discount}</span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span style={isNegative(data.netPrice) ? { color: 'red' } : {}}>{data.netPrice}</span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span style={isNegative(data.tax) ? { color: 'red' } : {}}>{data.tax}</span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span style={isNegative(data.grossPrice) ? { color: 'red' } : {}}>{data.grossPrice}</span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span style={isNegative(data.aveTotalSale) ? { color: 'red' } : {}}>{data.aveTotalSale}</span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>{data.percentage}</StyledTableCell>
                </TableRow>
            </>
        );
    };

    const RenderCategory = ({ categoryName, items, data }) => {
        if (!data?.quantity) return null;
        return (
            <>
                <TableRow>
                    <StyledTableCell className={`${classes.defaultHeader} ${classes.leftAlign}`}>
                        {categoryName}
                    </StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                </TableRow>

                {items.map((item, index) => (
                    <RenderRow key={`${categoryName}_items_${item?.id || index}`} item={item} />
                ))}
            </>
        );
    };

    const RenderTableBody = useCallback(() => {
        return (
            <TableBody>
                <TableRow>
                    <StyledTableCell className={`${classes.defaultHeader} ${classes.leftAlign}`}>
                        Grand Total
                    </StyledTableCell>
                    <StyledTableCell className={`${classes.defaultHeader} ${classes.rightAlign}`}>
                        {displayingItemsTotals?.grandTotal?.quantity}
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span
                            style={
                                isNegative(displayingItemsTotals?.grandTotal?.discount || '') ? { color: 'red' } : {}
                            }
                        >
                            {displayingItemsTotals?.grandTotal?.discount}
                        </span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span
                            style={
                                isNegative(displayingItemsTotals?.grandTotal?.netPrice || '') ? { color: 'red' } : {}
                            }
                        >
                            {displayingItemsTotals?.grandTotal?.netPrice}
                        </span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span style={isNegative(displayingItemsTotals?.grandTotal?.tax || '') ? { color: 'red' } : {}}>
                            {displayingItemsTotals?.grandTotal?.tax}
                        </span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span
                            style={
                                isNegative(displayingItemsTotals?.grandTotal?.grossPrice || '') ? { color: 'red' } : {}
                            }
                        >
                            {displayingItemsTotals?.grandTotal?.grossPrice}
                        </span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        <span
                            style={
                                isNegative(displayingItemsTotals?.grandTotal?.aveTotalSale || '')
                                    ? { color: 'red' }
                                    : {}
                            }
                        >
                            {displayingItemsTotals?.grandTotal?.aveTotalSale}
                        </span>
                    </StyledTableCell>
                    <StyledTableCell className={classes.defaultHeader}>
                        {displayingItemsTotals?.grandTotal?.percentage}
                    </StyledTableCell>
                </TableRow>

                {<RenderCategoryHeader categoryName="Service" data={displayingItemsTotals.services} />}
                {<RenderCategoryHeader categoryName="Course" data={displayingItemsTotals.courses} />}
                {<RenderCategoryHeader categoryName="Product" data={displayingItemsTotals.products} />}

                {<RenderCategory categoryName="Services" items={servicesRows} data={displayingItemsTotals.services} />}
                {<RenderCategory categoryName="Courses" items={coursesRows} data={displayingItemsTotals.courses} />}
                {<RenderCategory categoryName="Products" items={productsRows} data={displayingItemsTotals.products} />}
            </TableBody>
        );
        //eslint-disable-next-line
    }, [page, rowsPerPage, values, isUsingSearch, search]);

    /* const getRowStyle = (cellName) => {
        if(cellName === 'name') return {width: '24%'};
        if(cellName === 'quantity' || cellName === 'percentage') return {width: '5%'};
        return {width: '11%'}
    }; */

    const processCsv = () => {
        const csvRows = (() => {
            const arr = [];
            const totRows = [];
            const totalValues = getRowsContent(totalRows);

            const createLabel = name => {
                arr.push({
                    name
                });
            };

            if (displayingItemsTotals?.grandTotal?.quantity) {
                totRows.push({ ...displayingItemsTotals?.grandTotal, name: 'Grand Total' });
            }
            if (displayingItemsTotals.services?.quantity) {
                totRows.push({ ...displayingItemsTotals.services, name: 'Service Totals' });
                createLabel('Services');
                arr.push(...totalValues.servicesRows);
            }
            if (displayingItemsTotals.courses?.quantity) {
                totRows.push({ ...displayingItemsTotals.courses, name: 'Course Totals' });
                createLabel('Courses');
                arr.push(...totalValues.coursesRows);
            }
            if (displayingItemsTotals.products?.quantity) {
                totRows.push({ ...displayingItemsTotals.products, name: 'Product Totals' });
                createLabel('Products');
                arr.push(...totalValues.productsRows);
            }
            return [...totRows, ...arr];
        })();
        if (!csvRows.length) return;
        exportCsv(csvRows, tableInfo, false, Columns);
    };

    const tableInfo = { title, filters };
    return (
        <div ref={ref}>
            {/* header */}
            <AdditionalHeadRow>
                <ReportFilterList openFilterModal={filterBtn.onClick} runReport={filterBtn.runReport}>
                    <DropdownButton
                        label={'Options'}
                        options={[
                            {
                                label: 'Export as CSV',
                                handleClick: processCsv
                            }
                        ]}
                    />
                </ReportFilterList>
                <SearchRow>
                    <SearchInput
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value);
                        }}
                        onSearch={() => handleSearch}
                        onCancel={() => {
                            setSearch('');
                        }}
                    />

                    <CustomHeadWrapper>
                        <Button
                            color="secondary"
                            variant="none"
                            onClick={filterBtn.onClick}
                            className={classes.filterBtn}
                        >
                            {filterBtn.label || 'Filters'}
                        </Button>
                    </CustomHeadWrapper>
                </SearchRow>
            </AdditionalHeadRow>
            {filters && (
                <TableInfoWrapper>
                    <TableInfo {...{ title, filters, showFilters: false }} />
                </TableInfoWrapper>
            )}
            {/* header */}
            {loading ? (
                <LoadingScreen />
            ) : (
                <TableContainer>
                    <Table className={classes.table}>
                        {/* 
                            <TableHead>
                                <TableRow>
                                    {columns.map(col => {
                                        return (
                                            <TableCellHead key={col} align={col === 'currency' ? 'right' : 'left'} style={getRowStyle(col)}>
                                                <TableSortLabel
                                                    active={orderBy === col}
                                                    direction={orderBy === col ? direction : 'asc'}
                                                    onClick={() => handleSort(col)}
                                                    hideSortIcon={orderBy !== col}
                                                    style={{ fontSize: '0.875rem', fontWeight: 500 }}
                                                >
                                                    {col === 'name' ? 'Category' : col}
                                                </TableSortLabel>
                                            </TableCellHead>
                                        );
                                    })}
                            </TableRow> */}
                        <TableHead>
                            <TableRow>
                                <TableCell className={`${classes.defaultHeader} ${classes.leftAlign}`}>
                                    Category
                                </TableCell>

                                <TableCell
                                    className={`${classes.defaultHeader} ${classes.rightAlign}`}
                                    style={{ width: '5%' }}
                                >
                                    #
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Discount
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Net Price
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Tax
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Total
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '11%' }}>
                                    Ave Net Sale
                                </TableCell>

                                <TableCell className={classes.defaultHeader} style={{ width: '5%' }}>
                                    % Net Total
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        {<RenderTableBody />}
                    </Table>
                    {
                        <PaginationTable
                            count={totalRows.length}
                            rowsPerPage={rowsPerPage}
                            countDivRows={Math.ceil(totalRows.length / rowsPerPage)}
                            page={page + 1}
                            handleChangePage={handlePageChange}
                            handleChangeRowsPerPage={handleRowsPerPageChange}
                            labelDisplayedRows={() => getDisplayedRows(page + 1, totalRows.length, rowsPerPage)}
                        />
                    }
                </TableContainer>
            )}
            {showWarning && <WarningMessage leaveAction={leaveAction} setShowWarning={setShowWarning} />}
        </div>
    );
}

Category.propTypes = {
    values: PropTypes.object.isRequired,
    query: PropTypes.object,
    classes: PropTypes.object.isRequired
};

export default withStyles(categoryStyles)(Category);
