import api from './api';

class AuthApi {
    static async getMe() {
        return api.request({
            method: 'GET',
            url: '/user/me'
        });
    }

    static lockUser() {
        return api.request({
            method: 'POST',
            url: '/lock-user'
        });
    }

    static isUserLocked() {
        return api.request({
            method: 'GET',
            url: '/is-user-locked'
        });
    }
}

export default AuthApi;
