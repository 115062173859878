import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ProductApi from '../collums-components/api/ProductApi';
import PractitionerApi from '../collums-components/api/PractitionerApi';
import ServiceApi from '../collums-components/api/ServiceApi';
import ReportApi from '../api/ReportApi';

const ReportFilterItemsContext = createContext({});

export const ReportFilterItemsProvider = ({ children }) => {
    const [toxins, setToxins] = useState([]);
    const [serviceCategories, setServiceCategories] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [practitioners, setPractitioners] = useState([]);
    const [organisation, setOrganisation] = useState({});
    const [categoriesForServiceSalesBy, setCategoriesForServicesSalesBy] = useState([]);
    const [categoriesForCoursesSalesBy, setCategoriesForCoursesSalesBy] = useState([]);
    const [categoriesForProductSalesBy, setCategoriesForProductSalesBy] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const loadToxins = useCallback(() => {
        ProductApi.getToxinsFromOrg().then(setToxins);
    }, []);

    const loadPractitioners = useCallback(() => {
        PractitionerApi.listAllPractitioners().then(setPractitioners);
    }, []);

    const loadServiceCategoriesItems = useCallback(() => {
        ServiceApi.getServiceAndCategories().then(setServiceCategories);
    }, []);

    const loadSalesByServiceCategoriesItems = useCallback(async () => {
        const res = await ReportApi.getServicesCategories();
        setCategoriesForServicesSalesBy(res?.items?.map(item => ({ label: item.name, value: item.id })));
    }, []);

    const loadSalesByCourseCategoriesItems = useCallback(async () => {
        const res = await ReportApi.getCoursesCategories();
        setCategoriesForCoursesSalesBy(res?.items?.map(item => ({ label: item.name, value: item.id })));
    }, []);

    const loadSalesByProductCategoriesItems = useCallback(async () => {
        const res = (await ReportApi.getProductsCategories()) ?? [];
        setCategoriesForProductSalesBy(res.map(item => ({ label: item.name, value: item.id })));
    }, []);

    const loadServiceList = useCallback(async () => {
        const { items } = (await ReportApi.getServices(5000, false)) ?? { items: [] };
        setServiceList(items.map(service => ({ label: service.name, value: service.id })));
    }, []);

    const loadCourseList = useCallback(async () => {
        const { courses } = (await ReportApi.getCourses(5000)) ?? { courses: [] };
        setCourseList(courses.map(course => ({ label: course.name, value: course.id })));
    }, []);

    const loadProductList = useCallback(async () => {
        const products = (await ReportApi.getProducts()) ?? [];
        setProductList(products.map(product => ({ label: product.name, value: product.id })));
    }, []);

    const loadPaymentMethods = useCallback(async () => {
        const newPaymentMethods = await ReportApi.getPaymentMethods();
        setPaymentMethods(newPaymentMethods);
    }, []);

    const loadFilterItems = useCallback(() => {
        loadServiceCategoriesItems();
        loadPractitioners();
        loadToxins();
        loadSalesByServiceCategoriesItems();
        loadSalesByCourseCategoriesItems();
        loadSalesByProductCategoriesItems();
        loadProductList();
        loadServiceList();
        loadCourseList();
        loadPaymentMethods();
        // eslint-disable-next-line
    }, []);

    return (
        <ReportFilterItemsContext.Provider
            value={{
                toxins,
                loadToxins,
                practitioners,
                serviceCategories,
                loadPractitioners,
                loadServiceCategoriesItems,
                loadFilterItems,
                setOrganisation,
                organisation,
                categoriesForServiceSalesBy,
                categoriesForCoursesSalesBy,
                categoriesForProductSalesBy,
                courseList,
                productList,
                serviceList,
                paymentMethods,
                loadPaymentMethods
            }}
        >
            {children}
        </ReportFilterItemsContext.Provider>
    );
};

ReportFilterItemsProvider.propTypes = {
    children: PropTypes.element
};

export const useReportFilterItems = () => useContext(ReportFilterItemsContext);
