import moment from 'moment';
import getDaysSinceCategSaleLabel from './getDaysSinceCategSaleLabel';
import { shortStr } from '../../collums-constants/utils';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

const getFilterLabel = (column, savedFilters) => {
    const getDateRange = () => {
        const transformToLabel = date => moment(date).format('DD/MM/YYYY');

        if (savedFilters.from && savedFilters.to) {
            const fromLabel = transformToLabel(savedFilters.from);
            const toLabel = transformToLabel(savedFilters.to);
            if (fromLabel === toLabel) return fromLabel;
            return `${fromLabel} - ${toLabel}`;
        }
        return '';
    };

    const defaultHandler = () => {
        if (isArray(savedFilters[column])) {
            if (isObject(savedFilters[column][0])) {
                return savedFilters[column].map(el => el.label || '').join(', ');
            }
            return savedFilters[column].join(', ');
        }
        if (isObject(savedFilters[column]) && savedFilters[column].label) {
            return savedFilters[column].label;
        }
        if (column === 'includeTaxes') {
            return savedFilters[column] ? 'Include taxes' : 'Exclude taxes';
        }
        if (column === 'includeExpired') {
            return savedFilters[column] ? 'Include expired' : 'Exclude expired';
        }
        if (typeof savedFilters[column] === 'boolean') {
            return savedFilters[column] ? 'Yes' : 'No';
        }
        return savedFilters[column];
    };

    const getDaysSinceCatSale = () => {
        return getDaysSinceCategSaleLabel(savedFilters[column]);
    };

    const getPaymentStatus = () => {
        return savedFilters[column].replace(/[,]/g, ', ');
    };

    const handlers = {
        dateRange: getDateRange,
        daysSinceCateg: getDaysSinceCatSale,
        paymentStatus: getPaymentStatus
    };

    const text = (handlers[column] ?? defaultHandler)();
    return shortStr(text);
};

export default getFilterLabel;
